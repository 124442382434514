import _ from "lodash";

import { SEARCH } from "../constants.js";
import { POSTer } from "./searchHelpers";

class Searcher {
  constructor() {
    // Get the saved values from localStorage
    const savedValues =
      JSON.parse(localStorage.getItem("SearcherSettings")) || {};
    this.searchTerm =
      (savedValues.searchTerm && savedValues.searchTerm.toString()) || "";
    this.cache = {};
    this.filters = savedValues.filters || {};
    this.ICClipboard = savedValues.ICClipboard || {};
    this.IClanguagecode = savedValues.IClanguagecode || "en";
    this.savedObjects = savedValues.savedObjects || {};
    this.credentials = savedValues.credentials || undefined;
    this.lastSortOrder = savedValues.lastSortOrder || "RANDOM";
    this.updateFilterNonce();
    //    this.checkCredentialsExpiry();
  }

  saveSettings = () => {
    const settings = {
      searchTerm: this.searchTerm,
      filters: this.filters,
      ICClipboard: this.ICClipboard,
      IClanguagecode: this.IClanguagecode,
      savedObjects: this.savedObjects,
      credentials: this.credentials,
      lastSortOrder: this.lastSortOrder,
    };
    localStorage.setItem("SearcherSettings", JSON.stringify(settings));
  };

  addSavedItem = (obj) => {
    if (this.savedObjects[obj.ID[0]]) return;
    this.savedObjects[obj.ID[0]] = { timestamp: new Date(), obj: obj };
    this.saveSettings();
  };
  removeSavedItem = (objID) => {
    if (this.savedObjects[objID]) delete this.savedObjects[objID];
    this.saveSettings();
  };

  removeCredentials = () => {
    this.credentials = null;
  };

  addCredentials = (credentials) => {
    this.credentials = credentials;
    const url = `${SEARCH.PATH_BASE}${SEARCH.PATH_USER}`;
    POSTer(url, { token: credentials.token }, (data) => {
      this.credentials.userpk = data.userpk;
      this.saveSettings();
    });
  };

  checkCredentialsExpiry = () => {
    // Check to see if the current credentials has expired, if so, try to get a new one.
    if (this.credentials) {
      const expiry = new Date(parseFloat(this.credentials.exp) * 1000);
      if (expiry < new Date()) {
        document.location = `${SEARCH.AUTH_TOKEN_URL}${window.location.origin}${
          process.env.REACT_APP_URL_PATH
        }/`;
      }
    }
  };

  setICLanguage = (languagecode) => {
    this.IClanguagecode = languagecode;
    this.saveSettings();
  };

  hasFilterEntry = (fieldName, fieldValue) => {
    for (let filterEntry in this.filters) {
      if (
        this.filters[filterEntry].fieldName === fieldName &&
        this.filters[filterEntry].fieldValue === fieldValue
      ) {
        return true;
      }
    }
    return false;
  };

  hasSearchFilters = () => {
    if (Object.keys(this.filters).length > 0) return true;
    if (this.searchTerm.length > 0) return true;
    return false;
  };

  updateFilterNonce = () => {
    this.nonce = Math.random()
      .toString(36)
      .substring(2, 15);
  };

  setSort = (section, sort) => {
    this.lastSortOrder = sort;
    let r = this.getSectionResults(section);
    r.sort = sort;
    this.cache[section] = r;
  };

  getSectionResults = (section) => {
    if (!this.cache[section]) {
      // When total === null a search has not been performed yet
      this.cache[section] = {
        total: null,
        hits: [],
        facets: null,
        page: 0,
        sort: this.lastSortOrder,
        error: null,
        nonce: this.nonce,
      };
    }
    return this.cache[section];
  };

  // Return a citable URL
  citable_url = () => {
    let filters_string = this.filters_to_string(this.filters);
    filters_string = filters_string.replace(/facet_ICPATH/g, "facet_ic");
    filters_string = filters_string.replace(/facet_TIPE/g, "facet_tipe");
    if (this.searchTerm) {
      filters_string = `${filters_string}&q=${escape(this.searchTerm)}`;
    }
    return `${window.location.href}?${filters_string}`;
  };

  filters_to_string = (filters) => {
    let filterstrings = [];
    for (var x in filters) {
      let f_obj = filters[x];
      filterstrings.push(
        `facet_${f_obj.fieldName}=${f_obj.filterType}${escape(
          f_obj.fieldValue
        ).replace(/\+/g, "%2B")}`
      );
    }
    return filterstrings.join("&");
  };

  // Called with a list of filters to be added.
  // Each entry in the list is an object with filterId, filterType, fieldName, fieldValue
  // If you pass in a filterId with filterType: null , you can delete that filter
  // Passing in an item with an existing filterType, fieldName, fieldValue deletes it.
  addFilter = (filterList) => {
    let thefilters = { ...this.filters };
    this.updateFilterNonce();
    for (var i = 0; i < filterList.length; i++) {
      let { filterId, filterType, fieldName, fieldValue } = filterList[i];

      // if the fieldName is ICPATH, record this notation
      if (fieldName === "ICPATH") {
        this.ICClipboard[fieldValue] = {
          timestamp: new Date(),
          notation: fieldValue,
        };
      }

      // Run through the existing filters, if there is already a fileter with the same type
      // and same fieldname, and fieldvalue delete it
      // this makes it a toggle
      for (let fkey in thefilters) {
        if (
          (thefilters[fkey].filterType === filterType) &
          (thefilters[fkey].fieldName === fieldName) &
          (thefilters[fkey].fieldValue === fieldValue)
        ) {
          filterId = thefilters[fkey].filterId;
          filterType = null; // deleting it
        }
      }

      // Specifying a filterType of null will delete this filter
      if (filterType === null) {
        delete thefilters[filterId];
        continue;
      }

      if (filterId === null) {
        filterId = Math.random()
          .toString(36)
          .substring(2, 15);
      }
      thefilters[filterId] = { filterId, filterType, fieldName, fieldValue };
    }
    this.filters = thefilters; // assign it new so updates in props for components become visible
  };

  removeFilter = (filterId) => {
    if (this.filters[filterId]) {
      delete this.filters[filterId];
    }
  };

  // Fetch a list of objects from the server as specified by the array "uids"
  getObjects = (uids, updateSearchResults) => {
    if (uids.length < 1) return;
    let search_url = `${SEARCH.PATH_BASE}${SEARCH.PATH_OBJECTS}`;

    fetch(search_url, {
      body: JSON.stringify({ uids: uids }),
      cache: "no-cache",
      method: "POST",
      credentials: "include",
    })
      .then((response) => response.json())
      .then((result) => {
        updateSearchResults(result);
      })
      .catch((e) => {
        updateSearchResults({ notfound: uids });
        console.log(e);
      });
  };

  // do a search action for a specific section
  doSearch = (
    section,
    updateSearchResults,
    searchTerm,
    filters,
    sortfield,
    size
  ) => {
    this.saveSettings();
    // for "normal" searches, the searchTerm and filters params are left out and stay undefined
    // when you want to do "custom" searches that are not fixed to a section,
    // it is called with section=null and filled in searchTerm, filters.
    // See views.js Children component
    let usedSearchTerm = searchTerm;
    if (searchTerm === undefined) {
      usedSearchTerm = this.searchTerm;
    }
    let sectionResults = this.getSectionResults(section);
    if (sortfield !== undefined) {
      sectionResults.sort = sortfield;
    }
    const hpp = size || SEARCH.DEFAULT_HPP;
    const CRED = this.credentials ? `token=${this.credentials.token}` : "";
    const search_url = `${SEARCH.PATH_BASE}${SEARCH.PATH_SEARCH}?${CRED}&${
      SEARCH.PARAM_SECTION
    }${section}&${SEARCH.PARAM_SEARCH}${usedSearchTerm}&${SEARCH.PARAM_PAGE}${
      sectionResults.page
    }&${SEARCH.PARAM_HPP}${hpp}&${SEARCH.PARAM_SORT}${
      sectionResults.sort
    }&${this.filters_to_string(filters || this.filters)}`;

    fetch(search_url, {
      credentials: "include",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.error) {
          document.location = `${process.env.REACT_APP_URL_PATH}/help/trial`;
        } else {
          sectionResults.hits = this.addNextPrevious(result.hits);
          sectionResults.facets = result.facets;
          sectionResults.total = result.total;
          sectionResults.nonce = this.nonce;
          this.cache[section] = sectionResults;
          if (updateSearchResults) {
            updateSearchResults(sectionResults);
          }
        }
      })
      .catch((e) => {
        console.log(e);
        this.cache[section].error = e;
        this.cache[section].total = null;
        updateSearchResults(this.cache[section]);
      });
  };

  // add next/previous IDs to search results.
  addNextPrevious = (hits) => {
    let currentPrevious = null;
    hits.forEach((hit, index) => {
      if (hit.ID) {
        const ID = hit.ID[0];
        if (currentPrevious) {
          hit._searchPrevious = [currentPrevious];
          hits[index - 1]._searchNext = [ID];
        }
        currentPrevious = ID;
      }
    });
    return hits;
  };

  extractICfromHits = (section) => {
    let sectionResults = this.getSectionResults(section);
    let ICCounter = {};
    let firstDigit = {};
    sectionResults.hits.forEach(
      (obj) =>
        obj.IC &&
        obj.IC.forEach((anIC) => {
          if (anIC) {
            let current = (ICCounter[anIC] && ICCounter[anIC].current) || 0;
            ICCounter[anIC] = { current: current + 1, notation: anIC };
            firstDigit[anIC.charAt(0)] = [];
          }
        })
    );
    _.reverse(_.sortBy(ICCounter, [(o) => o.current])).forEach(
      (obj) =>
        obj.notation && firstDigit[obj.notation.charAt(0)].push(obj.notation)
    );
    return firstDigit;
  };
}

export default Searcher;
