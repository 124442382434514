import React from "react";
import { Button, Grid, Input } from "semantic-ui-react";

const SearchInputGrid = ({ searchTerm, searching, onChange, doSearch }) => (
  <Grid>
    <Grid.Column width="16">
      {searching ? (
        <Input
          loading
          fluid
          icon="search"
          placeholder="Search..."
          value={searchTerm}
          onKeyPress={event => {
            if (event.key === "Enter") {
              doSearch();
            }
          }}
          onChange={onChange}
        />
      ) : (
        <Input
          fluid
          value={searchTerm}
          placeholder="Type here to start searching"
          action={
            <Button
              color="teal"
              labelPosition="right"
              icon="search"
              content="Search"
              onClick={event => {
                doSearch();
              }}
            />
          }
          actionPosition="left"
          onKeyPress={event => {
            if (event.key === "Enter") {
              doSearch();
            }
          }}
          onChange={onChange}
        />
      )}
    </Grid.Column>
  </Grid>
);

export default SearchInputGrid;
