import React from "react";
import { Link } from "react-router-dom";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class TitlePrint extends DefaultObject {
  render() {
    return (
      <div>
        <B if="TITLE_ABBR">
          <Link to={this.parentURL()}>
            <h4 style={{ marginBottom: "1em" }}>
              <T>TITLE_ABBR</T>
            </h4>
          </Link>
        </B>
        <T>LOCATION_ORIG</T>, <T>PERSON_BSPR</T>, <T>DATE_ORIG</T>
        <br />
        <B if="PERSON_ARTIST">
          Artist: <T br>PERSON_ARTIST</T>
        </B>
        <B if="LOCATION_INV">
          Copy: <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>ID_INV</T>
          <br />
        </B>
        <T br>LINK_FACSIMILE_NOBJ</T>
        <T br>CAPTION_EN</T>
        <T br>TITLE</T>
        <T>DESCRIPTION</T>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
      </div>
    );
  }
}

export default TitlePrint;
