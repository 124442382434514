import React, { Component } from "react";
import { Icon, Popup } from "semantic-ui-react";

import { SEARCH } from "../constants.js";

const languages = [
  { text: "English", value: "en" },
  { text: "German", value: "de" },
  { text: "French", value: "fr" },
  { text: "Italian", value: "it" },
  { text: "Portuguese", value: "pt" },
  { text: "Finnish", value: "fi" },
  { text: "Dutch", value: "nl" },
  { text: "Polish", value: "pl" },
  { text: "Chinese", value: "zh" }
];

let CACHE = {};

/*
["13A1:95A(NESTOR)(+0)", "12B7:31A2262", "11H(Lala: Oink):25FF"].forEach(
  nota => {
    console.log(nota, splitDouble(nota));
  }
)
*/
function splitDouble(no) {
  let inbracket = false;
  let no_list = [];
  let starti = 0;
  let endi = 0;
  for (let i = 0; i < no.length; i++) {
    if (no[i] === "(") inbracket = true;
    if (no[i] === ":" && !inbracket) {
      no_list.push(no.substring(starti, i));
      starti = i + 1;
    }
    if (no[i] === ")") inbracket = false;
    endi = i;
  }
  no_list.push(no.substring(starti, endi + 1));
  return no_list;
}

class Iconclass extends Component {
  constructor(props) {
    super(props);

    const openTreeObj = CACHE[props.openTree];
    let thisOnTreePath = false;
    if (openTreeObj) {
      openTreeObj.p.forEach(pathEntry => {
        if (pathEntry === props.notation) {
          thisOnTreePath = true;
        }
      });
    }

    this.state = { childrenVisible: thisOnTreePath };
    this.languagecode =
      (this.props.search && this.props.search.IClanguagecode) || "en";
  }

  componentDidMount() {
    const obj = CACHE[this.props.notation];
    if (obj) {
      this.setState({ obj: obj });
      return;
    }

    // Check for Double notations
    // This is a double notation for example: 12B7:31A2262
    // But watch out for colons inside brakceted text, eg. 11H(Lala: Oink):25FF
    // Or 13A1:95A(NESTOR)(+0)

    let splittedNotations = splitDouble(this.props.notation);
    if (splittedNotations.length > 1) {
      this.setState({ splittedNotation: splittedNotations[1] });
    }
    let useAsState = true;
    splittedNotations.forEach(splittedNotation => {
      this.fetchObj(splittedNotation, useAsState);
      useAsState = false; // the first time round in the loop it is true, so only the first entry will set the state
    });
  }

  fetchObj(notation, useAsState) {
    // See: http://monsur.hossa.in/2012/07/20/utf-8-in-javascript.html
    let search_url = `${
      SEARCH.PATH_BASE
    }/api/iconclass?notation=${encodeURIComponent(notation).replace(
      "+",
      "%2B"
    )}`;

    fetch(search_url)
      .then(response => response.json())
      .then(result => {
        if (result[notation]) {
          CACHE[notation] = result[notation];
          if (useAsState) {
            this.setState({ obj: result[notation] });
          } else {
            this.setState({ foo: Math.random() });
          }
        }
      })
      .catch(e => {
        console.log("Error in Iconclass Fetch call", e);
      });
  }

  getTextCorrelate(style) {
    let txt = this.state.obj.txt[this.languagecode];
    let splitted = null;
    if (this.state.splittedNotation && CACHE[this.state.splittedNotation]) {
      let splittedObj = CACHE[this.state.splittedNotation];
      splitted = (
        <div style={{ display: "inline" }}>
          <span style={{ fontWeight: "bold", fontSize: "120%" }}>
            &nbsp;:&nbsp;
          </span>
          {splittedObj.txt[this.languagecode]}
        </div>
      );
    }
    return (
      <div style={{ display: "inline" }}>
        <span style={style}>{txt}</span>
        {splitted}
      </div>
    );
  }

  pathrefineContent() {
    const { obj } = this.state;

    const pathSelector =
      obj.p.length > 1 ? (
        <div>
          {obj.p.map(n => (
            <div
              style={{ width: "40em" }}
              key={this.props.notation + "_path_" + n}
            >
              <Iconclass
                shownotation
                showcheckbox
                inline
                notation={n}
                search={this.props.search}
                triggerUpdate={this.props.triggerUpdate}
              />
            </div>
          ))}
        </div>
      ) : (
        <div>Already at the top of the path, no refinement possible</div>
      );
    return (
      <Popup
        trigger={
          <span style={{ cursor: "pointer" }}>{this.getTextCorrelate()}</span>
        }
        size="mini"
        on="click"
        position="bottom center"
      >
        {pathSelector}
      </Popup>
    );
  }

  browseableContent() {
    return (
      <Popup
        trigger={<span>{this.getTextCorrelate()}</span>}
        size="mini"
        wide="very"
        on="click"
        flowing
      >
        <div style={{ width: "40em", maxHeight: "500px", overflowY: "auto" }}>
          <b>Keywords:</b>&nbsp;
          <i>
            {this.state.obj.kw &&
              this.state.obj.kw.en &&
              this.state.obj.kw.en.join(", ")}
          </i>
          <br />
          {this.state.obj.r && (
            <div>
              <b>See also:</b>
              {this.state.obj.r.map(seealso => (
                <Iconclass
                  browseable
                  key={this.props.notation + "_" + seealso}
                  notation={seealso}
                  search={this.props.search}
                  triggerUpdate={this.props.triggerUpdate}
                  showcheckbox
                />
              ))}
            </div>
          )}
        </div>
      </Popup>
    );
  }

  content() {
    if (this.props.pathrefine) return this.pathrefineContent();
    if (this.props.browseable) return this.browseableContent();
    if (!(this.props.pathrefine || this.props.browseable)) {
      return <span>{this.getTextCorrelate()}</span>;
    }
    return null;
  }
  render() {
    if (!this.state.obj) return null;
    const { obj } = this.state;

    let i = null;
    if (typeof obj["c"] === "undefined" || obj["c"].length < 1) {
      i = <Icon name="angle right" style={{ width: "0.4em" }} />;
    } else if (!this.state.childrenVisible) {
      i = (
        <Icon
          style={{ cursor: "pointer", width: "0.4em" }}
          name="caret right"
          onClick={event => this.setState({ childrenVisible: true })}
        />
      );
    } else if (this.state.childrenVisible) {
      i = (
        <Icon
          style={{ cursor: "pointer", width: "0.4em" }}
          name="caret down"
          onClick={event => this.setState({ childrenVisible: false })}
        />
      );
    }
    if (!this.props.browseable) {
      i = null;
    }

    let style = {};
    let contentStyle = {};
    if (this.props.inline) {
      style["display"] = "inline";
      contentStyle = {
        display: "inline"
      };
    } else {
      contentStyle = {
        display: "inline-block",
        verticalAlign: "top",
        width: "85%",
        marginLeft: "0.2em",
        textIndent: "-0.2em"
      };
    }

    return (
      <div style={style}>
        {this.props.showcheckbox && (
          <span>
            {this.props.search.hasFilterEntry("ICPATH", this.props.notation) ? (
              <input type="checkbox" checked disabled />
            ) : (
              <input
                type="checkbox"
                onClick={() => {
                  this.props.search.addFilter([
                    {
                      filterId: null,
                      filterType: "A",
                      fieldName: "ICPATH",
                      fieldValue: this.props.notation
                    }
                  ]);
                  this.props.triggerUpdate && this.props.triggerUpdate();
                }}
              />
            )}
            &nbsp;
          </span>
        )}
        {i}
        <div style={contentStyle}>
          {this.props.shownotation && this.props.notation}&nbsp;
          {this.content()}
          {this.state.childrenVisible
            ? this.state.obj.c.map(c => (
                <Iconclass
                  openTree={this.props.openTree}
                  key={c}
                  notation={c}
                  search={this.props.search}
                  triggerUpdate={this.props.triggerUpdate}
                  browseable={this.props.browseable}
                  shownotation={this.props.shownotation}
                  showcheckbox={this.props.showcheckbox}
                />
              ))
            : ""}
        </div>
      </div>
    );
  }
}

export { Iconclass, languages };
