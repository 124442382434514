import React from "react";
import { Link } from "react-router-dom";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class BookIllustration extends DefaultObject {
  render() {
    return (
      <div>
        <B if="TITLE|TITLE_ABBR">
          <Link to={this.parentURL()}>
            <h4 style={{ marginBottom: "1em" }}>
              <T>TITLE_ABBR|TITLE</T>
            </h4>
          </Link>
        </B>

        <br />
        <B if="LOCATION_ORIG">
          <b>Edition:</b> <T>LOCATION_ORIG</T> &middot; <T>PERSON_BSPR</T>{" "}
          <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="PERSON_ARTIST">
          <b>Artist:</b> <T br>PERSON_ARTIST</T>
        </B>
        <B if="LOCATION_INV">
          <b>Copy:</b> <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>ID_INV</T>
          <br />
        </B>
        <T br>LINK_FACSIMILE</T>
        <B if="CAPTION_EN">
          <b>Caption:</b> <T br>CAPTION_EN</T>
        </B>
        <B if="CAPTION_LA">
          <b>Caption:</b> <T br>CAPTION_LA</T>
        </B>
        <B if="CAPTION_FR">
          <b>Caption:</b> <T br>CAPTION_FR</T>
        </B>
        <B if="CAPTION_IT">
          <b>Caption:</b> <T br>CAPTION_IT</T>
        </B>
        <B if="CAPTION_NL">
          <b>Caption:</b> <T br>CAPTION_NL</T>
        </B>
        <B if="CAPTION_DE">
          <b>Caption:</b> <T br>CAPTION_DE</T>
        </B>
        <B if="PRINTER">
          <b>Printer:</b> <T br>PRINTER</T>
        </B>
        <B if="PLACE">
          <T br>PLACE</T>
        </B>
        <B if="FROM">
          <T>FROM</T> - <T>UNTIL</T>
        </B>
        <B if="DESCRIPTION">
          <b>Description:</b> <T>DESCRIPTION</T>
        </B>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <B if="TEXT_EXPL_XML">
          <div
            style={{
              backgroundColor: "#fefefe",
              padding: "1em",
              marginTop: "1em"
            }}
          >
            <h4>Transcription</h4>
            <T safe>TEXT_EXPL_XML</T>
          </div>
        </B>
      </div>
    );
  }
}

export default BookIllustration;
