import React from "react";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";

import CERLHeader from "../cerl/CERLHeader";
import { HIMs, HIMHeader } from "../HIMHeader";
import DefaultPageHeader from "./DefaultPageHeader";

const defaultHeaderExtras = [
  <Button style={{ marginTop: "2.75em" }}>
    <Link to={`${process.env.REACT_APP_}/lists/`}>Lists</Link>
  </Button>,
  <Button style={{ marginTop: "2.75em" }}>
    <Link to={`${process.env.REACT_APP_}/cerlpda/`}>CERL PDA</Link>
  </Button>
];

function PageHeader({
  section = null,
  extras = defaultHeaderExtras,
  search,
  history
}) {
  if (section === "him_CERLPDA" || section === "him_CERLCANYOUHELP") {
    return (
      <CERLHeader
        section={section}
        extras={extras}
        search={search}
        history={history}
      />
    );
  }
  if (HIMs[section]) {
    return (
      <HIMHeader
        section={section}
        extras={extras}
        search={search}
        history={history}
      />
    );
  }

  return (
    <DefaultPageHeader
      section={section}
      extras={extras}
      search={search}
      history={history}
    />
  );
}

export default PageHeader;
