import React, { Component } from "react";
import { Grid, Image } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { Link } from "react-router-dom";

import SearchInputGrid from "./liminal/SearchInputGrid";
import PageFooter from "./liminal/PageFooter";
import PageHeader from "./liminal/PageHeader";
import medievalmanuscript from "../assets/images/medievalmanuscript.jpg";
import alchemy from "../assets/images/alchemy.jpg";
import emblembook from "../assets/images/emblembook.jpg";
import fable from "../assets/images/fable.jpg";
import metamorphoses from "../assets/images/metamorphoses.jpg";
import painting from "../assets/images/painting.jpg";
import photography from "../assets/images/photography.jpg";
import print from "../assets/images/print.jpg";
import printersdevice from "../assets/images/printersdevice.jpg";
import proverb from "../assets/images/proverb.jpg";
import satire from "../assets/images/satire.jpg";
import drawing from "../assets/images/drawing.jpg";
import naturalhistory from "../assets/images/naturalhistory.jpg";
import cerl from "../assets/images/cerl.jpg";
import { SECTIONS } from "../constants";

const IMAGES = {
  medievalmanuscript: medievalmanuscript,
  emblembook: emblembook,
  painting: painting,
  satire: satire,
  printersdevice: printersdevice,
  photography: photography,
  print: print,
  proverb: proverb,
  fable: fable,
  metamorphoses: metamorphoses,
  alchemy: alchemy,
  drawing: drawing,
  naturalhistory: naturalhistory,
  cerlpda: cerl,
};

const SectionBlock = ({
  color = "#fff",
  image = "",
  section = "all",
  children,
}) => (
  <div>
    <div
      style={{
        minHeight: "50px",
        fontSize: "130%",
        backgroundColor: color,
        padding: "0.5em",
      }}
    >
      <Link
        to={`${process.env.REACT_APP_URL_PATH}/section/${section}/`}
        style={{ display: "block", height: "100%", color: "white" }}
      >
        {children}
      </Link>
    </div>
    <div style={{ float: "right" }}>
      <Image src={image} />
    </div>
  </div>
);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm,
    };
  }

  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () => {
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`);
    });
  };
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader search={this.props.search} history={this.props.history} />

        <Grid columns="3" stackable>
          <Grid.Column>&nbsp;</Grid.Column>
          <Grid.Column>&nbsp;</Grid.Column>
          {process.env.REACT_APP_INCLUDE_PDF_TUTORIAL === "1" && (
            <Grid.Column
              style={{
                fontSize: "120%",
                backgroundColor: "#ccc",
                padding: "1em",
              }}
            >
              <a href={process.env.REACT_APP_PDF_TUTORIAL_LINK}>
                How to use {process.env.REACT_APP_PROJECT_NAME} - a Tutorial
              </a>
            </Grid.Column>
          )}
        </Grid>

        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={(event) =>
            this.setState({ searchTerm: event.target.value })
          }
          doSearch={this.doSearch}
        />
        <Grid columns={3} stackable>
          {SECTIONS.map((sobj) => (
            <Grid.Column key={sobj.key}>
              <SectionBlock
                section={sobj.key}
                color={sobj.color}
                image={IMAGES[sobj.image]}
              >
                {sobj.text}
              </SectionBlock>
            </Grid.Column>
          ))}
        </Grid>

        <PageFooter />
      </div>
    );
  }
}

export default Home;
