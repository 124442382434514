import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";

class Book extends DefaultObject {
  render() {
    return (
      <div>
        <T br>PERSON_AUTHOR</T>
        <i>
          <T br>TITLE_SHOW</T>
        </i>
        <br />
        <B if="IMPRESSUM">
          <b>Impressum:</b> <T br>IMPRESSUM</T>
        </B>
        <B if="LOCATION_ORIG">
          <b>Edition:</b> <T>LOCATION_ORIG</T> &middot; <T>PERSON_BSPR</T>{" "}
          &middot; <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="LOCATION_INV">
          <b>Copy:</b> <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>ID_INV</T>
          <br />
        </B>
        <T br>LINK_FACSIMILE</T>
        <T br>FACET|TITLEPAGE</T>
        <T>SOU</T>
        {this.children()}
      </div>
    );
  }
}

export default Book;
