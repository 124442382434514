import React from "react";
import { Link } from "react-router-dom";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class Facsimile extends DefaultObject {
  render() {
    return (
      <div>
        <B if="PARENT">
          <Link to={this.parentURL()}>
            <h4 style={{ marginBottom: "1em" }}>
              <T>TITLE_SHOW|TITLE</T>
            </h4>
          </Link>
        </B>

        <br />
        <B if="LOCATION_ORIG">
          <b>Edition:</b> <T>LOCATION_ORIG</T> &middot; <T>PERSON_BSPR</T>{" "}
          &middot; <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="LOCATION_INV">
          <b>Copy:</b> <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>ID_INV</T>
          <br />
        </B>
        <B if="BIBLIO_FORM">
          <T br>BIBLIO_FORM</T>
        </B>
        <B if="PERSON_BSPR">
          <b>Bookseller/Printer:&nbsp;</b>
          <T br>PERSON_BSPR</T>
        </B>
        {this.props.obj.TXT && (
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.obj.TXT.join("\n")
            }}
          />
        )}
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <B if="TEXT_EXPL_XML">
          <div
            style={{
              backgroundColor: "#fefefe",
              padding: "1em",
              marginTop: "1em"
            }}
          >
            <h4>Transcription</h4>
            <T safe>TEXT_EXPL_XML</T>
          </div>
        </B>
      </div>
    );
  }
}

export default Facsimile;
