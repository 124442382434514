import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class ImageA extends DefaultObject {
  render() {
    return (
      <div>
        <T br>TITLE_AGGR</T>
        <T>PERSON_AUTHOR</T>
        <B if="TITLE_ABBR">
          , <T>TITLE_ABBR</T>
        </B>
        <B if="IMPRESSUM">
          , <T>IMPRESSUM</T>
          <br />
        </B>
        <T br>TYPE_INS</T>
        <T>PERSON_ARTIST</T>
        <B if="CREATOR">
          &middot; <T>CREATOR</T>
        </B>
        <B if="CONTRIBUTOR">
          &middot; <T>CONTRIBUTOR</T>
        </B>
        <div
          style={{ fontStyle: "italic", fontSize: "120%", padding: "0.5em" }}
        >
          <T>TITLE|CAPTION_EN|CAPTION_NL|CAPTION_DE|CAPTION_IT</T>
        </div>
        <T>LOCATION</T> <T>DATE_ORIG</T> <T>DATE</T>
        <br />
        <T br>ANNOT</T>
        <T br>INSCRIPTION_LA</T>
        <T br>DESCRIPTION</T>
        <B if="LOCATION_INV">
          <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>INSTIT_ID</T>
          <br />
        </B>
        <B if="KW_NL">
          <b>Dutch keywords:</b> &nbsp;{" "}
          <T br all=", ">
            KW_NL
          </T>
        </B>
        <T>DIMENSION</T>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <B if="KW_ZH">
          <b>Chinese keywords:</b> &nbsp;{" "}
          <T br all=", ">
            KW_ZH
          </T>
        </B>
        <B if="KW_EN">
          <b>(Google-) Generated English:</b> &nbsp;{" "}
          <T br all=", ">
            KW_EN
          </T>
        </B>
      </div>
    );
  }
}

export default ImageA;
