import React from "react";
import { Link } from "react-router-dom";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class EmblemPictura extends DefaultObject {
  render() {
    return (
      <div>
        <B if="TITLE_ABBR">
          <Link to={this.parentURL()}>
            <h4 style={{ marginBottom: "1em" }}>
              <T>TITLE_ABBR</T>
            </h4>
          </Link>
        </B>
        <B if="LOCATION_ORIG">
          <b>Edition:</b> <T>LOCATION_ORIG</T> &middot; <T>PERSON_BSPR</T>{" "}
          <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="PERSON_AUTHOR">
          <b>Author:</b> <T br>PERSON_AUTHOR</T>
        </B>
        <B if="PERSON_ARTIST">
          <b>Artist:</b> <T br>PERSON_ARTIST</T>
        </B>
        <B if="LOCATION_INV">
          <b>Copy:</b> <T>LOCATION_INV</T> <T>INSTIT_INV</T> &middot;{" "}
          <T br>ID_INV</T>
        </B>
        <B if="BIBLIO_FORM">
          <T br>BIBLIO_FORM</T>
        </B>
        <B if="PERSON_BSPR">
          <T br>PERSON_BSPR</T>
        </B>
        <T br>DEDICATION_LA</T>
        <B if="CAPTION_EN">
          <div
            style={{ fontStyle: "italic", padding: "1em", textAlign: "center" }}
          >
            <T>CAPTION_EN</T>
          </div>
        </B>
        <B if="MOTTO_LA">
          <T br>MOTTO_LA</T>
        </B>
        <B if="MOTTO_EN">
          <T br>MOTTO_EN</T>
        </B>
        <B if="MOTTO_FR">
          <T br>MOTTO_FR</T>
        </B>
        <B if="MOTTO_NL">
          <T br>MOTTO_NL</T>
        </B>
        <B if="MOTTO_DE">
          <T br>MOTTO_DE</T>
        </B>
        <B if="MOTTO_IT">
          <T br>MOTTO_IT</T>
        </B>
        <B if="MOTTO_ES">
          <T br>MOTTO_ES</T>
        </B>
        <B if="MOTTO_EL">
          <T br>MOTTO_EL</T>
        </B>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />

        <T>DESCRIPTION</T>
        <B if="TEXT_EXPL_XML">
          <div
            style={{
              backgroundColor: "#fefefe",
              padding: "1em",
              marginTop: "1em"
            }}
          >
            <h4>Transcription</h4>
            <T safe>TEXT_EXPL_XML</T>
          </div>
        </B>
      </div>
    );
  }
}

export default EmblemPictura;
