import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class Ornament extends DefaultObject {
  render() {
    return (
      <div>
        <ZoomedImage
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <B if="PRINTER">
          <T br>PRINTER</T>
        </B>
        <B if="PLACE">
          <T br>PLACE</T>
        </B>
        <B if="FROM">
          <T>FROM</T> - <T>UNTIL</T>
        </B>
      </div>
    );
  }
}

export default Ornament;
