import React, { Component } from "react";
import { Menu } from "semantic-ui-react";

class PageMenu extends Component {
  state = {};
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    let organizationName = "";
    const ORGANISATION = document.querySelector("meta[name='ORGANISATION']");
    if (ORGANISATION) {
      organizationName = ORGANISATION.getAttribute("content");
    }
    return (
      <Menu stackable compact size="mini" borderless>
        <Menu.Item
          icon="home"
          active={activeItem === "home"}
          onClick={(event) => (document.location = "/")}
        />
        {organizationName ? (
          <Menu.Item>{organizationName}</Menu.Item>
        ) : (
          <Menu.Item
            name="Login via your Institution"
            icon="key"
            onClick={(event) =>
              this.props.history.push(
                `${process.env.REACT_APP_URL_PATH}/help/access`
              )
            }
          />
        )}
        {process.env.REACT_APP_DISPLAY_HELP_PAGE === "1" && (
          <Menu.Item
            name="help"
            active={activeItem === "help"}
            onClick={(event) =>
              this.props.history.push(
                `${process.env.REACT_APP_URL_PATH}/help/start`
              )
            }
          >
            Help
          </Menu.Item>
        )}
      </Menu>
    );
  }
}

export default PageMenu;
