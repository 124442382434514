import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";

import "./assets/index.css";
import App from "./App";
//import registerServiceWorker from "./registerServiceWorker";

window._ll = window._ll || [];

ReactDOM.render(<App />, document.getElementById("root"));
//registerServiceWorker();
