import React, { Component } from "react";

import { Iconclass } from "../Iconclass";
import { ICONCLASS, HIM, TYPES } from "../../constants";

const lookUpTable = {
  TIPE: TYPES,
  HIM: HIM,
  ICPATH: ICONCLASS,
};

class FilterView extends Component {
  render() {
    const { aFilter } = this.props;
    // fieldName can be HIM, TIPE, ICPATH
    if (aFilter.fieldName === "ICPATH")
      return (
        <Iconclass
          triggerUpdate={this.props.triggerUpdate}
          notation={aFilter.fieldValue}
          inline
          pathrefine
          aFilter={aFilter}
          search={this.props.search}
          shownotation
        />
      );
    return (
      lookUpTable[aFilter.fieldName][aFilter.fieldValue] || aFilter.fieldValue
    );
  }
}

export default FilterView;
