import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class PrintersDeviceUsage extends DefaultObject {
  render() {
    return (
      <div>
        <T br>CAPTION_EN</T>
        <B if="SHOW_NAME">
          <h4 style={{ marginTop: "1em", marginBottom: 0 }}>Used by:</h4>
          {this.props.obj.SHOW_NAME &&
            this.props.obj.SHOW_NAME.map(name => <div key={name}>{name}</div>)}
        </B>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
      </div>
    );
  }
}

export default PrintersDeviceUsage;
