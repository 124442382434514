import React, { Component } from "react";
import PropTypes from "prop-types";

class B extends Component {
  render() {
    if (!this.context.obj) return null;
    if (!this.props.if) return null;
    let tmp = null;
    this.props.if.split("|").forEach(value => {
      if (this.context.obj[value] && this.context.obj[value].length > 0) {
        tmp = (
          <div style={{ display: this.props.div ? "block" : "inline" }}>
            {this.props.children}
          </div>
        );
      }
    });
    return tmp;
  }
}

B.contextTypes = {
  obj: PropTypes.object
};

export default B;
