import React from "react";
import { Link } from "react-router-dom";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class ManuscriptIllumination extends DefaultObject {
  render() {
    return (
      <div>
        {this.props.obj.PARENT ? (
          <Link to={this.parentURL()}>
            <T>LOCATION_INV</T> <T>INSTIT_INV</T>, MS <T>ID_INV</T>
          </Link>
        ) : (
          <div>
            <T>LOCATION_INV</T> <T>INSTIT_INV</T>, MS <T>ID_INV</T>
          </div>
        )}

        <br />
        <T br>TITLE_ABBR</T>
        <T br>TEXT</T>
        <B if="FOLIO_SHOW">
          <b>Folio:</b> <T>FOLIO_SHOW</T> <T>ILL_TYPE</T> <T>MR</T>
        </B>
        <div
          style={{ fontStyle: "italic", fontSize: "120%", padding: "0.5em" }}
        >
          <T>CAPTION_EN|CAPTION_NL</T>
        </div>
        <B if="TRANSCRIPTION">
          <b>Transcription: </b>
          <T>TRANSCRIPTION</T>
        </B>

        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
      </div>
    );
  }
}

export default ManuscriptIllumination;
