import React, { Component } from "react";
import { Segment } from "semantic-ui-react";
import _ from "lodash";

import { ICONCLASS, HIM, TYPES } from "../../constants";

const lookupTable = {
  TIPE: TYPES,
  HIM: HIM,
  ICPATH: ICONCLASS
};

class Filter extends Component {
  onFilterAdd = facetValue => {
    this.props.search.addFilter([
      {
        filterId: null,
        filterType: "O",
        fieldName: this.props.facetType,
        fieldValue: facetValue
      }
    ]);
    this.props.triggerUpdate();
  };

  render() {
    const searchResults = this.props.search.getSectionResults(
      this.props.section
    );
    let srchrslt_facets = {};
    if (searchResults.facets && searchResults.facets[this.props.facetType]) {
      searchResults.facets[this.props.facetType].forEach(facet => {
        srchrslt_facets[facet.key] = facet.doc_count;
      });
    }
    if (
      this.props.section.startsWith("him_") &&
      Object.keys(srchrslt_facets).length < 2
    )
      return null;

    // The convoluted preparation adding items to 'gathered' is necessary to sort the facets by "value"
    let gathered = {};
    for (let key in lookupTable[this.props.facetType]) {
      if (this.props.section.startsWith("him_") && !srchrslt_facets[key])
        continue;
      gathered[key] = {
        key: key,
        value: lookupTable[this.props.facetType][key],
        count: srchrslt_facets[key] && `(${srchrslt_facets[key]})`
      };
    }
    let facets = _.sortBy(gathered, [x => x.value]).map(obj => (
      <div key={obj.key}>
        {this.props.search.hasFilterEntry(this.props.facetType, obj.key) ? (
          <input type="checkbox" checked disabled />
        ) : (
          <input type="checkbox" onClick={() => this.onFilterAdd(obj.key)} />
        )}
        &nbsp;
        <span
          style={{
            color: obj.count ? "#000" : "#aaa"
          }}
        >{`${obj.value} ${obj.count || ""}`}</span>
      </div>
    ));

    return (
      <Segment vertical>
        <h5 className="ui header">{this.props.children}</h5>
        {facets}
      </Segment>
    );
  }
}

export default Filter;
