import React, { Component } from "react";

class Uploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      progress: 0,
      text: "Choose a JPG image to upload"
    };
  }

  uploadFile(file) {
    if (file) {
      if (
        !(
          file.name.toLowerCase().endsWith(".jpg") ||
          file.name.toLowerCase().endsWith(".jpeg")
        )
      ) {
        alert("You can only upload JPEG files");
        return;
      }
      this.setState({ uploading: true });
      const formData = new FormData();
      const xhr = new XMLHttpRequest();

      formData.append(this.props.fieldName, file, file.name);
      formData.append("token", this.props.search.credentials.token);

      xhr.onload = () => {
        this.setState({ progress: 100 });

        if (xhr.status === 200) {
          const response = JSON.parse(xhr.response);
          this.setState({
            text: `New filename: ${response.newfilename}`,
            uploading: false
          });
          if (this.props.callbackDone) {
            this.props.callbackDone(file.name, response.newfilename);
          }
        }
      };

      xhr.upload.onprogress = e => {
        if (e.lengthComputable) {
          this.setState({
            progress: `${file.name}   ${parseInt(
              (e.loaded / e.total) * 100,
              10
            )}`
          });
        }
      };

      xhr.onerror = () => {
        this.setState({ text: "Error sending file" });
      };

      xhr.open(this.props.method, this.props.url, true);
      xhr.send(formData);
    }
  }

  onFileSelect = () => {
    for (var i = 0; i < this.fileInput.files.length; i++) {
      this.uploadFile(this.fileInput.files[i]);
    }
  };

  render() {
    if (!this.props.search.credentials) {
      return <div>You need to be logged in to do uploads</div>;
    } else
      return (
        <div onClick={event => this.fileInput.click()} style={this.props.style}>
          {this.state.uploading
            ? `${this.state.progress} %`
            : this.props.children}

          <input
            style={{ display: "none" }}
            multiple
            type="file"
            ref={c => {
              if (c) {
                this.fileInput = c;
              }
            }}
            onChange={this.onFileSelect}
          />
        </div>
      );
  }
}
Uploader.defaultProps = {
  method: "POST",
  fieldName: "datafile"
};

export default Uploader;
