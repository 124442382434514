import React, { Component } from "react";
import { Segment } from "semantic-ui-react";

import IconclassFilter from './IconclassFilter';
import Filter from './Filter';

class FacetBox extends Component {
  render() {
    return (
      <Segment.Group raised style={{ paddingLeft: "0.5em" }}>
        <IconclassFilter
          triggerUpdate={this.props.triggerUpdate}
          section={this.props.section}
          search={this.props.search}
          onChangeICLanguage={this.props.onChangeICLanguage}
        />
        <Filter
          triggerUpdate={this.props.triggerUpdate}
          section={this.props.section}
          search={this.props.search}
          facetType="TIPE"
        >
          Type
        </Filter>
        {this.props.section && !this.props.section.startsWith("him_") && (
          <Filter
            triggerUpdate={this.props.triggerUpdate}
            section={this.props.section}
            search={this.props.search}
            facetType="HIM"
          >
            Collection
          </Filter>
        )}
      </Segment.Group>
    );
  }
}

export default FacetBox;
