import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class ProvenanceDocument extends DefaultObject {
  render() {
    return (
      <div>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <B if="LOCATION_ORIG">
          Place and date: <T>LOCATION_ORIG</T>, <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="PERSON_OWNER">
          Owner: <T br>PERSON_OWNER</T>
        </B>
        <B if="TYPE_INS">
          Type: <T br>TYPE_INS</T>
        </B>
        <B if="LOCATION_INV">
          Location: <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>ID_INV</T>
        </B>
        <B if="CAPTION_EN">
          Description: <T br>CAPTION_EN</T>
        </B>
        <B if="CAPTION_ADD_EN">
          Additional: <T br>CAPTION_ADD_EN</T>
        </B>
        <B if="INSCRIPTION_EN">
          Inscription: <T br>INSCRIPTION_EN</T>
        </B>
        <B if="INSCRIPTION_LA">
          Inscription: <T br>INSCRIPTION_LA</T>
        </B>
        <B if="INSCRIPTION_EL">
          Inscription: <T>INSCRIPTION_EL</T>
        </B>
      </div>
    );
  }
}

export default ProvenanceDocument;
