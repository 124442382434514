import React, { Component } from "react";
import PropTypes from "prop-types";

class T extends Component {
  render() {
    if (!this.props.children) return null;
    if (!this.context.obj) return null;
    let fields = this.props.children.split("|");
    let txt = "";
    for (let x in fields) {
      txt = this.context.obj[fields[x]];
      if (txt) break;
    }
    if (txt) {
      if (this.props.safe) {
        txt = txt.join("").replace(/<lb\/>/g, "<br/>"); // The TEI transcriptions have <lb/> in them...
        return <div dangerouslySetInnerHTML={{ __html: txt }} />;
      }
      if (this.props.all) {
        txt = txt.join(this.props.all);
      } else {
        txt = txt.join(" ");
      }
      if (this.props.max) {
        if (txt.length > this.props.max) {
          return txt.slice(0, this.props.max) + "...";
        } else {
          return txt;
        }
      } else {
        if (this.props.br) {
          return <span dangerouslySetInnerHTML={{ __html: txt + "<br/>" }} />;
        }
        return txt; // values in the obj are lists, only return the first one
      }
    } else {
      return null;
    }
  }
}

T.contextTypes = {
  obj: PropTypes.object
};

export default T;
