import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";

class EmblemBook extends DefaultObject {
  render() {
    return (
      <div>
        <h4>
          <T br>TITLE_SHOW|TITLE_IDX</T>
        </h4>
        <B if="PERSON_AUTHOR">
          <b>Author:</b> <T br>PERSON_AUTHOR</T>
        </B>
        <B if="IMPRESSUM">
          <b>Impressum:</b> <T br>IMPRESSUM</T>
        </B>
        <B if="PERSON_ARTIST">
          <b>Illustrations:</b> <T br>PERSON_ARTIST</T>
        </B>
        <B if="LOCATION_ORIG">
          <b>Edition:</b> <T>LOCATION_ORIG</T> <T>PERSON_BSPR</T>{" "}
          <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="LOCATION_INV">
          <b>Copy:</b> <T>LOCATION_INV</T> <T>INSTIT_INV</T> <T>ID_INV</T>
          <br />
        </B>

        {this.children()}
      </div>
    );
  }
}

export default EmblemBook;
