import React from "react";

import {
  DefaultObject, ManuscriptIllumination, AggregatedObject,
  BibliographyContainer, Bibliography, Book, BookIllustration,
  EmblemBook, EmblemPictura, Facsimile, ImageA,
  ImagesFromPeriodicals, Journal, ManuscriptBook, PrintersDevice,
  PrintersDeviceUsage, ProvenanceImage, ProvenanceDocument,
  ProvenanceItem, ProvenanceSource, Proverb, TitlePage,
  TitlePrint, Ursicula, Person, Essay, Ornament
} from "./objects";


function ObjChooser(obj, section, search, history) {
  if (!obj.TYPE)
    return (
      <DefaultObject obj={obj} section={section} search={search} history={history} />
    );
  let t = obj.TYPE[0].toLowerCase().replace(/ /g, "");
  switch (t) {
    case "initial":
      return (
        <Ursicula
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "ornament":
      return (
        <Ornament
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "manuscriptillumination":
      return (
        <ManuscriptIllumination
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "aggregatedobject":
      return (
        <AggregatedObject
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "bibliographycontainer":
      return (
        <BibliographyContainer
          obj={obj}
          section={section}
          search={search}
          history={history}
          children="__all__"
        />
      );
    case "bibliography":
      return (
        <Bibliography
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "book":
      return (
        <Book
          obj={obj}
          section={section}
          search={search}
          history={history}
          children="__all__"
        />
      );
    case "bookillustration":
      return (
        <BookIllustration
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "emblembook":
      return (
        <EmblemBook
          obj={obj}
          section={section}
          search={search}
          history={history}
          children="emblempictura"
        />
      );
    case "emblempictura":
      return (
        <EmblemPictura
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "facsimile":
      return (
        <Facsimile
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "image":
      return (
        <ImageA
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "imagesfromperiodicals":
      return (
        <ImagesFromPeriodicals
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "journal":
      return (
        <Journal
          obj={obj}
          section={section}
          search={search}
          history={history}
          children="__all__"
        />
      );
    case "manuscriptbook":
      return (
        <ManuscriptBook
          obj={obj}
          section={section}
          search={search}
          history={history}
          children="__all__"
        />
      );
    case "printersdevice":
      return (
        <PrintersDevice
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "printersdeviceusage":
      return (
        <PrintersDeviceUsage
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "provenanceimage":
      return (
        <ProvenanceImage
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "provenancedocument":
      return (
        <ProvenanceDocument
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "provenanceitem":
      return (
        <ProvenanceItem
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "provenancesource":
      return (
        <ProvenanceSource
          obj={obj}
          section={section}
          search={search}
          history={history}
          children="__all__"
        />
      );
    case "proverb":
      return (
        <Proverb
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "titlepage":
      return (
        <TitlePage
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "titleprint":
      return (
        <TitlePrint
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "ursicula":
      return (
        <Ursicula
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "person":
      return (
        <Person
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    case "essay":
      return (
        <Essay
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
    default:
      return (
        <DefaultObject
          obj={obj}
          section={section}
          search={search}
          history={history}
        />
      );
  }
}

export { ObjChooser };
