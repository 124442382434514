import React from "react";
import { Link } from "react-router-dom";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class ImagesFromPeriodicals extends DefaultObject {
  render() {
    return (
      <div>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <B if="TITLE_SHOW">
          {this.props.obj.PARENT ? (
            <h4 style={{ marginBottom: "1em" }}>
              <Link to={this.parentURL()}>
                <T>TITLE_SHOW</T>
              </Link>
            </h4>
          ) : (
            <h4 style={{ marginBottom: "1em" }}>
              <T>TITLE_SHOW</T>
            </h4>
          )}
        </B>
        <T br>IMPRESSUM</T>
        <B if="LOC">
          Vol. <T br>LOC</T>
        </B>
        <T br>TITLE_IT</T>
        <T>PERSON_BSPR</T> <T>DATE_ORIG</T> <T>PERSON_ARTIST</T>
        <B if="CREATOR">
          <b>Artist:</b> <T br>CREATOR</T>
        </B>
        <br />
        <B if="CAPTION_EN">
          <i title="English text by Google Translate">
            <T br>CAPTION_EN</T>
          </i>
        </B>
        <hr />
        <T br>CAPTION_IT</T>
        <hr />
        <T>ANNOT</T>
      </div>
    );
  }
}

export default ImagesFromPeriodicals;
