import React, { Component } from "react";
import { Button, Dropdown, Segment, Popup } from "semantic-ui-react";

import FilterView from './FilterView';

class FilterBox extends Component {
  render() {
    if (Object.keys(this.props.search.filters).length < 1) return null;
    return (
      <Segment.Group raised style={{ paddingLeft: "0.5em" }}>
        <Segment vertical>
          {Object.keys(this.props.search.filters).length > 0 ? (
            <div>
              <h4>This is what you searched for:</h4>
              {Object.keys(this.props.search.filters).map(aFilter => (
                <div key={this.props.search.filters[aFilter].filterId}>
                  <Popup
                    trigger={
                      <Button
                        icon="remove"
                        size="mini"
                        compact
                        onClick={() => {
                          this.props.search.addFilter([
                            {
                              filterId: this.props.search.filters[aFilter]
                                .filterId,
                              filterType: null
                            }
                          ]);
                          this.props.triggerUpdate();
                        }}
                      />
                    }
                    content="Remove this filter"
                    size="mini"
                  />

                  <Dropdown
                    inline
                    defaultValue={this.props.search.filters[aFilter].filterType}
                    options={[
                      {
                        text: "- On Hold -",
                        value: "_"
                      },
                      { text: "Must have", value: "A" },
                      { text: "Include", value: "O" },
                      { text: "Exclude", value: "N" }
                    ]}
                    onChange={(event, data) => {
                      this.props.search.addFilter([
                        {
                          filterId: this.props.search.filters[aFilter].filterId,
                          filterType: data.value,
                          fieldName: this.props.search.filters[aFilter]
                            .fieldName,
                          fieldValue: this.props.search.filters[aFilter]
                            .fieldValue
                        }
                      ]);
                      this.props.triggerUpdate();
                    }}
                  />
                  <FilterView
                    aFilter={this.props.search.filters[aFilter]}
                    search={this.props.search}
                    triggerUpdate={this.props.triggerUpdate}
                  />
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </Segment>
      </Segment.Group>
    );
  }
}

export default FilterBox;
