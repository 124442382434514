import { SEARCH } from "../constants.js";

function deleteEntry(pk, token, callback) {
  let url = `${SEARCH.PATH_BASE}${SEARCH.PATH_ENTRY}`;
  const payload = {
    token: token,
    action: "delete",
    pk: pk
  };
  POSTer(url, payload, callback);
}

function addEntry(pk, uid, field, value, token, callback) {
  let url = `${SEARCH.PATH_BASE}${SEARCH.PATH_ENTRY}`;
  const payload = {
    pk: pk,
    token: token,
    list_pk: "_annotations",
    uid: uid,
    field: field,
    value: value
  };
  POSTer(url, payload, callback);
}

// retrieve lists from the server
// pk is either a primary key of a list to retrieve, or null to get all for the current user
function getLists(pk, token, callback) {
  // Requests are made to /api/lists with a choice of params ?id= (can be more than one to retrieve lists)
  // ?token= to identify a user
  // if none of 'id' or 'token' are specified, all the public lists are returned
  let search_url = `${SEARCH.PATH_BASE}${SEARCH.PATH_LISTS}?token=${token}`;
  if (pk) {
    search_url = `${SEARCH.PATH_BASE}${
      SEARCH.PATH_LISTS
    }?id=${pk}&token=${token}`;
  }

  // console.log(search_url);
  fetch(search_url, {
    credentials: "include"
  })
    .then(response => response.json())
    .then(result => {
      if (callback) {
        callback(result);
      }
    })
    .catch(e => {
      console.log(e);
    });
}

function deleteList(obj, token, callback) {
  let url = `${SEARCH.PATH_BASE}${SEARCH.PATH_LISTS}?token=${token}`;
  const payload = { obj: obj, token: token, action: "delete" };
  POSTer(url, payload, callback);
}
function modifyList(obj, token, callback) {
  let url = `${SEARCH.PATH_BASE}${SEARCH.PATH_LISTS}?token=${token}`;
  const payload = { obj: obj, token: token, action: "modify" };
  POSTer(url, payload, callback);
}

function deleteObject(obj, token, callback) {
  modifyObject("delete", obj, token, callback);
}

function saveObject(obj, token, callback) {
  modifyObject("save", obj, token, callback);
}

function modifyObject(action, obj, token, callback) {
  /* Saves an object to the index and database using the API
  You need to specify a JWT authentication token for user identification
  */
  const url = `${SEARCH.PATH_BASE}${SEARCH.PATH_MODIFY}`;
  const payload = { obj: obj, token: token, action: action };
  POSTer(url, payload, callback);
}

function POSTer(url, payload, callback) {
  fetch(url, {
    body: JSON.stringify(payload),
    cache: "no-cache",
    method: "POST",
    credentials: "include"
  })
    .then(response => response.json())
    .then(result => {
      if (callback) {
        callback(result);
      }
    })
    .catch(e => {
      console.log(e);
    });
}

export {
  addEntry,
  deleteEntry,
  getLists,
  deleteList,
  modifyList,
  saveObject,
  deleteObject,
  POSTer
};
