import React from "react";
import { Link } from "react-router-dom";

import bibliasacrabanner from "../assets/images/banners/bibliasacra.jpg";

/*
UIUC: 20-112-133
EPU: 255-255-242
MSS: 128-22-26
RKD: 199-199-199
VKK: 255-255-255
ALC: 198-164-11$
BVH: 199-199-199
GEDP: 218-224-210
*/

const HIMs = {
  him_BIBLIASACRA: {
    color: "#000",
    text: "Biblia Sacra",
    image: bibliasacrabanner
  }
};

const HIMHeader = ({ section, search, history }) => {
  return (
    <div
      style={{
        backgroundColor: HIMs[section].color,
        marginBottom: "1em",
        height: 109
      }}
    >
      <Link to={`${process.env.REACT_APP_URL_PATH}/`}>
        <img
          alt={`${process.env.REACT_APP_PROJECT_NAME} Logo`}
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PROJECT_LOGO}`} style={{ float: "left" }}
        />
      </Link>
      <img
        alt={HIMs[section].text}
        src={HIMs[section].image}
        style={{ float: "left" }}
      />
    </div>
  );
}

export { HIMs, HIMHeader };
