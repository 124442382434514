import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";

class AggregatedObject extends DefaultObject {
  render() {
    return (
      <div>
        <T>PERSON_AUTHOR</T>, <T>TITLE_ABBR</T>, <T br>IMPRESSUM</T>
        <T br>TYPE_INS</T>
        <B if="PERSON_ARTIST">
          <T>PERSON_ARTIST</T> &middot; <T>CREATOR</T> &middot;
          <T>CONTRIBUTOR</T>
          <br />
        </B>
        <T br>TITLE</T>
        <T br>CAPTION_AGGR</T>
        <T br>CAPTION_NL</T>
        <T br>LOCATION</T> <T>DATE_ORIG</T> <T>DATE</T>
        <T br>ANNOT</T>
        <T br>INSCRIPTION_LA</T>
        <T br>DESCRIPTION</T>
        <T br>LOCATION_INV</T> <T>INSTIT_INV</T> <T>INSTIT_ID</T>
        <B if="KW_NL">
          Dutch keywords: &nbsp; <T>KW_NL</T>
          <br />
        </B>
        <T br>DIMENSION</T>
        <T>FACET</T>
      </div>
    );
  }
}

export default AggregatedObject;
