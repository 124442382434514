import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";


class TitlePage extends DefaultObject {
  render() {
    return (
      <div>
        <B if="PERSON_AUTHOR">
          <T>PERSON_AUTHOR</T> &middot; <T>TITLE_SHOW</T>
          <br />
        </B>
        <T br>IMPRESSUM</T>
        <B if="LOCATION_ORIG">
          Edition: <T>LOCATION_ORIG</T> <T>PERSON_BSPR</T> <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="LOCATION_INV">
          Copy: <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>ID_INV</T>
          <br />
        </B>
        <T>FACET</T>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
      </div>
    );
  }
}

export default TitlePage;
