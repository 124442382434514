import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";

class Ursicula extends DefaultObject {
  render() {
    return (
      <div>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <B if="PRINTER">
          Printer-bookseller: <T br>PRINTER</T>
        </B>
        <B if="ORNAMENTTYPE">
          Type: <T br>ORNAMENTTYPE</T>
        </B>
        <B if="HEIGHT">
          Height: <T>HEIGHT</T> mm <br />
        </B>
        <B if="PLACE">
          Place: <T br>PLACE</T>
        </B>
        <B if="FROM">
          Range: <T>FROM</T> until <T>UNTIL</T>
          <br />
        </B>
        <B if="DAMAGE">
          Damage: <T br>DAMAGE</T>
        </B>
        <B if="ICON">
          Keywords: &nbsp; <T br>ICON</T>
        </B>
      </div>
    );
  }
}

export default Ursicula;
