import React from "react";

import T from "../utils/T";
import { DefaultObject } from "./index";

class BibliographyContainer extends DefaultObject {
  render() {
    return (
      <div>
        <T>AUT</T>, <T br>TITLE</T>
        <T br>YEA</T>
        <T br>COM</T>
        <T br>MAG</T>
        {this.children()}
      </div>
    );
  }
}

export default BibliographyContainer;
