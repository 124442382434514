import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";


class PrintersDevice extends DefaultObject {
  render() {
    const obj = this.props.obj;
    const printer_names = obj.USAGE.map(usg =>
      usg.PRINTER_KEY.map(prk => (
        <div key={prk.ID[0]}>
          {prk.SHOW_NAME && prk.SHOW_NAME[0]} &middot;{" "}
          {prk.SHOW_ADDRESS && prk.SHOW_ADDRESS[0]}
          <em>{prk.OCCUPAT && prk.OCCUPAT[0].replace("@RANGE", ",")}</em>
        </div>
      ))
    );

    return (
      <div>
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <T br>CAPTION_EN</T>
        <B if="MOTTO_LA">
          <b>Inscription:</b> <T br>MOTTO_LA</T>
        </B>
        <B if="MOTTO_NL">
          <b>Inscription:</b> <T br>MOTTO_NL</T>
        </B>
        <B if="MOTTO_NL_NORM">
          <T br>MOTTO_NL_NORM</T>
        </B>

        <B if="MOTTO_EL">
          <b>Inscription:</b> <T br>MOTTO_EL</T>
        </B>
        <B if="MOTTO_ES">
          <b>Inscription:</b> <T br>MOTTO_ES</T>
        </B>
        <B if="MOTTO_PO">
          <b>Inscription:</b> <T br>MOTTO_PO</T>
        </B>
        <B if="MOTTO_FR">
          <b>Inscription:</b> <T br>MOTTO_FR</T>
        </B>
        <B if="MOTTO_IT">
          <b>Inscription:</b> <T br>MOTTO_IT</T>
        </B>
        <B if="INIT">
          <b>Initial(s):</b> <T>INIT</T>, <T>INIT_NORM</T>
          <br />
        </B>
        <B if="USAGE">
          <b>Used by:</b> {printer_names}
        </B>
        <B if="RANGE">
          <b>Used in:</b> <T>RANGE</T>
        </B>
        <B if="PRINTER">
          <b>Printer:</b> <T br>PRINTER</T>
        </B>
        <B if="PLACE">
          <T br>PLACE</T>
        </B>
        <B if="FROM">
          <T>FROM</T> - <T>UNTIL</T>
        </B>
        <B if="DESCRIPTION">
          <b>Description:</b> <T>DESCRIPTION</T>
        </B>
      </div>
    );
  }
}

export default PrintersDevice;
