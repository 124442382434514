import React, { Component } from "react";
import { Dropdown, Button, Grid, Input, Tab, Popup } from "semantic-ui-react";
import _ from "lodash";
import { parse } from "query-string";
import StackGrid from "react-stack-grid";

import PageFooter from "./liminal/PageFooter";
import PageHeader from "./liminal/PageHeader";
import FacetBox from "./facetsfilters/FacetBox";
import FilterBox from "./facetsfilters/FilterBox";
import Thumb from "./Thumb";
import ThumbSelection from "./ThumbSelection";

const sortOptions = [
  { text: "on Object ID", value: "SORTID" },
  { text: "by Type", value: "TIPE" },
  { text: "by Collection", value: "HIM" },
  { text: "by Date", value: "ADATE" },
  { text: "by Parent", value: "PARENT" },
  { text: "Randomly", value: "RANDOM" }
];

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searching: false,
      resultSliceLimit: 33,
      statusText:
        "Making choices is hard, but sometimes you have to make them. Try the Search button.",
      searchResults: this.props.search.getSectionResults(
        this.props.match.params.section
      ),
      searchTerm: this.props.search.searchTerm,
      selectedObjects: {}
    };
  }

  componentDidMount() {
    this.scrollfunc = _.throttle(this.onScroll, 16);
    window.addEventListener("scroll", this.scrollfunc, false);
    const parsed = parse(window.location.search);
    let shouldSearch = false;
    let newFilters = [];

    if (parsed.facet_tipe || parsed.facet_ic) {
      this.setState({ searchTerm: "" });
    }
    if (parsed.q) {
      this.props.search.filters = {};
      this.setState({ searchTerm: parsed.q });
      shouldSearch = true;
    }
    if (parsed.facet_tipe) {
      const facet_tipes =
        parsed.facet_tipe instanceof Array
          ? parsed.facet_tipe
          : [parsed.facet_tipe];
      facet_tipes.forEach(entry =>
        newFilters.push({
          filterId: null,
          filterType: entry.substring(0, 1),
          fieldName: "TIPE",
          fieldValue: entry.substring(1)
        })
      );
      shouldSearch = true;
    }
    if (parsed.facet_ic) {
      const facet_ics =
        parsed.facet_ic instanceof Array ? parsed.facet_ic : [parsed.facet_ic];
      facet_ics.forEach(entry =>
        newFilters.push({
          filterId: null,
          filterType: entry.substring(0, 1),
          fieldName: "ICPATH",
          fieldValue: entry.substring(1)
        })
      );
      shouldSearch = true;
    }
    if (newFilters.length > 0) {
      this.props.search.filters = {};
      this.props.search.addFilter(newFilters);
    }

    if (shouldSearch || this.state.searchResults.total === null) {
      setTimeout(() => this.doSearch(), 100);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollfunc, false);
  }
  onScroll = () => {
    const top =
      typeof window.scrollY === "undefined"
        ? window.pageYOffset
        : window.scrollY;
    if (
      window.innerHeight + top >= document.body.scrollHeight - 100 &&
      this.state.searchResults.total > 0
    ) {
      if (this.state.resultSliceLimit < this.state.searchResults.hits.length) {
        this.setState({ resultSliceLimit: this.state.resultSliceLimit + 33 });
      }
    }
  };

  updateSearchResults = sectionResults => {
    this.setState({
      searchResults: sectionResults,
      searching: false,
      statusText: sectionResults.error
        ? `An error occurred: ${sectionResults.error}`
        : this.state.searchResults.total < 1 &&
          "Alas, nothing found. Try changing your query"
    });
  };

  doSearch = () => {
    this.setState({
      searching: true,
      statusText: "Searching..."
    });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch(
      this.props.match.params.section,
      this.updateSearchResults
    );
  };

  onSortChange = (event, data) => {
    this.props.search.setSort(this.props.match.params.section, data.value);
    this.doSearch();
  };

  onChangeICLanguage = (event, data) => {
    this.props.search.setICLanguage(data.value);
  };

  triggerUpdate = () => {
    this.setState({ foo: Math.random() });
  };

  onSearchFieldKeyPress = event => {
    if (event.key === "Enter") {
      this.doSearch();
    }
  };

  facetsAndFilters = section => {
    let buf = [];
    buf.push(
      <FilterBox
        key={this.props.search.nonce}
        triggerUpdate={this.triggerUpdate}
        search={this.props.search}
        section={section}
      />
    );
    buf.push(
      <FacetBox
        key={`facetbox${this.props.search.nonce}`}
        triggerUpdate={this.triggerUpdate}
        search={this.props.search}
        section={section}
        onChangeICLanguage={this.onChangeICLanguage}
      />
    );
    return buf;
  };

  savedItemsCheckboxHandler = obj => {
    this.props.search.addSavedItem(obj);
    this.triggerUpdate();
  };

  render() {
    const { match } = this.props;
    const { searchTerm } = this.state;
    const searchButton = (
      <Button
        color="teal"
        labelPosition="left"
        icon="search"
        content="Search"
        onClick={event => {
          this.doSearch();
        }}
      />
    );

    const savedTab = (
      <Tab.Pane>
        <ThumbSelection
          match={this.props.match}
          history={this.props.history}
          search={this.props.search}
          selectedObjects={this.state.selectedObjects}
          savedObjects={this.props.search.savedObjects}
          triggerUpdate={() => this.setState({ foo: Math.random() })}
          onRemoveSelected={selectedObjects => {
            Object.keys(selectedObjects).forEach(key => {
              this.props.search.removeSavedItem(selectedObjects[key].ID[0]);
            });
            for (var prop in selectedObjects) {
              delete selectedObjects[prop];
            }
            this.setState({ foo: Math.random() });
          }}
        />
      </Tab.Pane>
    );
    const searchResultTab = (
      <Tab.Pane>
        {this.state.searchResults.nonce !== this.props.search.nonce && (
          <div
            style={{
              textAlign: "center",
              marginBottom: "1em",
              backgroundColor: "#fff",
              padding: "0.5em 0"
            }}
          >
            <b>Note:</b> These search results are for a different set of
            filters. Press the search button again if you want to search with
            the currently selected filters.
          </div>
        )}
        <StackGrid
          columnWidth={150}
          monitorImagesLoaded
          gridRef={grid => (this.grid = grid)}
        >
          {this.state.searchResults.hits
            .slice(0, this.state.resultSliceLimit)
            .map(obj =>
              obj ? (
                <Thumb
                  showText
                  showMeta
                  key={obj.ID[0]}
                  obj={obj}
                  search={this.props.search}
                  triggerUpdate={this.triggerUpdate}
                  section={match.params.section}
                  history={this.props.history}
                  checkboxHandler={this.savedItemsCheckboxHandler}
                />
              ) : (
                "Error: empty obj in searchresults"
              )
            )}
        </StackGrid>
      </Tab.Pane>
    );

    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={match.params.section}
          search={this.props.search}
          history={this.props.history}
        />
        <Grid>
          <Grid.Column width="16">
            {this.state.searching ? (
              <Input
                loading
                fluid
                icon="search"
                placeholder="Searching..."
                value={searchTerm}
                onKeyPress={this.onSearchFieldKeyPress}
                onChange={event =>
                  this.setState({ searchTerm: event.target.value })
                }
              />
            ) : (
              <Input
                fluid
                value={searchTerm}
                placeholder="Type here to start searching"
                action={searchButton}
                actionPosition="left"
                onKeyPress={this.onSearchFieldKeyPress}
                onChange={event =>
                  this.setState({ searchTerm: event.target.value })
                }
              />
            )}
          </Grid.Column>
        </Grid>
        <Grid stackable>
          <Grid.Column width={5}>
            <div>
              {this.props.search.hasSearchFilters() && (
                <div>
                  <Button
                    style={{ float: "right" }}
                    compact
                    content="Clear Search"
                    size="mini"
                    color="olive"
                    onClick={event => {
                      this.props.search.searchTerm = "";
                      this.props.search.filters = {};
                      this.setState({ searchTerm: "" });
                      this.doSearch();
                    }}
                  />
                  <Popup
                    trigger={
                      <Button
                        style={{ float: "right" }}
                        compact
                        content="Citable URL"
                        size="mini"
                        color="brown"
                      />
                    }
                    size="mini"
                    on="click"
                  >
                    {this.props.search.citable_url()}
                  </Popup>
                </div>
              )}
              {this.state.searchResults.total > 0 ? (
                <div>
                  <em>{this.state.searchResults.total}</em> results found,
                  sorted{" "}
                  <Dropdown
                    inline
                    defaultValue={this.props.search.lastSortOrder || "RANDOM"}
                    options={sortOptions}
                    onChange={this.onSortChange}
                  />
                </div>
              ) : (
                <div>&middot; &#8756; &middot;</div>
              )}
            </div>
            {this.facetsAndFilters(match.params.section)}
          </Grid.Column>
          <Grid.Column width={11}>
            <div style={{ backgroundColor: "#fcfcfc", padding: "0.7em" }}>
              {this.state.searchResults.total ? (
                <Tab
                  panes={[
                    {
                      menuItem: "Search Results",
                      render: () => searchResultTab
                    },
                    {
                      menuItem: `${
                        Object.keys(this.props.search.savedObjects).length
                      } Saved Items`,
                      render: () => savedTab
                    }
                  ]}
                />
              ) : (
                <div>{this.state.statusText}</div>
              )}
              {this.state.searchResults.total > 0 &&
              this.state.resultSliceLimit >
                this.state.searchResults.hits.length &&
              this.state.resultSliceLimit < this.state.searchResults.total ? (
                <div
                  style={{
                    fontStyle: "italic",
                    textAlign: "center",
                    margin: "1em"
                  }}
                >
                  There are more results, now showing the first batch of
                  the&nbsp;
                  {this.state.searchResults.total}. Add some filters to narrow
                  your search.
                </div>
              ) : null}
            </div>
          </Grid.Column>
        </Grid>

        <PageFooter />
      </div>
    );
  }
}

export default Section;
