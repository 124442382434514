import React from "react";
import { Button } from "semantic-ui-react";

import cerllogo from "../../assets/images/cerl_logo.jpg";

const CERLHeader = ({ handleSearchClick, history }) => (
  <div
    style={{
      backgroundColor: "#004165",
      marginBottom: "1em",
      height: 109,
      display: "flex",
      justifyContent: "space-around"
    }}
  >
    <img alt="CERL Logo" src={cerllogo} />
    <div />
    {handleSearchClick && (
      <Button
        color="brown"
        onClick={handleSearchClick}
        style={{ marginTop: "2em", height: "3em" }}
      >
        Search
      </Button>
    )}
    {history && (
      <Button
        color="brown"
        onClick={event =>
          history.push(`${process.env.REACT_APP_URL_PATH}/cerl/pda`)
        }
        style={{ marginTop: "2em", height: "3em" }}
      >
        Add New Item
      </Button>
    )}
    <div style={{ marginTop: "3em" }}>
      <a
        href="/"
        style={{ color: "white", fontSize: "18pt", textDecoration: "none" }}
      >
        {`${process.env.REACT_APP_PROJECT_NAME} Homepage`}
      </a>
    </div>
  </div>
);

export default CERLHeader;
