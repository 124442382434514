import React, { Component } from "react";
import {
  CanvasProvider,
  Manifest,
  SingleTileSource,
  OpenSeadragonViewer
} from "@canvas-panel/core";

import CanvasNavigation from "../CanvasNavigation";

class ZoomedImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: null
    };
  }

  componentDidCatch(error, info) {
    console.log(error, info);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) return null;
    let manifestURL = "";
    if (this.props.obj.URL_IIIF_MANIFEST) {
      manifestURL = this.props.obj.URL_IIIF_MANIFEST[0];
    } else {
      const id = this.props.obj.ID[0];
      manifestURL = `/api/iiif/presentation/${id}/info.json`;
    }

    const osdOptions = {
      autoHideControls: false,
      showNavigationControl: true,
      showNavigator: false,
      showFullPageControl: true,
      showRotationControl: true,
      maxZoomLevel: 7,
      prefixUrl: "/r/images/"
    };
    return (
      <Manifest url={manifestURL + "?t=" + ((Date.now() / 1000) | 0)}>
        <CanvasProvider>
          <SingleTileSource>
            <OpenSeadragonViewer maxHeight={500} osdOptions={osdOptions} />
          </SingleTileSource>
          <CanvasNavigation actions={this.props.actions || []} />
        </CanvasProvider>
      </Manifest>
    );
  }
}

export default ZoomedImage;
