import React from "react";
import { markdown } from "markdown";

import { DefaultObject } from "./index";

class Essay extends DefaultObject {
  constructor(props) {
    super(props);
    const eList = (this.props.obj.ARKYVES_LIST &&
      this.props.obj.ARKYVES_LIST[0]) || { entries: [] };
    props.search.getObjects(
      eList.entries.map(tle => tle.uid),
      getObjectsResults => {
        this.setState({
          children: getObjectsResults.hits
        });
      }
    );
  }

  render() {
    const eList =
      (this.props.obj.ARKYVES_LIST && this.props.obj.ARKYVES_LIST[0]) || {};
    return (
      <div>
        <h1>{eList.name}</h1>
        <div
          style={{ marginBottom: "2em" }}
          dangerouslySetInnerHTML={{
            __html: markdown.toHTML(eList.description || "")
          }}
        />
        {this.children()}
      </div>
    );
  }
}


export default Essay;
