import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";

class ManuscriptBook extends DefaultObject {
  render() {
    return (
      <div>
        <T>LOCATION_INV</T>, <T>INSTIT_INV</T>, MS <T br>ID_INV</T>
        <i>
          <T br>TITLE_ABBR</T>
        </i>
        <T br>LOCDAT_DISPLAY|LOCATION_ORIG|DATE_ORIG</T>
        <br />
        <B if="BIBLIO_FORM">
          <b>Codicology:</b> <T br>BIBLIO_FORM</T>
        </B>
        <B if="ILLUMINIATION">
          <b>Illumination:</b> <T br>ILLUMINATION</T>
        </B>
        <B if="PROVENANCE">
          <b>Provenance:</b> <T br>PROVENANCE</T>
        </B>
        <B if="ANNOT">
          <b>Note:</b> <T br>ANNOT</T>
        </B>
        <B if="BIBLIO">
          <b>Bibliography:</b> <T>BIBLIO</T>
        </B>
        {this.children()}
      </div>
    );
  }
}

export default ManuscriptBook;
