import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";

class ProvenanceSource extends DefaultObject {
  render() {
    return (
      <div>
        <B if="LOCATION_ORIG">
          Place and date: <T>LOCATION_ORIG</T> &middot; <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="PERSON_OWNER">
          Owner: <T br>PERSON_OWNER</T>
        </B>
        <B if="PERSON_BENEFICIARY">
          Beneficiary: <T br>PERSON_BENEFICIARY</T>
        </B>
        <B if="PERSON_NOTARY">
          Notary: <T br>PERSON_NOTARY</T>
        </B>
        <B if="TYPE_INV">
          Source type: <T br>TYPE_INV</T>
        </B>
        <B if="ID_INV">
          Source location: <T br>ID_INV</T>
        </B>
        <B if="INTRO_SRC">
          Incipit: <T br>INTRO_SRC</T>
        </B>
        {this.children()}
      </div>
    );
  }
}

export default ProvenanceSource;
