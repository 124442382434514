import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";

class ProvenanceItem extends DefaultObject {
  render() {
    return (
      <div>
        <B if="TYPE_INV">
          Source: <T>TYPE_INV</T> &middot; <T>PERSON_OWNER</T>
          <br />
        </B>
        <B if="PERSON_NOTARY">
          Notary: <T br>PERSON_NOTARY</T>
        </B>
        <B if="PERSON_BENEFICIARY">
          Beneficiary: <T br>PERSON_BENEFICIARY</T>
        </B>
        <B if="PERSON_ARTIST">
          Artist: <T br>PERSON_ARTIST</T>
        </B>
        <B if="DOCUMENTCONTEXT">
          Object description: <T>DOCUMENTCONTEXT</T>: "<T>DESCRIPTION_SRC</T>"
          <T>RESIDENCE</T>
          <T>ROOM</T> &middot; <T>TYPE_INS</T>
        </B>
        ... <T>DOCUMENTCONTEXT</T>
      </div>
    );
  }
}


export default ProvenanceItem;
