const ICONCLASS = {
  "0": "Abstract, Non-representational Art",
  "1": "Religion and Magic",
  "2": "Nature",
  "3": "Human Being, Man in General",
  "4": "Society, Civilization, Culture",
  "5": "Abstract Ideas and Concepts",
  "6": "History",
  "7": "Bible",
  "8": "Literature",
  "9": "Classical Mythology and Ancient History"
};

const HIM = {
  AAG: "Alciatus Collection Glasgow",
  BVH: "Bibliothèques Virtuelles Humanistes",
  BYVANCK: "Byvanck Genootschap",
  DMVI: "Mid-Victorian Illustration",
  EPU: "Utrecht Love Emblems",
  GEDP: "French Emblems Glasgow",
  GEP: "German Emblem Books",
  GUL: "Stirling Maxwell",
  HAB: "Herzog August Bibliothek",
  KBMSS: "KB Nederland Medieval Manuscripts",
  KB: "Koninklijk Bibliotheek Nederland",
  MILAN: "Marengo Satirical Journals",
  MMWMSS: "Museum Meermanno",
  MNE: "Arkyves Core Collections",
  MNE_DPD: "Arkyves Printer's Devices",
  MNE_EMB: "Arkyves Emblem Books",
  RIJKSMUSEUM: "Rijksmuseum",
  RKD: "Netherlands Institute for Art History",
  UIUC: "Emblem Books Illinois",
  URSICULA: "Typography",
  VKK: "Virtual Print Cabinet",
  FOLGER: "Folger Shakespeare Library",
  MSS: "Medieval Manuscripts",
  EMBLEMS: "Emblems",
  UVA: "Universiteit Amsterdam",
  LOC: "Library of Congress",
  BSB: "Bayerische Staatsbibliothek",
  FABLES: "Fables",
  ILL_BOOK: "Illustrated Books",
  DEVICES: "Printers Devices",
  ALC: "Alchemy",
  BIBLIASACRA: "Biblia Sacra",
  GPI: "Getty Provenance Index",
  CERL: "Consortium of European Research Libraries",
  TLC: "The Leiden Collection"
};

const TYPES = {
  bibliography: "Bibliography",
  bibliographycontainer: "Bibliography Container",
  book: "Book",
  bookillustration: "Book Illustration",
  emblempictura: "Emblem Pictura",
  emblembook: "Emblem Book",
  facsimile: "Facsimile",
  image: "Image",
  imagesfromperiodicals: "Periodical Image",
  manuscriptbook: "Manuscript",
  person: "Person",
  manuscriptillumination: "Manuscript Illumination",
  printersdevice: "Printers Device",
  printersdeviceusage: "Printers Device",
  proverb: "Proverb",
  typeface: "Typeface",
  ornament: "Ornament",
  initial: "Initial",
  titleprint: "Title Illustration",
  journal: "Journal",
  provenanceitem: "Provenance",
  provenancedocument: "Provenance",
  provenancesource: "Provenance Source",
  provenanceimage: "Provenance Image",
  essay: "Essay"
};

const SECTIONS = [
  { key: "all", color: "#444", text: "All content" },
  {
    key: "medievalmanuscript",
    color: "#135680",
    text: "Medieval Manuscripts",
    image: "medievalmanuscript"
  },
  {
    key: "emblembook",
    color: "#82272a",
    text: "Emblem Books",
    image: "emblembook"
  },
  { key: "painting", color: "#b89f3a", text: "Paintings", image: "painting" },
  {
    key: "periodical",
    color: "#22683c",
    text: "Periodicals",
    image: "satire"
  },
  {
    key: "printersdevice",
    color: "#5e3789",
    text: "Printer's Devices",
    image: "printersdevice"
  },
  {
    key: "photography",
    color: "#f7a63d",
    text: "Photography",
    image: "photography"
  },
  { key: "prints", color: "#936d50", text: "Prints", image: "print" },
  { key: "proverb", color: "#135680", text: "Proverbs", image: "proverb" },
  { key: "fable", color: "#82272a", text: "Fables", image: "fable" },
  {
    key: "metamorphoses",
    color: "#22683c",
    text: "Metamorphoses",
    image: "metamorphoses"
  },
  { key: "alchemy", color: "#b89f3a", text: "Alchemy", image: "alchemy" },
  { key: "drawing", color: "#115280", text: "Drawings", image: "drawing" },
  {
    key: "naturalhistory",
    color: "#936d50",
    text: "Natural History",
    image: "naturalhistory"
  }
];

const SEARCH = {
  DEFAULT_HPP: "150",
  AUTH_TOKEN_URL: process.env.REACT_APP_AUTH_TOKEN_URL,
  PATH_BASE: "",
  PATH_SEARCH: "/api/search",
  PATH_OBJECTS: "/api/objects",
  PATH_LISTS: "/api/lists",
  PATH_MODIFY: "/api/modify",
  PATH_ENTRY: "/api/entry",
  PATH_USER: "/api/user",
  PARAM_SEARCH: "q=",
  PARAM_PAGE: "page=",
  PARAM_HPP: "size=",
  PARAM_SECTION: "section=",
  PARAM_SORT: "sort="
};

export { ICONCLASS, HIM, TYPES, SECTIONS, SEARCH };
