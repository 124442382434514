import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid } from "semantic-ui-react";

import { SECTIONS } from "../../constants";
import PageMenu from "./PageMenu";

const SECTIONMAP = {};
SECTIONS.map(sobj => (SECTIONMAP[sobj.key] = sobj));

const defaultHeaderExtras = [
  <Button style={{ marginTop: "2.75em" }}>
    <Link to={`${process.env.REACT_APP_URL_PATH}/lists/`}>Lists</Link>
  </Button>,
  <Button style={{ marginTop: "2.75em" }}>
    <Link to={`${process.env.REACT_APP_URL_PATH}/cerlpda/`}>CERL PDA</Link>
  </Button>
];

const DefaultPageHeader = ({
  section = null,
  extras = defaultHeaderExtras,
  search,
  history
}) => (
  <Grid stackable>
    <Grid.Column width={3}>
      <div id="logobox">
        <Link to={`${process.env.REACT_APP_URL_PATH}/`}>
          <img
            alt={`${process.env.REACT_APP_PROJECT_NAME} Logo`}
            src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_PROJECT_LOGO}`}
          />
        </Link>
      </div>
    </Grid.Column>

    <Grid.Column
      width={9}
      textAlign="left"
      floated="left"
      style={{ marginTop: "1em" }}
    >
      <PageMenu search={search} history={history} />
    </Grid.Column>
    <Grid.Column width={4}>
      {section !== null && (
        <div
          style={{
            height: "50px",
            backgroundColor:
              (SECTIONMAP[section] && SECTIONMAP[section].color) || "teal",
            color: "white",
            padding: "1em",
            fontSize: "120%",
            textAlign: "center",
            borderRadius: "0 0 10px 10px"
          }}
        >
          <Link to={`${process.env.REACT_APP_URL_PATH}/section/${section}`} style={{ color: "white" }}>
            {(SECTIONMAP[section] && SECTIONMAP[section].text) ||
              section.slice(4, 99)}
          </Link>
        </div>
      )}
    </Grid.Column>
  </Grid>
);


export default DefaultPageHeader;
