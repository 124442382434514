import React, { Component } from "react";
import {
  Button,
  Form,
  Icon,
  Image,
  Segment,
  Modal,
  Popup,
  Grid,
} from "semantic-ui-react";
import PropTypes from "prop-types";

import { Iconclass } from "./Iconclass";
import PageFooter from "./liminal/PageFooter";
import PageHeader from "./liminal/PageHeader";
import NotFound from "./liminal/NotFound";
import SearchInputGrid from "./liminal/SearchInputGrid";
import { ObjChooser } from "./ObjChooser";
import FilterBox from "./facetsfilters/FilterBox";
import Comments from "./liminal/Comments";
import iiiflogo from "../assets/images/iiif_logo.png";
import { addEntry, deleteEntry, deleteObject } from "../search/searchHelpers";
import { HIM, TYPES } from "../constants";

function checkObjectOwner(obj, credentials) {
  if (
    credentials &&
    credentials.userpk &&
    obj._OWNER_PK &&
    obj._OWNER_PK.indexOf(credentials.userpk) > -1
  ) {
    return true;
  }
  return false;
}

const rawFields = (props) => {
  const obj = props.obj;

  let elms = [];
  for (let key in obj) {
    if (key.indexOf("_") === 0) continue;
    if (key === "USAGE") continue;
    if (key === "LINK_FACSIMILE") continue;
    elms.push(
      <tr key={key}>
        <td>{key}</td>
        <td>
          {obj[key].map((value) => (
            <span key={`${key}${value}`}>{value} &middot;</span>
          ))}
        </td>
      </tr>
    );
  }
  return (
    <table>
      <tbody>{elms}</tbody>
    </table>
  );
};

class TypesHIMPrevNext extends Component {
  state = { addingIC: "" };
  render() {
    const obj = this.props.obj;
    if (!obj.TYPE) return null;
    const objtype = (
      <span>
        {TYPES[obj.TYPE[0].toLowerCase().replace(/ /g, "")] || obj.TYPE[0]}
      </span>
    );

    // Also fetch the user-annotated IC notations from the _annotations
    let icAnnotation = null;
    if (obj._annotations && obj._annotations.IC) {
      icAnnotation = obj._annotations.IC.map((annot) => (
        <div>
          <hr />
          {this.props.search.credentials &&
            annot.userpk === this.props.search.credentials.userpk && (
              <Popup
                trigger={
                  <Icon
                    style={{ cursor: "pointer" }}
                    name="edit"
                    compact
                    floated="right"
                    onClick={(event) =>
                      this.setState({
                        ICEditPk: annot.pk,
                        addingIC: annot.value,
                        isAddingIC: true,
                      })
                    }
                  />
                }
              >
                Click here to edit your annotations
              </Popup>
            )}
          by: <Icon name="user" /> {annot.username}{" "}
          {new Date(Date.parse(annot.timestamp)).toLocaleString("en-GB")}
          {annot.value.split("\n").map((annotsplitted) => (
            <Iconclass
              triggerUpdate={this.props.triggerUpdate}
              key={`annotation_${annotsplitted}`}
              notation={annotsplitted}
              pathrefine
              search={this.props.search}
              shownotation
              showcheckbox
            />
          ))}
        </div>
      ));
    }

    return (
      <Segment.Group
        raised
        style={{ paddingLeft: "0.5em", paddingRight: "0.5em" }}
      >
        {(obj._searchPrevious || obj._searchNext) && (
          <Segment vertical>
            {obj._searchPrevious && (
              <Button
                animated
                onClick={() =>
                  this.props.history.push(
                    `${process.env.REACT_APP_URL_PATH}/view/${
                      obj._searchPrevious[0]
                    }/${this.props.section}`
                  )
                }
              >
                <Button.Content hidden>Prev</Button.Content>
                <Button.Content visible>
                  <Icon name="left arrow" />
                </Button.Content>
              </Button>
            )}
            <em>In Search results</em>
            {obj._searchNext && (
              <Button
                animated
                floated="right"
                onClick={() =>
                  this.props.history.push(
                    `${process.env.REACT_APP_URL_PATH}/view/${
                      obj._searchNext[0]
                    }/${this.props.section}`
                  )
                }
              >
                <Button.Content hidden>Next</Button.Content>
                <Button.Content visible>
                  <Icon name="right arrow" />
                </Button.Content>
              </Button>
            )}
          </Segment>
        )}
        {obj.HIM && !this.props.section.startsWith("him_") && (
          <Segment vertical>
            <h4>Collections</h4>
            {obj.HIM.map((value) => (
              <div key={value}>{HIM[value]}</div>
            ))}
          </Segment>
        )}

        <Segment vertical>
          <h4>ICONCLASS</h4>
          {obj.IC ? (
            obj.IC.map((ic) => (
              <Iconclass
                key={ic}
                notation={ic}
                pathrefine
                search={this.props.search}
                shownotation
                showcheckbox
              />
            ))
          ) : (
            <div>
              No notations have been assigned to this item yet. If you want, you
              can assign your own Iconclass notations.
            </div>
          )}
          {icAnnotation}
          {this.state.isAddingIC ? (
            <div style={{ marginTop: "1em" }}>
              {this.state.addingIC && (
                <div style={{ backgroundColor: "#eee", padding: "0.5em" }}>
                  <h5>Preview</h5>
                  {this.state.addingIC.split("\n").map((anic) => (
                    <Iconclass
                      key={`editing_${anic}`}
                      notation={anic}
                      search={this.props.search}
                      shownotation
                    />
                  ))}
                </div>
              )}
              <Form>
                <Form.TextArea
                  placeholder="Type an ICONCLASS code here, one per line"
                  fluid
                  value={this.state.addingIC}
                  onChange={(event, data) =>
                    this.setState({ addingIC: event.target.value })
                  }
                />
                <Button.Group>
                  <Button
                    color="green"
                    compact
                    size="tiny"
                    onClick={(event) => {
                      if (!this.state.addingIC) return;
                      addEntry(
                        this.state.ICEditPk || null,
                        obj.ID[0],
                        "IC",
                        this.state.addingIC,
                        this.props.search.credentials.token,
                        () => {
                          this.setState({
                            isAddingIC: false,
                            ICEditPk: null,
                            addingIC: "",
                          });
                          this.props.update();
                        }
                      );
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    compact
                    size="tiny"
                    onClick={(event) => this.setState({ isAddingIC: false })}
                  >
                    Cancel
                  </Button>
                  {this.state.ICEditPk && (
                    <Button
                      icon="delete"
                      compact
                      size="tiny"
                      color="orange"
                      onClick={(event) =>
                        deleteEntry(
                          this.state.ICEditPk,
                          this.props.search.credentials.token,
                          () => {
                            this.setState({
                              isAddingIC: false,
                              ICEditPk: null,
                              addingIC: "",
                            });
                            this.props.update();
                          }
                        )
                      }
                    >
                      Delete
                    </Button>
                  )}
                </Button.Group>
              </Form>
            </div>
          ) : (
            this.props.search.credentials && (
              <Button
                style={{ marginTop: "1em" }}
                compact
                onClick={(event) => this.setState({ isAddingIC: true })}
              >
                Add ICONCLASS
              </Button>
            )
          )}
        </Segment>

        <Segment vertical>
          {obj.PREV && (
            <Button
              animated
              onClick={() =>
                this.props.history.push(
                  `${process.env.REACT_APP_URL_PATH}/view/${obj.PREV[0]}/${
                    this.props.section
                  }`
                )
              }
            >
              <Button.Content hidden>Prev</Button.Content>
              <Button.Content visible>
                <Icon name="left arrow" />
              </Button.Content>
            </Button>
          )}

          {obj.TYPE && (
            <Modal trigger={objtype}>
              <Modal.Header>Raw Data for {this.props.obj.ID[0]}</Modal.Header>
              <Modal.Content image>
                <Modal.Description>{rawFields(this.props)}</Modal.Description>
              </Modal.Content>
            </Modal>
          )}

          {obj.NEXT && (
            <Button
              animated
              floated="right"
              onClick={() =>
                this.props.history.push(
                  `${process.env.REACT_APP_URL_PATH}/view/${obj.NEXT[0]}/${
                    this.props.section
                  }`
                )
              }
            >
              <Button.Content hidden>Next</Button.Content>
              <Button.Content visible>
                <Icon name="right arrow" />
              </Button.Content>
            </Button>
          )}
        </Segment>
      </Segment.Group>
    );
  }
}

class Default extends Component {
  getIC(obj) {
    if (typeof obj.IC === "undefined" || obj.IC.length < 1) return null;
    return (
      <div
        style={{
          float: "right",
          width: "30%",
          backgroundColor: "#F8F8F8",
          padding: "1em",
          borderBottom: "2px solid #F4F4F4",
          borderRight: "2px solid #F4F4F4",
        }}
      >
        <h4>Iconclass</h4>
        {obj.IC.map((ic) => (
          <Iconclass
            triggerUpdate={this.props.triggerUpdate}
            key={ic}
            notation={ic}
            pathrefine
            search={this.props.search}
            shownotation
            showcheckbox
          />
        ))}
      </div>
    );
  }
  getChildContext() {
    return { obj: this.props.obj };
  }

  render() {
    const handleSetOriginalCLick = (url) =>
      window.open(url, `${process.env.REACT_APP_PROJECT_NAME}_Original`);
    return (
      <div>
        {this.props.obj.ALLIMAGES[0] && (
          <a
            style={{ margin: "0 1em 0 0" }}
            target="mirador"
            href={
              this.props.obj.URL_IIIF_MANIFEST
                ? `/mirador.html?manifest=${
                    this.props.obj.URL_IIIF_MANIFEST[0]
                  }`
                : `/mirador.html?manifest=${
                    process.env.REACT_APP_IIIF_SERVER
                  }/${this.props.obj.ALLIMAGES[0]}/info.json`
            }
          >
            <img src={iiiflogo} alt="IIIF" />
          </a>
        )}
        {checkObjectOwner(this.props.obj, this.props.search.credentials) && (
          <div style={{ display: "inline-block" }}>
            <Button
              compact
              onClick={(event) => {
                const yesno = window.confirm(
                  "Do you REALLY want to delete this object?"
                );
                if (yesno) {
                  deleteObject(
                    this.props.obj,
                    this.props.search.credentials.token,
                    (result) => setTimeout(() => this.props.doSearch(), 500)
                  );
                }
              }}
            >
              Delete
            </Button>
            <Button
              compact
              onClick={(event) => {
                this.props.history.push(
                  `${process.env.REACT_APP_URL_PATH}/cerl/pda/edit/${
                    this.props.obj.ID[0]
                  }`
                );
              }}
            >
              Edit
            </Button>
          </div>
        )}

        {this.props.obj.ALLIMAGES[0] && (
          <Popup trigger={<Button compact content="Image URL" />} on="click">
            {this.props.obj.ALLIMAGES.map((animage) => (
              <p key={animage}>{`${window.location.protocol}//${
                window.location.hostname
              }/f/${animage}`}</p>
            ))}
          </Popup>
        )}
        {!this.props.search.savedObjects[this.props.obj.ID[0]] && (
          <Button
            color="teal"
            compact
            onClick={(event) => {
              this.props.search.addSavedItem(this.props.obj);
              this.setState({ foo: Math.random() });
            }}
          >
            Save
          </Button>
        )}
        {this.props.obj.URL_WEBPAGE && (
          <Button
            compact
            color="olive"
            onClick={(event) =>
              handleSetOriginalCLick(this.props.obj.URL_WEBPAGE[0])
            }
          >
            Original Source
          </Button>
        )}
        {this.props.obj.LINK_FACSIMILE && (
          <Button
            compact
            onClick={(event) =>
              handleSetOriginalCLick(
                `${process.env.REACT_APP_URL_PATH}/view/${
                  this.props.obj.LINK_FACSIMILE[0].ID[0]
                }/${this.props.section}`
              )
            }
          >
            Facsimile
          </Button>
        )}
        {this.props.obj.LINK &&
          this.props.obj.LINK.map((link) => (
            <Popup
              trigger={
                <Button
                  compact
                  content="Link"
                  onClick={(event) =>
                    handleSetOriginalCLick(
                      `${process.env.REACT_APP_URL_PATH}/view/${link}/${
                        this.props.section
                      }`
                    )
                  }
                />
              }
              content={link}
            />
          ))}
        {this.props.obj.PARENT && (
          <Button
            compact
            onClick={() =>
              this.props.history.push(
                `${process.env.REACT_APP_URL_PATH}/view/${
                  this.props.obj.PARENT[0]
                }/${this.props.section}`
              )
            }
          >
            Parent
          </Button>
        )}

        {this.props.section.startsWith("him_") ? (
          <Popup
            trigger={
              <Button
                color="violet"
                compact
                content="Persistent URL"
                floated="right"
              />
            }
            on="click"
          >
            <p>{`${window.location.protocol}//${window.location.hostname}${
              process.env.REACT_APP_URL_PATH
            }/view/${this.props.obj.ID[0]}/${this.props.section}`}</p>
          </Popup>
        ) : (
          <Popup
            trigger={
              <Button
                color="violet"
                compact
                content="Persistent URL"
                floated="right"
              />
            }
            on="click"
          >
            <p>{`https://www.arkyves.org/view/${this.props.obj.ID[0]}`}</p>
          </Popup>
        )}

        {ObjChooser(
          this.props.obj,
          this.props.section,
          this.props.search,
          this.props.history
        )}
      </div>
    );
  }
}
Default.childContextTypes = {
  obj: PropTypes.object,
};

class ViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obj: null,
      error: null,
      searchResults: { hits: [] },
      searchTerm: this.props.search.searchTerm,
    };
  }

  getObj = (uid) => {
    /* If this object is also in the search results of the current section, use _that_ object.
    It saves us a network trip back and forth, and it will have the _searchPrevious and _searchNext properties set on the object for navigation
    */
    if (this.state.obj && uid === this.state.obj.ID[0]) return;
    if (
      this.props.search.cache[this.props.section] &&
      !this.state.skipSearchCache
    ) {
      const theHits = this.props.search.cache[this.props.section].hits;
      for (let i = 0; i < theHits.length; i++) {
        const obj = theHits[i];
        const thisObjID = obj.ID[0];
        if (thisObjID === uid) {
          this.setState({
            similar_images: undefined,
            obj: obj,
            searchResults: { hits: theHits },
          });
          return;
        }
      }
    }
    this.props.search.getObjects(
      [uid],

      (getObjectsResults) => {
        if (getObjectsResults.notfound.indexOf(uid) > -1) {
          this.setState({ objnotfound: true });
        } else {
          this.setState({
            similar_images: undefined,
            obj: getObjectsResults.hits[0],
            searchResults: { hits: [getObjectsResults.hits[0]] },
          });
        }
      }
    );

    return null;
  };

  functionAfterSearch = () => {
    this.props.history.push(
      `${process.env.REACT_APP_URL_PATH}/section/${this.props.section}`
    );
  };

  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch(
      this.props.match.params.section,
      this.functionAfterSearch
    );
  };

  render() {
    if (this.state.objnotfound) {
      return (
        <NotFound search={this.props.search} history={this.props.history} />
      );
    }
    this.getObj(this.props.match.params.uid);
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={this.props.section}
          search={this.props.search}
          history={this.props.history}
        />
        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={(event) =>
            this.setState({ searchTerm: event.target.value })
          }
          doSearch={this.doSearch}
        />

        <Grid stackable>
          {this.props.section !== "him_CERLPDA" &&
            this.props.section !== "him_CERLCANYOUHELP" && (
              <Grid.Column width={5}>
                <FilterBox
                  key={this.props.search.nonce}
                  triggerUpdate={() => this.setState({ foo: Math.random() })}
                  search={this.props.search}
                  section={this.props.section}
                />
                {this.state.obj ? (
                  <div>
                    <TypesHIMPrevNext
                      obj={this.state.obj}
                      getObj={this.getObj}
                      search={this.props.search}
                      section={this.props.section}
                      history={this.props.history}
                      update={() =>
                        this.setState({
                          skipSearchCache: true,
                          obj: null,
                        })
                      }
                    />
                    <Comments
                      obj={this.state.obj}
                      search={this.props.search}
                      resetAfterSave={() =>
                        this.setState({
                          skipSearchCache: true,
                          obj: null,
                        })
                      }
                    />
                    <div>
                      {this.state.___obj && (
                        <Button
                          compact
                          size="tiny"
                          onClick={(event) => {
                            fetch(
                              `/similar?filename=${this.state.obj.URL_IMAGE[0]}`
                            )
                              .then((response) => response.json())
                              .then((result) => {
                                this.setState({ similar_images: result });
                              })
                              .catch((e) => {
                                console.log(e);
                              });
                          }}
                        >
                          Related to {this.state.obj.URL_IMAGE[0]}
                        </Button>
                      )}
                      {this.state.similar_images &&
                        this.state.similar_images.map((value) => (
                          <div>
                            <Image
                              style={{ cursor: "pointer" }}
                              title={value}
                              src={`${
                                process.env.REACT_APP_IIIF_SERVER
                              }/${value}/full/150,/0/default.jpg`}
                            />{" "}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  `Loading ${this.props.match.params.uid}`
                )}
              </Grid.Column>
            )}
          <Grid.Column width={11}>
            <div style={{ backgroundColor: "#f8f8f8", padding: "0.7em" }}>
              {this.state.obj ? (
                <Default
                  triggerUpdate={() => this.setState({ foo: Math.random() })}
                  obj={this.state.obj}
                  section={this.props.section}
                  history={this.props.history}
                  search={this.props.search}
                  doSearch={this.doSearch}
                />
              ) : (
                `Loading ${this.props.match.params.uid}`
              )}
            </div>
          </Grid.Column>
          {(this.props.section === "him_CERLPDA" ||
            this.props.section === "him_CERLCANYOUHELP") && (
            <Grid.Column width={5}>
              {this.state.obj && this.state.obj.COMMENT && (
                <div>
                  <h5>Notes:</h5>
                  {this.state.obj.COMMENT[0]}
                </div>
              )}
              {this.state.obj &&
                this.state.obj.HIM.indexOf("CERLCANYOUHELP") > -1 && (
                  <a
                    href={`${
                      process.env.REACT_APP_URL_PATH
                    }/section/him_CERLCANYOUHELP`}
                    style={{ fontSize: "130%", color: "red" }}
                    onClick={() =>
                      this.props.history.push(
                        `${
                          process.env.REACT_APP_URL_PATH
                        }/section/him_CERLCANYOUHELP`
                      )
                    }
                  >
                    Can You Help?
                  </a>
                )}
              {this.state.obj ? (
                <Comments
                  obj={this.state.obj}
                  search={this.props.search}
                  resetAfterSave={() =>
                    this.setState({
                      skipSearchCache: true,
                      obj: null,
                    })
                  }
                />
              ) : (
                `Loading ${this.props.match.params.uid}`
              )}
            </Grid.Column>
          )}
        </Grid>

        <PageFooter />
      </div>
    );
  }
}

export default ViewPage;
