import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";


class Person extends DefaultObject {
  render() {
    return (
      <div>
        <B if="NOTE">
          <b>Note:</b> <T br>NOTE</T>
        </B>
        <B if="OCCUPAT">
          <b>Occupations:</b> <T br>OCCUPAT</T>
        </B>
        <B if="PLACE_ACTIVE">
          <b>Active in:</b> <T br>PLACE_ACTIVE</T>
        </B>
        <B if="SHOW_ADDRESS">
          <b>Address(es):</b> <T br>SHOW_ADDRESS</T>
        </B>
        <B if="SHOW_SHOP">
          <b>Shop-sign(s):</b> <T br>SHOW_SHOP</T>
        </B>
      </div>
    );
  }
}

export default Person;
