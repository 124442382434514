import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { Iconclass } from "../Iconclass";
import { DefaultObject, ZoomedImage } from "./index";

class ProvenanceImage extends DefaultObject {
  render() {
    return (
      <div>
        {this.props.obj.OWNERS && (
          <div style={{ fontSize: "140%", margin: "1em 0 1em 0" }}>
            <b>Owner: </b>
            {this.props.obj.OWNERS.map((val, i) => (
              <p style={{ margin: "0" }} key={"owners_" + i}>
                {val}
              </p>
            ))}
          </div>
        )}
        <B if="CAPTION">
          <b>Description: </b>
          <i>
            <T br>CAPTION</T>
          </i>
        </B>
        <B if="TEXT">
          <b>Transcription: </b> <T br>TEXT</T>
        </B>
        <B if="PERSON_AUTHOR">
          <b>Source: </b>
          <B if="PERSON_AUTHOR">
            <T>PERSON_AUTHOR</T>&nbsp;
          </B>
          <B if="TITLE">
            <T>TITLE</T>&nbsp;
          </B>
          <B if="IMPRINT">
            <T>IMPRINT</T>
          </B>
        </B>
        {this.props.obj.INSTIT_CERLID && (
          <div>
            <b>Institution:</b>{" "}
            {this.props.obj.INSTIT_CERLID.map(o => (
              <a href={`https://data.cerl.org/holdinst/${o.split("|")[0]}`}>
                {o.split("|")[1]}
              </a>
            ))}
            <B if="SHELFMARK">
              <b> Shelfmark: </b>
              <T>SHELFMARK</T>
            </B>
          </div>
        )}
        {this.props.obj.LOCATION_ORIG_CERLID && (
          <div>
            <b>Place of use:</b>{" "}
            {this.props.obj.LOCATION_ORIG_CERLID.map(o => (
              <a href={`https://data.cerl.org/thesaurus/${o.split("|")[0]}`}>
                {o.split("|")[1]}
              </a>
            ))}
          </div>
        )}
        {this.props.obj.OWNERS_CERLID && (
          <div>
            <b>Former Owners:</b>{" "}
            {this.props.obj.OWNERS_CERLID.map(o => (
              <a href={`https://data.cerl.org/thesaurus/${o.split("|")[0]}`}>
                {o.split("|")[1]}
              </a>
            ))}
          </div>
        )}
        <B if="PAGE">
          <b> Location: </b> <T br>PAGE</T>
        </B>
        <B if="TYPE_INS">
          <b>Type: </b>
          <T>TYPE_INS</T>
        </B>
        &nbsp; &nbsp;
        <B if="TECHNIQUE">
          <b>Technique: </b>
          <T>TECHNIQUE</T>
        </B>
        &nbsp; &nbsp;
        <B if="DATE_ORIG_CENTURY">
          <b>Timeperiod: </b>
          <T>DATE_ORIG_CENTURY</T>th century
        </B>
        <B if="DATE_ORIG">
          <b> Date: </b>
          <T>DATE_ORIG</T>
        </B>
        <div>
          <B if="WIDTH">
            <b> Width: </b> <T>WIDTH</T> cm
          </B>
          <B if="HEIGHT">
            <b> Height: </b> <T>HEIGHT</T> cm
          </B>
        </div>
        {this.props.obj.IC &&
        this.props.obj.IC.length > 0 &&
        this.props.section.startsWith("him_CERL") ? (
          <div>
            <div style={{ marginTop: "1em" }}>
              <h4 style={{ margin: "0" }}>ICONCLASS</h4>
              {this.props.obj.IC.map(ic => (
                <Iconclass
                  key={ic}
                  notation={ic}
                  pathrefine
                  search={this.props.search}
                  shownotation
                  showcheckbox
                />
              ))}
            </div>
          </div>
        ) : (
          <div>&nbsp;</div>
        )}
        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
        <div style={{ padding: "1em", backgroundColor: "white" }}>
          <B if="_OWNER_INFO">
            <b>Uploaded by:</b> {this.props.obj._OWNER_INFO[0]}
          </B>
        </div>
      </div>
    );
  }
}

export default ProvenanceImage;
