import React, { Component } from "react";
import { Button, Form, Segment, Popup, Icon } from "semantic-ui-react";
import { markdown } from "markdown";

import { addEntry, deleteEntry } from "../../search/searchHelpers";

class Comments extends Component {
  state = {};

  render() {
    return (
      <Segment.Group>
        <Segment>
          {this.props.obj._annotations &&
            this.props.obj._annotations.COMMENT && (
              <div style={{ marginBottom: "1em" }}>
                <h4>Comments</h4>
                {this.props.obj._annotations.COMMENT.map(annot => (
                  <div
                    style={{
                      display:
                        annot.pk === this.state.commentEditPk ? "none" : "block"
                    }}
                  >
                    <hr />
                    {this.props.search.credentials &&
                      annot.userpk === this.props.search.credentials.userpk && (
                        <Popup
                          trigger={
                            <Icon
                              style={{ cursor: "pointer" }}
                              name="edit"
                              compact
                              floated="right"
                              onClick={event =>
                                this.setState({
                                  commentEditPk: annot.pk,
                                  comment: annot.value,
                                  isCommenting: true
                                })}
                            />
                          }
                        >
                          Click here to edit this comment
                        </Popup>
                      )}
                    by: <Icon name="user" /> {annot.username}{" "}
                    {new Date(Date.parse(annot.timestamp)).toLocaleString(
                      "en-GB"
                    )}
                    <div
                      style={{ marginBottom: "1em" }}
                      dangerouslySetInnerHTML={{
                        __html: markdown.toHTML(annot.value)
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          {this.state.isCommenting ? (
            <div>
              <Form>
                <Form.TextArea
                  placeholder="...comments..."
                  fluid
                  value={this.state.comment}
                  onChange={(event, data) =>
                    this.setState({ comment: event.target.value })}
                />
                <Button.Group>
                  <Button
                    color="green"
                    icon="save"
                    compact
                    size="tiny"
                    onClick={event => {
                      if (!this.state.comment) return;
                      addEntry(
                        this.state.commentEditPk || null,
                        this.props.obj.ID[0],
                        "COMMENT",
                        this.state.comment,
                        this.props.search.credentials.token,
                        () => {
                          this.setState({
                            isCommenting: false,
                            commentEditPk: null,
                            comment: ""
                          });
                          this.props.resetAfterSave();
                        }
                      );
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    icon="cancel"
                    compact
                    size="tiny"
                    onClick={event =>
                      this.setState({
                        isCommenting: false,
                        commentEditPk: null,
                        comment: ""
                      })}
                  >
                    Cancel
                  </Button>
                  {this.state.commentEditPk && (
                    <Button
                      icon="delete"
                      compact
                      size="tiny"
                      color="orange"
                      onClick={event =>
                        deleteEntry(
                          this.state.commentEditPk,
                          this.props.search.credentials.token,
                          () => {
                            this.setState({
                              isCommenting: false,
                              commentEditPk: null,
                              comment: ""
                            });
                            this.props.resetAfterSave();
                          }
                        )}
                    >
                      Delete
                    </Button>
                  )}
                </Button.Group>
              </Form>
            </div>
          ) : (
            this.props.search.credentials && (
              <Button
                compact
                onClick={event => this.setState({ isCommenting: true })}
              >
                Add Comment
              </Button>
            )
          )}
        </Segment>
      </Segment.Group>
    );
  }
}

export default Comments;
