import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import Section from "./components/Section";
import ViewPage from "./components/ViewPage";
import { HelpPage } from "./components/HelpPage";
import ListView from "./components/ListView";
import CERLPDA from "./components/cerl/CERLPDA";
import Searcher from "./search/searcher";
import feedback from "./assets/images/feedback.png";
import { parse } from "query-string";
import NotFound from "./components/liminal/NotFound";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: new Searcher()
    };
  }

  componentDidCatch() {
    // Display fallback UI
    this.setState({ hasError: true });
    setTimeout(() => this.setState({ hasError: undefined }), 10000);
  }

  updateSearchTerm(params) {
    const { searchTerm } = params;
    this.setState({ searchTerm });
    return searchTerm;
  }

  render() {
    // see if there is any credentials passed in as a token
    const parsed = parse(window.location.search);
    if (parsed.token && parsed.name && parsed.exp && parsed.uid) {
      this.state.search.addCredentials({
        token: parsed.token,
        name: parsed.name,
        exp: parsed.exp,
        uid: parsed.uid
      });
    }
    if (parsed.token && parsed.token === "skip") {
      this.state.search.removeCredentials();
      this.state.search.saveSettings();
    }

    return (
      <Router>
        <div>
          <a
            target="_feedback"
            href={`${process.env.REACT_APP_FEEDBACK_GOOGLE_FORM}${
              window.location.href
            }`}
          >
            <img
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                border: 0,
                zIndex: 1000
              }}
              src={feedback}
              alt="Feedback ribbon with link to comments form"
            />
          </a>
          {this.state.__hasError && (
            <div
              style={{
                backgroundColor: "#cc271e",
                color: "white",
                fontSize: "130%",
                padding: "20px 100px 10px 50px"
              }}
            >
              Oops, an error has occurred in the application. Sorry about that.
              <br />
              Can you please send us some feedback using the ribbon in the
              top-right hand corner and let us know what you were doing when the
              error occurred?
            </div>
          )}
          <Switch>
            <Route
              exact
              path="/"
              render={({ match, history }) => (
                <div>
                  <Home
                    match={match}
                    history={history}
                    search={this.state.search}
                  />
                </div>
              )}
            />
            <Route
              exact
              path={`${process.env.REACT_APP_URL_PATH}/`}
              render={({ match, history }) => (
                <div>
                  <Home
                    match={match}
                    history={history}
                    search={this.state.search}
                  />
                </div>
              )}
            />
            <Route
              path={`${process.env.REACT_APP_URL_PATH}/section/:section`}
              render={({ match, history }) => (
                <div>
                  <Section
                    match={match}
                    history={history}
                    search={this.state.search}
                  />
                </div>
              )}
            />
            <Route
              path={`${process.env.REACT_APP_URL_PATH}/view/:uid/:section`}
              render={({ match, history, location }) => (
                <div>
                  <ViewPage
                    match={match}
                    history={history}
                    location={location}
                    section={match.params.section}
                    search={this.state.search}
                  />
                </div>
              )}
            />
            <Route
              path={`${process.env.REACT_APP_URL_PATH}/lists`}
              render={({ match, history, location }) => (
                <div>
                  <ListView
                    search={this.state.search}
                    match={match}
                    history={history}
                    location={location}
                  />
                </div>
              )}
            />
            <Route
              path={`${process.env.REACT_APP_URL_PATH}/help/:page`}
              render={({ match, history, location }) => (
                <HelpPage
                  match={match}
                  history={history}
                  location={location}
                  search={this.state.search}
                  page={match.params.page}
                />
              )}
            />

            <Route
              path={`${process.env.REACT_APP_URL_PATH}/cerl/pda/edit/:uid`}
              render={({ match, history, location }) => (
                <div>
                  <CERLPDA
                    search={this.state.search}
                    match={match}
                    history={history}
                    location={location}
                    uid={match.params.uid}
                  />
                </div>
              )}
            />
            <Route
              path={`${process.env.REACT_APP_URL_PATH}/cerl/pda/`}
              render={({ match, history, location }) => (
                <div>
                  <CERLPDA
                    search={this.state.search}
                    match={match}
                    history={history}
                    location={location}
                  />
                </div>
              )}
            />
            <Route
              render={({ history }) => (
                <NotFound search={this.state.search} history={history} />
              )}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
