import React from "react";

const PageFooter = () => (
  <div
    style={{
      backgroundColor: "white",
      textAlign: "center",
      width: "100%",
      paddingBottom: "1em",
      marginTop: "2em",
    }}
  >
    For more information please visit{" "}
    <a href="https://brill.com/arko">brill.com</a>
    &middot;{" "}
    <a href="https://brill.com/page/PrivacyStatement/privacy-statement">
      Privacy Policy
    </a>{" "}
    &middot;{" "}
    <a href="https://brill.com/page/RightsPermissions/rights-and-permissions">
      Copyright &amp; Permissions
    </a>
  </div>
);

export default PageFooter;
