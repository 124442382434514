import React from "react";

import T from "../utils/T";
import { DefaultObject } from "./index";

class Journal extends DefaultObject {
  render() {
    return (
      <div>
        <T br>TITLE_SHOW</T>
        <T br>IMPRESSUM</T>
        <T br>ANNOT</T>
        <T br>DATE_ORIG</T>
        <T>BIBLIO_FORM</T>
        {this.children()}
      </div>
    );
  }
}

export default Journal;
