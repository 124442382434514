import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject } from "./index";

class Bibliography extends DefaultObject {
  render() {
    return (
      <div>
        <B if="AUT|PERSON_AUTHOR">
          <b>
            Author: <T br>AUT|PERSON_AUTHOR</T>
          </b>
        </B>
        <i>
          <T br>TITLE|TITLE_ABBR</T>
        </i>
        <B if="PUB">
          <T>PUB</T>
        </B>

        <T br>DATE</T>
        <T br>COM</T>
        <B if="MAG">
          <T>MAG</T>, pp.
        </B>
        <T>LOC</T>
      </div>
    );
  }
}

export default Bibliography;
