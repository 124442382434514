import React, { Component } from "react";
import { Button, Form, Grid, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";

import helpstrip from "../assets/images/helpstrip.jpg";
import helpabout from "../assets/images/helpabout.png";
import helppartnercollections from "../assets/images/helppartnercollections.png";
import helpessays from "../assets/images/helpessays.png";
import helpessays1 from "../assets/images/helpessays1.png";
import helpessays2 from "../assets/images/helpessays2.png";
import helpessays3 from "../assets/images/helpessays3.png";
import helpessays4 from "../assets/images/helpessays4.png";
import helpplatform from "../assets/images/helpplatform.png";
import SearchInputGrid from "../components/liminal/SearchInputGrid";
import PageHeader from "../components/liminal/PageHeader";
import PageFooter from "../components/liminal/PageFooter";

const SectionBlock = ({
  color = "#fff",
  image = "",
  link = `${process.env.REACT_APP_URL_PATH}/`,
  children
}) => (
  <div>
    <div
      style={{
        minHeight: "50px",
        fontSize: "130%",
        backgroundColor: color,
        padding: "0.5em"
      }}
    >
      {link.endsWith(".pdf") ? (
        <a
          href={link}
          style={{ display: "block", height: "100%", color: "white" }}
        >
          {children}
        </a>
      ) : (
        <Link
          to={link}
          style={{ display: "block", height: "100%", color: "white" }}
        >
          {children}
        </Link>
      )}
    </div>
    <div style={{ float: "right" }}>
      <Image src={image} />
    </div>
  </div>
);

class PlatformPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm
    };
  }
  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () => {
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`);
    });
  };
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={"all"}
          search={this.props.search}
          history={this.props.history}
        />
        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={event => this.setState({ searchTerm: event.target.value })}
          doSearch={this.doSearch}
        />
        <div style={{ marginTop: "3em" }}>
          <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/start`} color={"#c5370b"}>
            Arkyves as a platform to publish collections and datasets
          </SectionBlock>
        </div>
        <Image src={helpplatform} floated="right" size="medium" />
        <div style={{ marginTop: "3em", width: "40em" }}>
          <p>
            Arkyves is much more than a database with a toolset for end-users.
            It is also an infrastructure with indexing, searching, browsing,
            hyperlinking and annotation functionalities, available to both
            institutions and individuals aiming to publish a catalogued
            collection or a dataset.
          </p>

          <p>
            This infrastructure, which includes the complete Iconclass system,
            is optimized for retrieval and made available at no cost to
            institutions and individual researchers who want to share their
            information. In other words, publishing your dataset in Arkyves,
            whether it consists of a few hundred images or hundreds of thousands
            of images, is free.
          </p>

          <p>
            Brill will host your collection at no cost, make it available to the
            audience of your choice, on the conditions of your choice, even if
            that means that there are no restrictions to accessing that
            collection. If you want your collection to made available in
            complete Open Access, that is what Brill will offer. Your collection
            will be offered with the look-and-feel and the functionalities of
            the main Arkyves site. All Brill asks in return is your permission
            to simultaneously incorporate the collection in the main Arkyves
            site.
          </p>

          <p>
            Preferably, collections incorporated in Arkyves for our partners,
            come indexed with Iconclass. If they do not, but their subject
            matter is described in a systematic way, e.g. with the help of
            another controlled vocabulary, we will determine whether a
            concordance between your vocabulary and the Iconclass system is
            feasible. If a concordance can be construed, your dataset or
            catalogue file can be automatically enriched with the Iconclass
            concepts at indexing time, drastically enhancing the (multilingual)
            retrieval options for your data.
          </p>
        </div>
        <PageFooter />
      </div>
    );
  }
}
class EssayPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm
    };
  }
  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () => {
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`);
    });
  };
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={"all"}
          search={this.props.search}
          history={this.props.history}
        />
        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={event => this.setState({ searchTerm: event.target.value })}
          doSearch={this.doSearch}
        />
        <div style={{ marginTop: "3em" }}>
          <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/start`} color={"#575bfb"}>
            Essays in iconography
          </SectionBlock>

          <Image src={helpessays} floated="right" size="medium" />
          <div style={{ marginTop: "3em", width: "50em" }}>
            <p>
              <a href={`${process.env.REACT_APP_URL_PATH}/view/essay0014/all`}>A Gentle Introduction</a>
            </p>
            <p>
              The digital revolution has dramatically transformed the way
              historical sources are offered to the researcher as museums,
              libraries, archives and documentation institutes are making more
              and more of their holdings available for consultation and
              downloading.
              <br />
              At the same time, it is still quite difficult to take full
              advantage of these developments when you are publishing the
              results of research in the history of culture, mentality or
              iconography. The digital abundance simply does not fit between the
              covers of a printed book or a journal article.
            </p>

            <div style={{ margin: "2em 0" }}>
              <a href="https://brill.com/view/title/24274">
                <Image src={helpessays1} size="small" floated="left" />
              </a>
              <a href="https://brill.com/view/title/15237">
                <Image src={helpessays2} size="small" floated="left" />
              </a>
              <a href="https://brill.com/view/title/35225">
                <Image src={helpessays3} size="small" floated="left" />
              </a>
              <a href="https://brill.com/view/title/25199">
                <Image src={helpessays4} size="small" floated="left" />
              </a>
            </div>

            <p style={{ clear: "both" }}>
              These are just a few random examples of recent publications
              dealing with themes for which a database like Arkyves could have
              provided a lot of background material. At present it contains some
              2,000 medieval and early modern examples of{" "}
              <Link to={`${process.env.REACT_APP_URL_PATH}/section/all/?facet_ic=O31E23621&facet_ic=O98C(TOMYRIS)41&facet_ic=O94P33&facet_ic=O97CC641&facet_ic=O71U4273&facet_ic=O73C1333&facet_ic=O11H(GEORGE)68`}>
                beheadings
              </Link>
              , almost 900 scenes from the{" "}
              <Link to={`${process.env.REACT_APP_URL_PATH}/section/all/?facet_ic=O71F3&facet_ic=O11I62(SAMSON)`}>
                story of Samson
              </Link>
              , well over 11,000{" "}
              <Link to={`${process.env.REACT_APP_URL_PATH}/section/all/?facet_ic=O34B11&facet_ic=O73C852`}>
                dogs
              </Link>{" "}
              in pictures, ranging from portraits to emblems, and also more than
              1,000 pictures of{" "}
              <Link to={`${process.env.REACT_APP_URL_PATH}/section/all/?facet_ic=O92L3&facet_ic=O92C3521&facet_ic=O92I2&facet_ic=O97C1&facet_ic=O92C3511`}>
                nymphs
              </Link>
              .
            </p>

            <p>
              Obviously, these numbers do not mean very much by themselves. Many
              of the examples in Arkyves may not be very relevant to the
              research described in these books; other databases may have a
              wealth of supplementary sources, while search engines like Google
              suggest that however many samples a particular database provides,
              there remains a world of other samples waiting to be explored.{" "}
            </p>

            <p>
              What is important is the realization that iconographic research
              and the publication of its results cannot escape this new reality,
              whatever its dimensions are. Researchers have always created
              hybrid datasets – collecting photographs, photocopies from books,
              slides – while testing their hypotheses and building their
              arguments. They still do, now adding digital copies to the mix.
              What has changed is the fact that they no longer have to be highly
              selective when presenting their material in a publication;
              provided, that is, that they can use a digital platform instead
              of, or in addition to, a more traditional format. With Arkyves we
              aim to offer such a platform, allowing users to store their search
              results in Lists, add their own material to the Arkyves database
              and write essays about the material they have thus gathered.
            </p>
          </div>
        </div>
        <PageFooter />
      </div>
    );
  }
}
class PartnerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm
    };
  }
  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () => {
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`);
    });
  };
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={"all"}
          search={this.props.search}
          history={this.props.history}
        />
        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={event => this.setState({ searchTerm: event.target.value })}
          doSearch={this.doSearch}
        />
        <div style={{ marginTop: "3em" }}>
          <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/start`} color={"#77933c"}>
            Partners and aggregated collections
          </SectionBlock>

          <Image src={helppartnercollections} floated="right" size="medium" />
          <div style={{ marginTop: "3em", width: "40em" }}>
            <p>
              Although the Arkyves website is created by a small team, its
              content is provided by a small army of cataloguers, working for a
              growing group of partner institutions and heritage projects.
              Arkyves brings together collections from a.o. the following
              museums, libraries, documentation institutes and cataloguing
              projects:
            </p>

            <ul>
              <li>Glasgow University Library - French emblems, Alciatus</li>
              <li>Bayerische Staatsbibliothek - Incunables</li>
              <li>Biblia Sacra project - Illustrated bibles</li>
              <li>Bibliothèques Virtuelles Humanistes</li>
              <li>Byvanck Illuminated Manuscript project</li>
              <li>
                University of Illinois, Urbana-Champaign - German and other
                emblems
              </li>
              <li>
                Folger Shakespeare Library, Washington – English book
                illustration
              </li>
              <li>Getty Research Institute &amp; Provenance Index</li>
              <li>
                Herzog August Bibliothek Wolfenbüttel - Prints, Emblems and
                Alchemy
              </li>
              <li>Anton Ulrich Museum, Braunschweig - Prints</li>
              <li>
                Koninklijke Bibliotheek, The Hague - Illuminated manuscripts,
                incunables, devices
              </li>
              <li>University of Milan, Marengo Satirical Journals project</li>
              <li>Cardiff University, Mid-Victorian Illustration project</li>
              <li>Museum Meermanno - Illuminated Manuscripts</li>
              <li>Deutsches Hygiene-Museum, Dresden</li>
              <li>RKD, Netherlands Institute for Art History</li>
              <li>Rijksmuseum, Amsterdam</li>
              <li>
                University Library, Amsterdam - Illustrated title-pages,
                emblems, typography
              </li>
              <li>University Library, Utrecht, - Emblems</li>
            </ul>

            <p>
              These collections were catalogued independently from each other,
              in unrelated projects with different metadata rules and in
              different languages. However, their subject metadata, usually the
              most widely divergent aspect of aggregated datasets, can be
              queried with a single vocabulary, since all projects share the use
              of iconclass.{" "}
            </p>
          </div>
        </div>
        <PageFooter />
      </div>
    );
  }
}

class AccessPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm
    };
  }
  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () => {
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`);
    });
  };
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader search={this.props.search} history={this.props.history} />
        <div style={{ marginTop: "3em" }}>
          <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/start`} color={"#3cc190"}>
            Getting Access via your Institution
          </SectionBlock>
          <div style={{ marginTop: "3em", width: "40em" }}>
            <p>
              It is possible to log in using a system like Shibboleth from your
              institution.
              <br />
              <a href="http://www.arkyves.org/LOGOUT?dest=https%3A%2F%2Fshibboleth2sp.brillonline.nl%2Fshib%3Fdest%3Dhttp%253A%252F%252Fwww.arkyves.org%252FSHIBBOLETH%253Fdest%253Dhttp%25253A%25252F%25252Fwww.arkyves.org%25252F">
                Click here for institutional login.
              </a>
            </p>
          </div>

          <div style={{ marginTop: "3em", width: "40em" }}>
            <p>
              If you have been provided with a username and password for an
              individual Subscription or Trial license, you can log in using
              this form:
            </p>
            <form action="/LOGIN" method="POST">
              <Form.Input width={20} name="user" placeholder="Username" />
              <Form.Input
                width={20}
                type="password"
                name="pass"
                placeholder="Password"
              />
              <Button type="submit" color="blue" floated="right">
                Login
              </Button>
              <input
                type="hidden"
                name="dest"
                value="https://www.arkyves.org/"
              />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm
    };
  }
  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () => {
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`);
    });
  };
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={"all"}
          search={this.props.search}
          history={this.props.history}
        />
        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={event => this.setState({ searchTerm: event.target.value })}
          doSearch={this.doSearch}
        />
        <div style={{ marginTop: "3em" }}>
          <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/start`} color={"#c5370b"}>
            About Arkyves: some basics
          </SectionBlock>

          <Image src={helpabout} floated="right" size="medium" />
          <div style={{ marginTop: "3em", width: "40em" }}>
            <p>
              Arkyves has two faces. It is a treasure trove for anyone
              interested in the History of Culture, and at the same time it is a
              toolbox and a platform for researchers, working in the field of
              the arts, literature, and visual communication. It is a single
              access point for thematic searches across a wide variety of
              cultural heritage collections, contributed by partners like the
              Dutch Rijksmuseum, the Netherlands Institute for Art History, the
              Herzog August Bibliothek, the Getty Research Institute, the
              Bayerische Staatsbibliothek, the Koninklijke Bibliotheek, and the
              university libraries of Milan, Utrecht, Glasgow, and Illinois.
            </p>

            <p>
              Although access to the complete <em>aggregated</em> content of
              Arkyves is only available for subscribers, the partners can also
              offer open access to their indexed collections, which makes
              Arkyves a highly topical experiment in <em>hybrid open access</em>
              databases. Rich yet standardized subject information is offered
              since all collections share the use of ICONCLASS, the most widely
              accepted multilingual classification system for cultural content.
              An earlier version of Arkyves, launched in 1999, went by the title
              <em>Mnemosyne</em>, evidence of its Warburgian inspiration. Its
              first content was provided by Yassu Frossati, Peter van Huisstede
              and Hans Brandhorst, who is still acting as its editor. Its
              software was, then as it is now, created by Etienne Posthumus.
            </p>

            <p>
              Over the years Arkyves has been facilitated, promoted and provided
              with material by so many people that listing them exhaustively is
              impossible. We should mention, however, Alison Rawles, Thomas
              Stäcker, Julia Thomas, Marta Sironi, Mara Wade, Henrike Hövelmann,
              Anne Korteweg, Els Stronks, Erin Blake, Marjolijn Verbrugge,
              Alexandra Marraccini, Marie-Luce Demonet, Nelly de
              Hommel-Steenbakkers, Vladmimir Cajkovac, and especially the
              cataloguing teams at all contributing institutions.
            </p>

            <p>
              It should be noted that Arkyves growth is organic, and based on
              flexible forms of collaboration. That the sum of its heterogeneous
              datasets can still be much more than its parts, is due to the
              practical genius of <strong>Henri van de Waal</strong> who
              recognized the crucial role of iconography for all kinds of
              historical research, created an indispensable tool for it with
              ICONCLASS, but also saw what computers could mean for art history
              at a time when almost none of his colleagues in the humanities
              did.
            </p>
          </div>
        </div>
        <PageFooter />
      </div>
    );
  }
}

class StartPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm
    };
  }
  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () => {
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`);
    });
  };
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={"all"}
          search={this.props.search}
          history={this.props.history}
        />
        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={event => this.setState({ searchTerm: event.target.value })}
          doSearch={this.doSearch}
        />

        <Grid columns={1} stackable>
          <Grid.Column>
            <Image src={helpstrip} centered />
          </Grid.Column>
        </Grid>

        <Grid columns={2} stackable style={{ marginTop: "6em" }}>
          <Grid.Column key={"about"}>
            <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/about`} color={"#c5370b"}>
              About Arkyves
            </SectionBlock>
          </Grid.Column>
          <Grid.Column key={"partnercollections"}>
            <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/partnercollections`} color={"#77933c"}>
              Partner Collections
            </SectionBlock>
          </Grid.Column>
        </Grid>

        <Grid columns={2} stackable style={{ marginTop: "6em" }}>
          <Grid.Column key={"essays"}>
            <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/essays`} color={"#575bfb"}>
              Essays In Iconography
            </SectionBlock>
          </Grid.Column>
          <Grid.Column key={"pubplatform"}>
            <SectionBlock link={`${process.env.REACT_APP_URL_PATH}/help/platform`} color={"#c5370b"}>
              Arkyves as publishing platform
            </SectionBlock>
          </Grid.Column>
        </Grid>

        <Grid columns={2} stackable style={{ marginTop: "6em" }}>
          <Grid.Column key={"essays"}>
            <SectionBlock link={"/help/ArkyvesIntro2.0.pdf"} color={"#20673b"}>
              Instructions
            </SectionBlock>
          </Grid.Column>
        </Grid>

        <PageFooter />
      </div>
    );
  }
}

class TrialPage extends Component {
  render() {
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={null}
          search={this.props.search}
          history={this.props.history}
        />
        <div
          style={{
            marginTop: "30px",
            float: "right",
            padding: "1em",
            border: "2px solid #ccc"
          }}
        >
          <a href="https://buyaccess.brillonline.com/shop/home?filter_pe_id=2352-9334">
            <h3>More information about a 7-days access pass</h3>
          </a>
          <h3>
            <a href={`${process.env.REACT_APP_URL_PATH}/help/start`}>More information about Arkyves</a>
          </h3>
        </div>

        <div style={{ marginTop: "5em", width: "40em" }}>
          <h1>Would you like to see more?</h1>
          <p>
            To enquire about this product, or arrange a{" "}
            <strong>free 30-day institutional trial</strong>, please contact our
            Sales Department at{" "}
            <a href="mailto:sales-nl@brill.com?subject=Arkyves Trial Subscription">
              sales-nl@brill.com
            </a>{" "}
            (outside the Americas) <br />
            or{" "}
            <a href="mailto:sales-us@brill.com?subject=Arkyves Trial Subscription">
              sales-us@brill.com
            </a>{" "}
            (the Americas).
            <br />
            <br />
            For price details see{" "}
            <a href="https://brill.com/arko">brill.com/arko</a>
          </p>
        </div>

        <div style={{ marginTop: "10em" }}>
          If you see this page, either:
          <ul>
            <li>You have a personal password but you forgot to login first,</li>
            <li>
              You are using a computer at an institute that does not subscribe
              to Arkyves.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = { searchTerm: "" };
  }
  render() {
    switch (this.props.page) {
      case "access":
        return (
          <AccessPage search={this.props.search} history={this.props.history} />
        );
      case "about":
        return (
          <AboutPage search={this.props.search} history={this.props.history} />
        );
      case "partnercollections":
        return (
          <PartnerPage
            search={this.props.search}
            history={this.props.history}
          />
        );
      case "essays":
        return (
          <EssayPage search={this.props.search} history={this.props.history} />
        );
      case "platform":
        return (
          <PlatformPage
            search={this.props.search}
            history={this.props.history}
          />
        );
      case "trial":
        return (
          <TrialPage search={this.props.search} history={this.props.history} />
        );
      default:
        return (
          <StartPage search={this.props.search} history={this.props.history} />
        );
    }
  }
}
export { HelpPage };
