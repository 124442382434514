import React from "react";

import B from "../utils/B";
import T from "../utils/T";
import { DefaultObject, ZoomedImage } from "./index";


class Proverb extends DefaultObject {
  render() {
    return (
      <div>
        <B if="PERSON_BSPR">
          <b>Edition:</b> <T>PERSON_BSPR</T>, <T>LOCATION_ORIG</T>,{" "}
          <T>DATE_ORIG</T>
          <br />
        </B>
        <B if="LOCATION_INV">
          <b>Copy:</b> <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T> &middot;{" "}
          <T>ID_INV</T>
          <br />
        </B>
        <b>
          <T br>SOU</T>
        </b>
        <B if="NUMBER_ROMAN_ORG">
          <b>Adagium:</b> <T>NUMBER_ROMAN_ORG</T> (ASD)
          <br />
        </B>
        <B if="JUX_1703">
          <b>Locus:</b> <T br>JUX_1703</T>
        </B>
        <div style={{ fontSize: "120%", textAlign: "center", width: "100%" }}>
          <T>PROVERB_LA_NORM</T>
          <T>PROVERB_EL_NORM</T> &mdash; <T br>PROVERB_EN_NORM</T>
        </div>

        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />

        <div
          style={{ maxWidth: "650px", paddingLeft: "3em", marginTop: "2em" }}
        >
          <T safe>ESSAY_XML_EN</T>
        </div>

        <div style={{ paddingLeft: "3em", marginTop: "2em" }}>
          <h4>Source text:</h4>
          <T safe>ESSAY_XML</T>
        </div>
        <B if="FROLINK">
          <b>Compare:</b> <T>FROLINK</T> (ASD), in the edition Froben 1559
          <br />
        </B>
      </div>
    );
  }
}

export default Proverb;
