import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Card, Image, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";

import { TYPES } from "../constants";
import B from "./utils/B";
import T from "./utils/T";
import BibIcoon from "../assets/images/bib_icoon1.svg";
import EmblemIcoon from "../assets/images/emblem_icoon2.svg";
import BookIcoon from "../assets/images/book_icoon2.svg";
import PersonIcoon from "../assets/images/person_icoon1.svg";
import JournalIcoon from "../assets/images/journal_icoon1.svg";
import ManuscriptIcoon from "../assets/images/manuscript_icoon1.svg";
import { ObjChooser } from "./ObjChooser";
import { ZoomedImage } from "./objects";
import { Iconclass } from "./Iconclass";

class MidView extends Component {
  getIC() {
    if (
      typeof this.props.obj.IC === "undefined" ||
      this.props.obj.IC.length < 1
    )
      return null;
    return (
      <div
        style={{
          float: "right",
          width: "30%",
          backgroundColor: "#F8F8F8",
          padding: "1em",
          borderBottom: "2px solid #F4F4F4",
          borderRight: "2px solid #F4F4F4",
        }}
      >
        <h4>Iconclass</h4>
        {this.props.obj.IC.map((ic) => (
          <Iconclass
            triggerUpdate={this.props.triggerUpdate}
            key={ic}
            notation={ic}
            pathrefine
            search={this.props.search}
            shownotation
            showcheckbox
          />
        ))}
      </div>
    );
  }

  render() {
    return (
      <Modal
        open={this.props.modalOpen}
        onClose={this.props.handleClose}
        closeIcon
        size="fullscreen"
      >
        <Modal.Header>
          <div>
            <Link
              to={`${process.env.REACT_APP_URL_PATH}/view/${
                this.props.obj.ID
              }/${this.props.section}`}
            >
              More details
            </Link>
            {this.props.obj.URL_WEBPAGE && (
              <a
                style={{ float: "right", marginRight: "4em" }}
                href={this.props.obj.URL_WEBPAGE[0]}
                target="_new"
              >
                Original Source
              </a>
            )}
          </div>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <ZoomedImage
              obj={this.props.obj}
              style={{
                height: "500px",
                width: "400px",
                marginRight: "1em",
                float: "left",
              }}
            />

            {this.getIC()}
            {ObjChooser(this.props.obj)}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

class Default extends Component {
  state = {
    modalOpen: false,
  };
  getChildContext() {
    return { obj: this.props.obj };
  }
  imagepart() {
    return (
      this.props.obj.ALLIMAGES &&
      this.props.obj.ALLIMAGES.length > 0 && (
        <Image
          style={{ cursor: "pointer" }}
          onClick={(event, data) => {
            this.props.history.push(
              `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${
                this.props.section
              }`
            );
          }}
          src={`${process.env.REACT_APP_IIIF_SERVER}/${
            this.props.obj.ALLIMAGES[0]
          }/full/150,/0/default.jpg`}
        />
      )
    );
  }
  textpart(obj) {
    return (
      <div>
        <T>TITLE|TITLE_SHOW</T>
        <T>SHOW_NAME</T>
        <T>SHOW_ADDRESS</T>
      </div>
    );
  }

  onModalClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    if (!this.props.obj || !this.props.obj.TYPE)
      return <div>{this.props.obj.ID}</div>;
    return (
      <Card>
        <Card.Content>
          {this.imagepart()}
          {this.props.showText && (
            <Card.Description
              onClick={(event, data) => {
                window._$tarQ.push([
                  "track",
                  {
                    datatype: "DATABASE",
                    title: process.env.REACT_APP_PROJECT_NAME,
                    itemtype: "RESULT_CLICK",
                  },
                ]);
                this.props.history.push(
                  `${process.env.REACT_APP_URL_PATH}/view/${
                    this.props.obj.ID[0]
                  }/${this.props.section}`
                );
              }}
            >
              {this.textpart(this.props.obj)}
            </Card.Description>
          )}
          {this.props.showMeta && (
            <Card.Meta>
              <Checkbox
                checked={this.props.isChecked}
                onClick={(event, data) => {
                  this.props.checkboxHandler(this.props.obj);
                }}
                label={
                  TYPES[
                    this.props.obj.TYPE[0].toLowerCase().replace(/ /g, "")
                  ] || this.props.obj.TYPE[0]
                }
              />
            </Card.Meta>
          )}
        </Card.Content>
        <MidView
          modalOpen={this.state.modalOpen}
          handleClose={this.onModalClose}
          obj={this.props.obj}
          search={this.props.search}
          section={this.props.section}
          triggerUpdate={this.props.triggerUpdate}
        />
      </Card>
    );
  }
}
Default.childContextTypes = {
  obj: PropTypes.object,
};

class PeriodicalImage extends Default {
  textpart(obj) {
    const languagecode =
      (this.props.search && this.props.search.IClanguagecode) || "en";
    let caption = (
      <T max="149" br>
        CAPTION_EN|CAPTION_IT
      </T>
    );
    if (languagecode === "it") {
      caption = (
        <T max="149" br>
          CAPTION_IT|CAPTION_EN
        </T>
      );
    }
    return (
      <div>
        <T>TITLE|TITLE_SHOW</T>
        <B if="CAPTION_IT">
          <div style={{ marginTop: "0.5em" }}>
            <em>{caption}</em>
          </div>
        </B>
      </div>
    );
  }
}

class ManuscriptBook extends Default {
  textpart(obj) {
    return (
      <div>
        <span style={{ fontSize: "75%" }}>
          <T>LOCATION_INV</T>, <T br>INSTIT_INV</T>
        </span>
        <B if="ID_INV">
          MS <T br>ID_INV</T>
        </B>
        <b>
          <T br>TITLE_ABBR</T>
        </b>
        <T br>LOCATION_ORIG</T>
        <T>DATE_ORIG</T>
      </div>
    );
  }
  imagepart() {
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={(event, data) =>
          this.props.history.push(
            `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${
              this.props.section
            }`
          )
        }
        src={ManuscriptIcoon}
      />
    );
  }
}

class ManuscriptIllumination extends Default {
  textpart(obj) {
    return (
      <div>
        <T max={99}>CAPTION_EN|CAPTION_NL</T>
      </div>
    );
  }
}

class Book extends Default {
  textpart(obj) {
    return (
      <div>
        <B if="LOCATION_INV">
          <T>LOCATION_INV</T> &middot; <T>INSTIT_INV</T>
          <br />
        </B>
        <i>
          <T max={49} br>
            TITLE_ABBR|TITLE_SHOW
          </T>
        </i>
        <T br>CREATOR</T>
        <T max={49} br>
          TITLE
        </T>
        <T br>LOCATION_ORIG</T>
        <T>DATE_ORIG</T>
      </div>
    );
  }
  imagepart() {
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={(event, data) =>
          this.props.history.push(
            `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${
              this.props.section
            }`
          )
        }
        src={BookIcoon}
      />
    );
  }
}

class BookIllustration extends Default {
  textpart(obj) {
    return (
      <div>
        <T max={140}>TITLE_ABBR|CAPTION_EN</T>
        <B if="PRINTER">
          <b>
            <T br>PRINTER</T>
          </b>
        </B>
      </div>
    );
  }
}

class Bibliography extends Default {
  textpart(obj) {
    return (
      <div>
        <span style={{ fontSize: "75%" }}>
          <T br>AUT</T>
          <T br>PERSON_AUTHOR</T>
        </span>
        <i>
          <T br>TITLE</T>
        </i>
        <i>
          <T br>TITLE_ABBR</T>
        </i>
        <T br>MAG</T>
        <T br>PUB</T>
        <T br>YEA</T>
        <T>LOC</T>
      </div>
    );
  }
  imagepart() {
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={(event, data) =>
          this.props.history.push(
            `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${
              this.props.section
            }`
          )
        }
        src={BibIcoon}
      />
    );
  }
}

class EmblemBook extends Default {
  textpart(obj) {
    return (
      <div>
        <T br>LOCATION_INV</T>
        <T br>INSTIT_INV</T>
        <T br>ID_INV</T>
        <b>
          <T br>TITLE_ABBR</T>
        </b>
        <T br>CREATOR</T>
        <T br>TITLE</T>
        <T br>LOCATION_ORIG</T>
        <T>DATE_ORIG</T>
      </div>
    );
  }
  imagepart() {
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={(event, data) =>
          this.props.history.push(
            `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${
              this.props.section
            }`
          )
        }
        src={
          this.props.obj.ALLIMAGES.length > 0
            ? `${process.env.REACT_APP_IIIF_SERVER}/${
                this.props.obj.ALLIMAGES[0]
              }/full/150,/0/default.jpg`
            : EmblemIcoon
        }
      />
    );
  }
}

class EmblemPictura extends Default {
  textpart(obj) {
    return (
      <div>
        <T>CAPTION_EN|CAPTION_NL</T>
      </div>
    );
  }
}

class Facsimile extends Default {
  textpart(obj) {
    return (
      <div>
        <i>
          <T>TITLE_ABBR</T>
        </i>
        <div style={{ marginTop: "0.5em" }}>
          <T>CAPTION_EN|CAPTION_NL</T>
        </div>
        <B if="PRINTER">
          <b>
            <T br>PRINTER</T>
          </b>
        </B>
      </div>
    );
  }
}

class AImage extends Default {
  textpart(obj) {
    return (
      <div>
        <i>
          <T max={75} br>
            CAPTION_EN|CAPTION_NL|TITLE
          </T>
        </i>
        <div style={{ marginTop: "0.5em" }}>
          <T>PERSON_ARTIST|CREATOR</T>
        </div>
      </div>
    );
  }
}

class Journal extends Default {
  textpart(obj) {
    return (
      <div>
        <T br>TITLE_SHOW</T>
        <T br>IMPRESSUM</T>
        <T br>BIBLIO_FORM</T>
        <T>COLLECTED</T>
      </div>
    );
  }
  imagepart() {
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={(event, data) =>
          this.props.history.push(
            `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${
              this.props.section
            }`
          )
        }
        src={JournalIcoon}
      />
    );
  }
}

class PrintersDeviceUsage extends Default {
  textpart(obj) {
    return (
      <div>
        <B if="SHOW_NAME">
          {this.props.obj.SHOW_NAME &&
            this.props.obj.SHOW_NAME.map((name) => (
              <div key={name}>{name}</div>
            ))}
        </B>
      </div>
    );
  }
}

class PrintersDevice extends Default {
  textpart(obj) {
    return (
      <div>
        <T max={99}>CAPTION_EN|CAPTION_NL</T>
        <B if="PRINTER">
          <b>
            <T br>PRINTER</T>
          </b>
        </B>
      </div>
    );
  }
}

class ProvenanceImage extends Default {
  textpart(obj) {
    return (
      <div style={{ margin: "0 0 1em 0" }}>
        {this.props.obj.OWNERS &&
          this.props.obj.OWNERS.map((owner) => <div key={owner}>{owner}</div>)}
      </div>
    );
  }
}

class ProvenanceItem extends Default {
  textpart(obj) {
    return (
      <div>
        <T br>PERSON_ARTIST</T>
        <T max={45} br>
          TITLE
        </T>
        <T br>TYPE_INS</T>
        <T br>ROOM</T>
        <B if="PERSON_OWNER">
          Owned by: <T max={45}>PERSON_OWNER</T>
        </B>
      </div>
    );
  }
}

class ProvenanceSource extends Default {
  textpart(obj) {
    return (
      <div>
        <T br>PERSON_OWNER</T>
        <T>INTRO_SRC</T>
      </div>
    );
  }
}

class Proverb extends Default {
  textpart(obj) {
    return (
      <div>
        <i>
          <T br>TITLE_ABBR</T>
        </i>
        <T br>NUMBER_ROMAN_ORG</T>
        <b>
          <T br>PROVERB_LA_NORM</T>
        </b>
        <T>PROVERB_EN_NORM</T>
        <B if="JUX_1703">
          <div style={{ fontSize: "80%", marginTop: "0.5em" }}>
            <T>JUX_1703</T>
          </div>
        </B>
      </div>
    );
  }
}

class Ursicula extends Default {
  textpart(obj) {
    return (
      <div>
        <b>
          <T br>PRINTER</T>
        </b>
        <T br>PLACE</T>
        <B if="FROM">
          <T>FROM</T> &middot; <T>UNTIL</T>
        </B>
      </div>
    );
  }
}

class Person extends Default {
  textpart(obj) {
    return (
      <div>
        <b>
          <T br>SHOW_NAME</T>
        </b>
      </div>
    );
  }
  imagepart() {
    return (
      <Image
        style={{ cursor: "pointer" }}
        onClick={(event, data) =>
          this.props.history.push(
            `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${
              this.props.section
            }`
          )
        }
        src={PersonIcoon}
      />
    );
  }
}

function Thumb({
  obj,
  search,
  section,
  triggerUpdate,
  history,
  showMeta,
  showText,
  checkboxHandler,
  isChecked,
}) {
  if (!obj.TYPE)
    return (
      <Default
        obj={obj}
        showMeta={showMeta}
        showText={showText}
        search={search}
        triggerUpdate={triggerUpdate}
        section={section}
        history={history}
        checkboxHandler={checkboxHandler}
        isChecked={isChecked}
      />
    );

  let t = obj.TYPE[0].toLowerCase().replace(/ /g, "");
  switch (t) {
    case "manuscriptbook":
      return (
        <ManuscriptBook
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "manuscriptillumination":
      return (
        <ManuscriptIllumination
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "bookillustration":
      return (
        <BookIllustration
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "book":
      return (
        <Book
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "bibliography":
      return (
        <Bibliography
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "emblembook":
      return (
        <EmblemBook
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "emblempictura":
      return (
        <EmblemPictura
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "facsimile":
      return (
        <Facsimile
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "journal":
      return (
        <Journal
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "imagesfromperiodicals":
      return (
        <PeriodicalImage
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "printersdevice":
      return (
        <PrintersDevice
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "printersdeviceusage":
      return (
        <PrintersDeviceUsage
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "proverb":
      return (
        <Proverb
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "typeface":
      return (
        <Ursicula
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );

    case "ornament":
      return (
        <Ursicula
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );

    case "initial":
      return (
        <Ursicula
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "image":
      return (
        <AImage
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "provenanceimage":
      return (
        <ProvenanceImage
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );

    case "provenanceitem":
      return (
        <ProvenanceItem
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "provenancesource":
      return (
        <ProvenanceSource
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    case "person":
      return (
        <Person
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
    default:
      return (
        <Default
          obj={obj}
          showMeta={showMeta}
          showText={showText}
          search={search}
          triggerUpdate={triggerUpdate}
          section={section}
          history={history}
          checkboxHandler={checkboxHandler}
          isChecked={isChecked}
        />
      );
  }
}

export default Thumb;
