import React, { Component } from "react";
import {
  Button,
  Form,
  Grid,
  Label,
  Tab,
  Table,
  Segment
} from "semantic-ui-react";

import { markdown } from "markdown";

import SearchInputGrid from "./liminal/SearchInputGrid";
import PageFooter from "./liminal/PageFooter";
import PageHeader from "./liminal/PageHeader";
import FilterBox from "./facetsfilters/FilterBox";
import { getLists, modifyList, deleteList } from "../search/searchHelpers";
import ThumbSelection from "./ThumbSelection";

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: this.props.search.searchTerm,
      resultSliceLimit: 33,
      objects: { hits: [] },
      selectedObjects: {},
      selectedObjectsInList: {}
    };
    getLists(null, this.props.search.credentials.token, result =>
      this.setState({ lists: result })
    );
  }
  doSearch = () => {
    this.setState({ searching: true });
    this.props.search.searchTerm = this.state.searchTerm;
    this.props.search.doSearch("all", () =>
      this.props.history.push(`${process.env.REACT_APP_URL_PATH}/section/all`)
    );
  };

  removeSelected = selectedObjects => {
    let newEntries = [];
    this.state.list.entries.forEach(listEntry => {
      if (!selectedObjects[listEntry.uid]) {
        newEntries.push(listEntry);
      }
    });
    const alist = this.state.list;
    alist.entries = newEntries;
    modifyList(alist, this.props.search.credentials.token, result => {
      this.setState({
        objects: { hits: [] },
        selectedObjects: []
      });
      getLists(null, this.props.search.credentials.token, getListsResult => {
        this.props.search.getObjects(
          alist.entries.map(tle => tle.uid),
          getObjectsResults => {
            setTimeout(() => this.setState({ foo: Math.random() }), 300);
            this.setState({
              list: null,
              lists: getListsResult,
              objects: getObjectsResults
            });
          }
        );
      });
    });
    this.setState({ selectedObjectsInList: {} });
  };

  render() {
    let lists = this.state.lists || [];

    // The saved Items object contains a dict with key 'obj'
    const savedFromListContents = {};
    this.state.objects.hits.forEach(
      obj => (savedFromListContents[obj.ID[0]] = { obj: obj })
    );

    const panes = [];

    if (this.state.list) {
      const menuItemContent = (
        <Tab.Pane>
          <div>
            {this.state.showListEdit ? (
              <Form>
                <Button.Group floated="right">
                  <Button
                    icon="save"
                    size="tiny"
                    compact
                    label="Save"
                    color="green"
                    onClick={event => {
                      const alist = this.state.list;
                      alist.name = this.state.listname;
                      alist.description = this.state.listdescription;
                      alist.public = this.state.listpublic;
                      modifyList(
                        alist,
                        this.props.search.credentials.token,
                        result =>
                          getLists(
                            null,
                            this.props.search.credentials.token,
                            result =>
                              this.setState({
                                lists: result,
                                list: alist,
                                showListEdit: false
                              })
                          )
                      );
                    }}
                  />
                  <Button
                    icon="cancel"
                    label="Cancel"
                    color="orange"
                    size="tiny"
                    compact
                    onClick={event => this.setState({ showListEdit: false })}
                  />
                </Button.Group>
                <Form.Input
                  label="Name"
                  value={this.state.listname}
                  placeholder={"Name"}
                  onChange={event =>
                    this.setState({ listname: event.target.value })
                  }
                  onKeyUp={event => {
                    if (event.key === "Escape") {
                      this.setState({ showListEdit: false });
                    }
                  }}
                />
                <Form.TextArea
                  label="Description"
                  rows={4}
                  value={this.state.listdescription}
                  onChange={event =>
                    this.setState({ listdescription: event.target.value })
                  }
                />
                <Form.Checkbox
                  checked={this.state.listpublic}
                  onChange={(event, data) =>
                    this.setState({ listpublic: data.checked })
                  }
                  label="Is Public"
                />
              </Form>
            ) : (
              <div>
                <Button
                  size="tiny"
                  floated="right"
                  color="green"
                  compact
                  onClick={event =>
                    this.setState({
                      listname: this.state.list.name,
                      listdescription: this.state.list.description,
                      listpublic: this.state.list.public,
                      showListEdit: true
                    })
                  }
                >
                  Edit
                </Button>
                <Button
                  color="orange"
                  size="tiny"
                  floated="right"
                  compact
                  icon="delete"
                  onClick={event => {
                    const areyousure = window.confirm(
                      "Are you SURE you want to delete this list?"
                    );
                    if (areyousure) {
                      deleteList(
                        { pk: this.state.list.pk },
                        this.props.search.credentials.token,
                        result => {
                          this.setState({ list: null });
                          getLists(
                            null,
                            this.props.search.credentials.token,
                            result => this.setState({ lists: result })
                          );
                        }
                      );
                    }
                  }}
                >
                  Delete List
                </Button>
                <h2>{this.state.list.name}</h2>

                {this.state.list.description && (
                  <div
                    style={{ marginBottom: "1em" }}
                    dangerouslySetInnerHTML={{
                      __html: markdown.toHTML(this.state.list.description)
                    }}
                  />
                )}
                <ThumbSelection
                  match={this.props.match}
                  history={this.props.history}
                  search={this.props.search}
                  selectedObjects={this.state.selectedObjectsInList}
                  savedObjects={savedFromListContents}
                  triggerUpdate={() => this.setState({ foo: Math.random() })}
                  onRemoveSelected={this.removeSelected}
                />
              </div>
            )}
          </div>
        </Tab.Pane>
      );

      panes.push({ menuItem: "List", pane: menuItemContent });
    }
    const savedItems = (
      <Tab.Pane>
        <ThumbSelection
          match={this.props.match}
          history={this.props.history}
          search={this.props.search}
          selectedObjects={this.state.selectedObjects}
          savedObjects={this.props.search.savedObjects}
          triggerUpdate={() => this.setState({ foo: Math.random() })}
          onRemoveSelected={selectedObjects => {
            Object.keys(selectedObjects).forEach(key => {
              this.props.search.removeSavedItem(selectedObjects[key].ID[0]);
            });
            for (var prop in selectedObjects) {
              delete selectedObjects[prop];
            }
            this.setState({ list: null, objects: { hits: [] } });
          }}
        >
          {this.state.list &&
            Object.keys(this.state.selectedObjects).length > 0 && (
              <Button
                size="tiny"
                onClick={(event, data) => {
                  const alist = this.state.list;
                  Object.keys(this.state.selectedObjects).forEach(key =>
                    alist.entries.push({ uid: key })
                  );
                  modifyList(
                    alist,
                    this.props.search.credentials.token,
                    result => {
                      this.setState({ list: null, objects: { hits: [] } });
                      getLists(
                        null,
                        this.props.search.credentials.token,
                        result => this.setState({ lists: result })
                      );
                    }
                  );
                  this.setState({ list: null, objects: { hits: [] } });
                }}
              >
                Add {Object.keys(this.state.selectedObjects).length} selected
                items to list
              </Button>
            )}
        </ThumbSelection>
      </Tab.Pane>
    );

    panes.push({
      menuItem: "Saved Items",
      pane: savedItems
    });

    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <PageHeader
          section={this.props.match.params.section}
          search={this.props.search}
          history={this.props.history}
        />

        <SearchInputGrid
          searchTerm={this.state.searchTerm}
          searching={this.state.searching}
          onChange={event => this.setState({ searchTerm: event.target.value })}
          doSearch={this.doSearch}
        />

        <Grid stackable>
          <Grid.Column width={6}>
            <FilterBox
              key={this.props.search.nonce}
              triggerUpdate={() => this.setState({ foo: Math.random() })}
              search={this.props.search}
              section={"all"}
            />

            <Segment vertical>
              <Button
                color="blue"
                compact
                icon="new"
                onClick={event => {
                  const newlist = { name: "", description: "", entries: [] };
                  this.setState({ list: newlist, showListEdit: true });
                }}
              >
                New List
              </Button>
            </Segment>
            {lists ? (
              <Segment vertical>
                {lists && (
                  <Table striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Number of items</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {lists.map(
                        l =>
                          l.name !== "_annotations" && (
                            <Table.Row
                              style={{ cursor: "pointer" }}
                              onClick={event => {
                                this.setState({
                                  showListEdit: false,
                                  list: l,
                                  objects: { hits: [] },
                                  selectedObjectsInList: []
                                });
                                this.props.search.getObjects(
                                  l.entries.map(tle => tle.uid),
                                  results =>
                                    this.setState({
                                      objects: results
                                    })
                                );
                              }}
                            >
                              {this.state.list &&
                              l.pk === this.state.list.pk ? (
                                <Table.Cell>
                                  <Label ribbon>{l.name}</Label>
                                </Table.Cell>
                              ) : (
                                <Table.Cell>{l.name}</Table.Cell>
                              )}
                              <Table.Cell>{l.entries.length}</Table.Cell>
                            </Table.Row>
                          )
                      )}
                    </Table.Body>
                  </Table>
                )}
              </Segment>
            ) : (
              <div>Loading</div>
            )}
          </Grid.Column>
          <Grid.Column width={10}>
            <div style={{ backgroundColor: "#fcfcfc", padding: "0.7em" }}>
              <Tab panes={panes} renderActiveOnly={false} />
            </div>
          </Grid.Column>
        </Grid>
        <PageFooter />
      </div>
    );
  }
}

export default ListView;
