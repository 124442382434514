import React, { Component } from "react";
import { Dropdown, Icon, Segment, Tab } from "semantic-ui-react";

import { ICONCLASS } from "../../constants";
import { Iconclass, languages } from "../Iconclass";

class IconclassFilter extends Component {
  state = { TabRefineActiveIndex: 0, openTree: null };

  handleTabRefineChange = (e, { activeIndex }) =>
    this.setState({ TabRefineActiveIndex: activeIndex });

  render() {
    const searchResults = this.props.search.getSectionResults(
      this.props.section
    );
    let top_ic = [];
    for (let i in ICONCLASS) {
      top_ic.push(
        <div key={i}>
          <Iconclass
            triggerUpdate={this.props.triggerUpdate}
            notation={i}
            search={this.props.search}
            browseable
            shownotation
            showcheckbox
            openTree={this.state.openTree}
          />
        </div>
      );
    }

    const panes = [
      {
        menuItem: "Tree",
        pane: (
          <Tab.Pane
            key={"ic_tree"}
            style={{ maxHeight: "600px", overflowY: "auto" }}
          >
            {top_ic}
          </Tab.Pane>
        )
      },
      {
        menuItem: "Clipboard",
        pane: (
          <Tab.Pane
            key={"ic_clipboard"}
            style={{ maxHeight: "600px", overflowY: "auto" }}
          >
            <h4>Your referenced notations</h4>
            {Object.keys(this.props.search.ICClipboard).map(icobjk => (
              <div key={icobjk}>
                <Icon
                  name="arrow circle left"
                  onClick={event => {
                    this.setState({
                      TabRefineActiveIndex: 0,
                      openTree: this.props.search.ICClipboard[icobjk].notation
                    });
                    this.props.search.updateFilterNonce();
                  }}
                  style={{ cursor: "pointer" }}
                />
                <Iconclass
                  inline
                  triggerUpdate={this.props.triggerUpdate}
                  notation={this.props.search.ICClipboard[icobjk].notation}
                  shownotation
                  pathrefine
                  search={this.props.search}
                />
              </div>
            ))}
          </Tab.Pane>
        )
      }
    ];

    const ICHItsByFirstDigit = this.props.search.extractICfromHits(
      this.props.section
    );
    let availFirstDigit = Object.keys(ICHItsByFirstDigit);
    availFirstDigit.sort();
    const firstDigitpanes = availFirstDigit.map(digit => ({
      menuItem: {
        key: `icfirstdmenuitem${digit}`,
        content: digit,
        style: { margin: "0 0.5em", padding: "0 0.2em" }
      },
      render: () => (
        <Tab.Pane key={"icfirstd" + digit}>
          <div style={{ fontWeight: "bold", marginBottom: "1em" }}>
            <Iconclass inline notation={digit} shownotation />
          </div>
          {ICHItsByFirstDigit[digit].map(notation => (
            <Iconclass
              triggerUpdate={this.props.triggerUpdate}
              key={`ICHItsByFirstDigit${notation}`}
              search={this.props.search}
              notation={notation}
              pathrefine
              shownotation
              showcheckbox
            />
          ))}
        </Tab.Pane>
      )
    }));

    return (
      <Segment vertical key={this.props.search.nonce}>
        <h5 className="ui header">
          ICONCLASS in&nbsp;
          <Dropdown
            compact
            defaultValue={this.props.search.IClanguagecode}
            options={languages}
            onChange={this.props.onChangeICLanguage}
          />
        </h5>

        {searchResults.total > 0 ? (
          <div style={{ maxHeight: "600px", overflowY: "auto" }}>
            <h4>We found your search most often combined with:</h4>
            <Tab panes={firstDigitpanes} />
          </div>
        ) : null}
        <div style={{ marginTop: "1em" }}>
          <h4>Refine further with:</h4>
          <Tab
            activeIndex={this.state.TabRefineActiveIndex}
            onTabChange={this.handleTabRefineChange}
            panes={panes}
            renderActiveOnly={false}
          />
        </div>
      </Segment>
    );
  }
}

export default IconclassFilter;
