import React from "react";

import PageHeader from "./PageHeader";
import PageFooter from "./PageFooter";

const NotFound = ({ section = null, search, history }) => (
  <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
    <PageHeader section={section} search={search} history={history} />
    <h1>The item you tried to access was not found</h1>
    <p>
      Or, there is a small chance that some kind of error occurred on the
      server.
    </p>
    <p style={{ color: "#279188" }}>
      If you suspect something is wrong, please use the red "Feedback" ribbon in
      the top right-hand corner.
    </p>

    <PageFooter />
  </div>
);

export default NotFound;
