import React, { Fragment } from "react";
import { CanvasProvider } from "@canvas-panel/core";

const CanvasPagination = ({
  dispatch,
  sequence,
  startCanvas,
  currentCanvas
}) => {
  const isFirstImage = currentCanvas === startCanvas;
  const isLastImage = currentCanvas === sequence.items.length - 1;
  return (
    <Fragment>
      <button
        className="ui compact button"
        disabled={isFirstImage}
        onClick={() => dispatch(CanvasProvider.prevCanvas())}
      >
        Prev Image
      </button>
      {currentCanvas + 1} of {sequence.items.length} &nbsp;
      <button
        className="ui compact button"
        disabled={isLastImage}
        onClick={() => dispatch(CanvasProvider.nextCanvas())}
      >
        Next Image
      </button>
    </Fragment>
  );
};

/*
 * Re-implementation of Canvas Panels' CanvasNavigation
 *
 * Canvas Panel doesn't appear to have any protections built in to handle
 * finding the last canvas when navigating.  This function was copied from:
 *
 * https://github.com/digirati-co-uk/canvas-panel/blob/f5544920354285bdf966c805e073b95c161551a7/packages/canvas-panel-core/src/components/CanvasNavigation/CanvasNavigation.js
 *
 * But removes the BEM sections (since they were unused).
 */
const CanvasNavigation = props => {
  const { sequence, currentCanvas, actions } = props;
  const actions_ = actions || [];
  return (
    <div style={{ paddingTop: "1em", paddingBottom: ".5em" }}>
      {sequence.items.length > 1 && <CanvasPagination {...props} />}
      {actions_.map((action, i) => (
        <Fragment key={i}>
          {action({ canvas: sequence.items[currentCanvas] })}
        </Fragment>
      ))}
    </div>
  );
};

export default CanvasNavigation;
