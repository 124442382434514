import React, { Component } from "react";
import { Loader, Pagination } from "semantic-ui-react";
import PropTypes from "prop-types";
import StackGrid from "react-stack-grid";

import T from "../utils/T";
import  Thumb from "../Thumb";
import { ZoomedImage } from "./index";

class DefaultObject extends Component {
  constructor(props) {
    super(props);
    this.state = { children: null, activePage: 1 };
    if (props.children) {
      this.getChildren(props.children);
    }
  }

  children = () => {
    return this.state.children ? (
      <div style={{ textAlign: "center", marginTop: "1em" }}>
        {this.state.children.length > 1 &&
          `${this.state.children.length} items   `}
        {this.state.children.length > 50 ? (
          <Pagination
            style={{ marginBottom: "1em" }}
            defaultActivePage={1}
            totalPages={Math.ceil(this.state.children.length / 50)}
            size="mini"
            onPageChange={(event, data) => this.setActivePage(data.activePage)}
          />
        ) : null}
        <StackGrid monitorImagesLoaded columnWidth={150}>
          {this.state.children
            .slice(
              (this.state.activePage - 1) * 50,
              (this.state.activePage - 1) * 50 + 50
            )
            .map(obj => (
              <Thumb
                showText
                key={obj.ID[0]}
                obj={obj}
                search={this.props.search}
                triggerUpdate={() => this.setState({ foo: Math.random() })}
                section={this.props.section}
                history={this.props.history}
              />
            ))}
        </StackGrid>
      </div>
    ) : (
      <Loader
        active
        inline
        content="Checking for items contained by this object..."
      />
    );
  };

  setActivePage = activePage => {
    this.setState({ activePage: activePage });
  };

  parentURL = () => {
    if (this.props.obj.PARENT) {
      return `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.PARENT[0]}/${this.props.section}`;
    } else {
      return `${process.env.REACT_APP_URL_PATH}/view/${this.props.obj.ID[0]}/${this.props.section}`;
    }
  };

  updateSearchResults = searchResults => {
    this.setState({ children: searchResults.hits, total: searchResults.total });
  };
  getChildren = childrenTypeFilter => {
    let filters = {
      parent: {
        filterId: "parent",
        filterType: "A",
        fieldName: "PARENT",
        fieldValue: this.props.obj.ID[0]
      }
    };
    if (childrenTypeFilter !== "__all__") {
      filters["childtype"] = {
        filterId: "childtype",
        filterType: "A",
        fieldName: "TIPE",
        fieldValue: childrenTypeFilter
      };
    }
    this.props.search.doSearch(
      null, // not doing it for a specific section
      this.updateSearchResults,
      "", // empty searchTerm
      filters,
      "SORTID",
      1500
    );
  };

  viewerIsOwner() {
    return "OOLA";
  }

  getChildContext() {
    return { obj: this.props.obj };
  }
  render() {
    return (
      <div>
        <T br>TITLE|TITLE_SHOW</T>
        <T>CAPTION_EN|CAPTION_NL</T>

        <ZoomedImage
          key={this.props.obj.ID[0]}
          obj={this.props.obj}
          style={{
            height: "500px",
            width: "100%",
            marginTop: "1em",
            marginBottom: "1em"
          }}
        />
      </div>
    );
  }
}

DefaultObject.childContextTypes = {
  obj: PropTypes.object
};

export default DefaultObject;
