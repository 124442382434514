import React, { Component } from "react";
import { Button } from "semantic-ui-react";
import StackGrid from "react-stack-grid";

import Thumb from "./Thumb";

class ThumbSelection extends Component {
  render() {
    return (
      <div>
        <div style={{ marginBottom: "1em" }}>
          {Object.keys(this.props.savedObjects).length > 0 && (
            <Button
              size="tiny"
              onClick={(event, data) => {
                for (var prop in this.props.selectedObjects) {
                  delete this.props.selectedObjects[prop];
                }
                for (var k in this.props.savedObjects) {
                  this.props.selectedObjects[k] = this.props.savedObjects[
                    k
                  ].obj;
                }
                this.props.triggerUpdate();
              }}
            >
              All
            </Button>
          )}
          {Object.keys(this.props.selectedObjects).length > 0 && (
            <div style={{ display: "inline" }}>
              <Button
                size="tiny"
                onClick={(event, data) => {
                  for (var prop in this.props.selectedObjects) {
                    delete this.props.selectedObjects[prop];
                  }
                  this.props.triggerUpdate();
                }}
              >
                None
              </Button>

              <Button
                size="tiny"
                onClick={(event, data) => {
                  this.props.onRemoveSelected(this.props.selectedObjects);
                }}
              >
                Remove {Object.keys(this.props.selectedObjects).length} selected
              </Button>
            </div>
          )}
          {this.props.children}
        </div>

        <StackGrid
          columnWidth={150}
          monitorImagesLoaded
          gridRef={grid => (this.grid = grid)}
        >
          {Object.keys(this.props.savedObjects).map(thek =>
            this.props.savedObjects[thek].obj ? (
              <Thumb
                isChecked={this.props.selectedObjects[thek] !== undefined}
                showText
                showMeta
                key={"saved_" + this.props.savedObjects[thek].obj.ID[0]}
                obj={this.props.savedObjects[thek].obj}
                search={this.props.search}
                triggerUpdate={this.props.triggerUpdate}
                section={this.props.match.params.section || "all"}
                history={this.props.history}
                checkboxHandler={obj => {
                  if (this.props.selectedObjects[obj.ID[0]]) {
                    delete this.props.selectedObjects[obj.ID[0]];
                  } else {
                    this.props.selectedObjects[obj.ID[0]] = obj;
                  }
                  this.props.triggerUpdate();
                  this.setState({ foo: Math.random() });
                }}
              />
            ) : (
              "Error: empty obj in saved items"
            )
          )}
        </StackGrid>
      </div>
    );
  }
}

export default ThumbSelection;
