import React, { Component } from "react";
import {
  Button,
  Icon,
  Form,
  Grid,
  Message,
  Popup,
  Tab,
} from "semantic-ui-react";
import _ from "lodash";

import { SEARCH } from "../../constants.js";
import { saveObject } from "../../search/searchHelpers";
import { ZoomedImage } from "../objects";
import PageFooter from "../liminal/PageFooter";
import Uploader from "../utils/Uploader";
import CERLHeader from "./CERLHeader";

const field_mapping = {
  date: "DATE_ORIG",
  century: "DATE_ORIG_CENTURY",
  kind: "TYPE_INS",
  language: "LANG",
  shelfmark: "SHELFMARK",
  location: "PAGE",
  institution: "INSTIT",
  width: "WIDTH",
  height: "HEIGHT",
  technique: "TECHNIQUE",
  title: "TITLE",
  transcription: "TEXT",
  comments: "COMMENT",
  author: "PERSON_AUTHOR",
  imprint: "IMPRINT",
  caption: "CAPTION",
};

const field_mapping_tosplit = {
  ic: "IC",
};

const field_mapping_multi = {
  source_url: "URL_WEBPAGE",
  place: "LOCATION_ORIG",
  former_owners: "OWNERS",
  century: "DATE_ORIG_CENTURY",
};

const field_mapping_withId = {
  institution: "INSTIT_CERLID",
  place: "LOCATION_ORIG_CERLID",
  former_owners: "OWNERS_CERLID",
};

const options_mapping = {
  institution: "institutionOptionsDict",
  former_owners: "personsOptionsDict",
  place: "placeOptionsDict",
};

const options_location = [
  { text: "Page number", value: "Page Number" },
  { text: "Front cover", value: "Front cover" },
  { text: "Back cover", value: "Back cover" },
  { text: "Front pastedown", value: "Front pastedown" },
  { text: "Back pastedown", value: "Back pastedown" },
  { text: "Front endleaves", value: "Front endleaves" },
  { text: "Back endleaves", value: "Back endleaves" },
  {
    text: "Text block (add folio number in Notes field)",
    value: "Text block (add folio number in Notes field)",
  },
  { text: "Spine", value: "Spine" },
  { text: "Upper edge", value: "Upper edge" },
  { text: "Lower edge", value: "Lower edge" },
  { text: "Fore-edge", value: "Fore-edge" },
  { text: "Title page", value: "Title page" },
];

const RemoveImageUrl = (removeHandler) => ({ canvas }) => {
  return (
    <Button
      title="Remove Image"
      icon
      onClick={() => {
        const imageUrl = canvas.__jsonld.label;
        removeHandler(imageUrl);
      }}
    >
      <Icon name="trash" />
    </Button>
  );
};

class CERLPDA extends Component {
  constructor(props) {
    super(props);
    this.state = { sourceURLCount: 1, imageURLs: [] };
    if (props.uid) {
      this.props.search.getObjects(
        [props.uid],

        (getObjectsResults) => {
          if (getObjectsResults.notfound.indexOf(props.uid) < 0) {
            let obj = getObjectsResults.hits[0];
            let moreState = this.deSerializeFromObject(obj);
            this.setState({ obj: obj, ...moreState });
          }
        }
      );
    }
  }

  deSerializeFromObject = (obj) => {
    let state = {};
    state.canyouhelp = obj["HIM"].indexOf("CERLCANYOUHELP") > -1 ? true : false;
    for (var key in field_mapping) {
      let tmp = obj[field_mapping[key]];
      if (tmp && tmp[0]) {
        if (field_mapping_withId[key]) {
          let tmp_s = tmp[0].split("|");
          if (tmp_s.length < 2) {
            tmp_s.push(tmp_s[0]);
          }
          tmp = [{ id: tmp_s[0], text: tmp_s[1] }];
          state[
            options_mapping[key].substring(0, options_mapping[key].length - 4)
          ] = [
            {
              key: tmp_s[0],
              text: tmp_s[1],
              value: tmp[0],
            },
          ];
        }

        state[key] = tmp[0];
      }
    }
    for (let key_multi in field_mapping_multi) {
      const tmp_multi = field_mapping_withId[key_multi]
        ? obj[field_mapping_withId[key_multi]]
        : obj[field_mapping_multi[key_multi]];
      if (tmp_multi) {
        // Take the field_mapping_withId into account too, plus add items to the combobox choices
        if (field_mapping_withId[key_multi]) {
          state[options_mapping[key_multi]] =
            this.state[options_mapping[key_multi]] || {};

          state[key_multi] = tmp_multi.map((tm_entry) => {
            // unpack the "stored" items based on a | separator
            const unpacked = tm_entry.split("|");
            if (unpacked.length < 2) {
              unpacked.push(unpacked[0]);
            }
            state[options_mapping[key_multi]][unpacked[0]] = {
              key: unpacked[0],
              text: unpacked[1],
              value: { id: unpacked[0], text: unpacked[1] },
            };

            return { id: unpacked[0], text: unpacked[1] };
          });

          state[
            options_mapping[key_multi].substring(
              0,
              options_mapping[key_multi].length - 4 // remove the 'dict' from the end of the key
            )
          ] = Object.values(state[options_mapping[key_multi]]);
        } else {
          state[key_multi] = tmp_multi;
        }
      }
    }
    for (var key_split in field_mapping_tosplit) {
      let tmp_ksplit = obj[field_mapping_tosplit[key_split]];
      if (tmp_ksplit) {
        state[key_split] = tmp_ksplit.join("\n");
      }
    }
    // The source_url_ fields in state needs special handling...
    if (state["source_url"]) {
      for (
        var srcurl_i = 0;
        srcurl_i < state["source_url"].length;
        srcurl_i++
      ) {
        state[`source_url_${srcurl_i}`] = state["source_url"][srcurl_i];
      }
      state["sourceURLCount"] = state["source_url"].length;
    }

    if (obj.URL_IMAGE) {
      state["imageURLs"] = obj.URL_IMAGE;
    }

    // Handle page numbers specially
    if (options_location.map((a) => a.value).indexOf(state.location) < 0) {
      state.pagenumber = state.location;
      state.location = "Page Number";
    }

    return state;
  };

  serializeToObject = () => {
    let obj = this.state.obj || {
      ID: [
        `cerlpda_${Math.random()
          .toString(16)
          .substring(2, 7)}`,
      ],
      IC: [],
      TYPE: ["provenance image"],
      // TODO
    };

    obj["URL_IMAGE"] = this.state.imageURLs;

    // If the "Can You Help?" checkbox is done, also add it to the relevant HIM
    obj["HIM"] =
      this.state.canyouhelp === true
        ? ["CERL", "CERLPDA", "CERLCANYOUHELP"]
        : ["CERL", "CERLPDA"];
    for (var key in field_mapping) {
      if (this.state[key]) {
        if (field_mapping_withId[key]) {
          obj[field_mapping[key]] = [this.state[key].text];
          obj[field_mapping_withId[key]] = [
            `${this.state[key].id}|${this.state[key].text}`,
          ];
        } else {
          obj[field_mapping[key]] = [this.state[key]];
        }
      }
    }
    // some fields need special treatment
    // KW and IC needs to be split on newlines and made into separate entries

    for (var kkey in field_mapping_tosplit) {
      if (this.state[kkey]) {
        obj[field_mapping_tosplit[kkey]] = this.state[kkey].split("\n");
      }
    }

    for (var kkkey in field_mapping_multi) {
      if (this.state[kkkey]) {
        if (field_mapping_withId[kkkey]) {
          obj[field_mapping_multi[kkkey]] = this.state[kkkey].map(
            (val) => val.text
          );
          obj[field_mapping_withId[kkkey]] = this.state[kkkey].map(
            (val) => `${val.id}|${val.text}`
          );
        } else {
          obj[field_mapping_multi[kkkey]] = this.state[kkkey];
        }
      }
    }

    // Special case for Exact Page Number location
    if (this.state.location === "Page Number" && this.state.pagenumber) {
      obj["PAGE"] = [this.state.pagenumber];
    }

    // Add the IC notation for posession if not there already
    // if (obj.IC.indexOf("55B(+3)") < 0) {
    //   obj.IC.push("55B(+3)");
    // }

    return obj;
  };

  updateISTCDetails = (ISTCURL) => {
    let url =
      ISTCURL.indexOf("format=json") < 0 ? ISTCURL + "?format=json" : ISTCURL;
    url = url.replace("http://", "https://");
    fetch(url)
      .then((response) => response.json())
      .then((result) => {
        let data = result.data || {};
        if (data.title) this.setState({ title: data.title });
        if (data.imprint.length > 0) {
          let imprint = data.imprint[0];
          this.setState({
            imprint: `${imprint.imprint_place} ${imprint.imprint_name} ${
              imprint.imprint_date
            }`,
          });
        }
        if (data.author) this.setState({ author: data.author });
      })
      .catch((e) => console.log(e));
  };

  lookupSourceUrl = (value) => {
    // Currently we can lookup HPB, MEI or ISTC values

    // An example HPB URL looks like: http://hpb.cerl.org/record/DE-604.VK.BV012108280
    if (value.indexOf("//hpb.cerl.org/record/") > 0) {
      let cid = value.substring(27);
      fetch(`https://data.cerl.org/_external/hpb_search?query=pica.cid=${cid}`)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          let data = result.rows[0];
          if (data.title) this.setState({ title: data.title });
          if (data.imprint) this.setState({ imprint: data.imprint });
          if (data.author) this.setState({ author: data.author });
        })
        .catch((e) => console.log(e));
    }

    // For MEI entries, we first need to retrieve the linked ISTC record and THEN do a ISTC lookup
    // entries look like: https://data.cerl.org/mei/02122152?format=json
    if (value.indexOf("//data.cerl.org/mei/") > 0) {
      let url =
        value.indexOf("format=json") < 0 ? value + "?format=json" : value;
      url = url.replace("http://", "https://");
      fetch(url)
        .then((response) => response.json())
        .then((result) => {
          let data = result.data || {};
          if (data.shelfmark) this.setState({ shelfmark: data.shelfmark });
          if (data.hostItemId) {
            // Turn the value into a ISTC URL, to get more details from following section below
            this.updateISTCDetails(
              `https://data.cerl.org/istc/${data.hostItemId}?format=json`
            );
          }
        })
        .catch((e) => console.log(e));
    }

    // For ISTC lookups, they are like: https://data.cerl.org/istc/ia00070500
    if (value.indexOf("//data.cerl.org/istc/") > 0) {
      this.updateISTCDetails(value);
    }
  };

  handleChange = (e, { name, value, checked }) => {
    if (name.indexOf("source_url_") === 0) {
      if (this.state.lookupSourceUrlTimeOut) {
        clearTimeout(this.state.lookupSourceUrlTimeOut);
      }
      let timeout = setTimeout(() => this.lookupSourceUrl(value), 600);
      const source_url_index = parseInt(name.substring(11), 10);
      let curr_source_url = this.state.source_url || [];
      curr_source_url[source_url_index] = value;
      let new_state = { lookupSourceUrlTimeOut: timeout };
      new_state["source_url"] = curr_source_url;
      new_state[name] = value;
      this.setState(new_state);
    } else {
      if (checked) value = true;
      this.setState({ [name]: value });
    }
  };

  handleAddCTChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleInstitutionSearch = (e, { searchQuery }) => {
    fetch(
      `https://data.cerl.org/holdinst/_search?size=100&query=${searchQuery}&format=json`
    )
      .then((response) => response.json())
      .then((result) => {
        let data = result.rows || [];
        let options = [];
        data.forEach((entry) => {
          options.push({
            key: entry.id,
            text: entry.name,
            value: { id: entry.id, text: entry.name },
          });
        });

        // Also always add a "Unknown Institution" option
        options.push({
          key: "unknown",
          text: "Unknown Institution",
          value: { id: null, text: "unknown" },
        });
        this.setState({ institutionOptions: options });
      });
  };

  handlePersonsSearch = (e, { searchQuery }) => {
    if (searchQuery.length < 4) {
      return;
    }
    if (this.state.CTPersonSearchQTimer) {
      clearTimeout(this.state.CTPersonSearchQTimer);
    }
    const CTPersonSearchQTimer = setTimeout(
      () => this.doPersonSearch(searchQuery),
      300
    );
    this.setState({ CTPersonSearchQTimer: CTPersonSearchQTimer });
  };

  doPersonSearch = (searchQuery) => {
    fetch(
      `https://data.cerl.org/thesaurus/_search?size=100&query=name%3A${searchQuery}+AND+type%3A%28cnc+OR+cnp%29&format=json`
    )
      .then((response) => response.json())
      .then((result) => {
        let data = result.rows || [];
        let personsOptionsDict = this.state.personsOptionsDict || {};

        data.forEach((entry) => {
          // Some persons have a date-of-birth-and-death in year_start and year_end fields too
          let nametxt;
          if (entry.year_start || entry.year_end) {
            nametxt = `${entry.name_display_line} (${entry.year_start ||
              ""} - ${entry.year_end || ""})`;
          } else {
            nametxt = entry.name_display_line;
          }

          personsOptionsDict[entry.id] = {
            key: entry.id,
            text: nametxt,
            value: { id: entry.id, text: nametxt },
          };
        });

        personsOptionsDict["unknown"] = {
          key: null,
          text: "Unidentified Owner",
          value: { id: null, text: "Unidentified Owner" },
        };

        this.setState({
          personsOptionsDict: personsOptionsDict,
          personsOptions: Object.values(personsOptionsDict),
        });
      });
  };

  handlePlaceSearch = (e, { searchQuery }) => {
    if (searchQuery.length < 4) {
      return;
    }
    if (this.state.CTPlaceSearchQTimer) {
      clearTimeout(this.state.CTPlaceSearchQTimer);
    }
    const CTPlaceSearchQTimer = setTimeout(
      () => this.doPlaceSearch(searchQuery),
      300
    );
    this.setState({ CTPlaceSearchQTimer: CTPlaceSearchQTimer });
  };

  doPlaceSearch = (searchQuery) => {
    fetch(
      `https://data.cerl.org/thesaurus/_search?size=100&query=name%3A${searchQuery}%20AND%20type:cnl&format=json`
    )
      .then((response) => response.json())
      .then((result) => {
        let data = result.rows || [];
        let placeOptionsDict = this.state.placeOptionsDict || {};

        data.forEach((entry) => {
          if (!placeOptionsDict[entry.id]) {
            placeOptionsDict[entry.id] = {
              key: entry.id,
              text: entry.placeName[0],
              value: { id: entry.id, text: entry.placeName[0] },
            };
          }
        });

        this.setState({
          placeOptionsDict: placeOptionsDict,
          placeOptions: Object.values(placeOptionsDict),
        });
      });
  };

  addCTPlace = (e) => {
    if (!this.state.addCTPlaceName) {
      return;
    }

    const payload = {
      token: this.props.search.credentials.token,
      action: "add_place",
      entry: this.state.addCTPlaceName,
    };

    if (this.state.addCTPlaceNameComment) {
      payload["notes"] = this.state.addCTPlaceNameComment;
    }

    this.addCT(payload, (returned_id) => {
      let placeOptionsDict = this.state.placeOptionsDict || {};
      placeOptionsDict[returned_id] = {
        key: returned_id,
        text: this.state.addCTPlaceName,
        value: { id: returned_id, text: this.state.addCTPlaceName },
      };
      let place = this.state.place || [];
      place.push({ id: returned_id, text: this.state.addCTPlaceName });

      this.setState({
        isCTPopupOpen: false,
        placeOptionsDict: placeOptionsDict,
        placeOptions: Object.values(placeOptionsDict),
        place: place,
      });
    });
  };

  addCTPerson = (e) => {
    if (!this.state.addCTPersonFirstName) return;
    if (!this.state.addCTPersonLastName) return;
    const payload = {
      token: this.props.search.credentials.token,
      action: "add_person",
      entry: this.state.addCTPersonLastName,
      firstname: this.state.addCTPersonFirstName,
      nonsort: this.state.addCTPersonPrefixName,
      gender: this.state.addCTPersonGender,
    };

    if (this.state.addCTPersonComment) {
      payload["notes"] = this.state.addCTPersonComment;
    }

    const displayText = `${this.state.addCTPersonLastName}, ${this.state
      .addCTPersonPrefixName || ""} ${this.state.addCTPersonFirstName || ""}`;
    this.addCT(payload, (returned_id) => {
      let personsOptionsDict = this.state.personsOptionsDict || {};
      personsOptionsDict[returned_id] = {
        key: returned_id,
        text: displayText,
        value: { id: returned_id, text: displayText },
      };
      let former_owners = this.state.former_owners || [];
      former_owners.push({ id: returned_id, text: displayText });

      this.setState({
        isCTPopupOpen: false,
        personsOptionsDict: personsOptionsDict,
        personsOptions: Object.values(personsOptionsDict),
        former_owners: former_owners,
      });
    });
  };

  addCTCorporate = (e) => {
    if (!this.state.addCTCorporateName) {
      this.setState({ addCTError: "Corporate Name has to be filled in" });
    }
    const payload = {
      token: this.props.search.credentials.token,
      action: "add_corporate",
      entry: this.state.addCTCorporateName,
    };
    if (this.state.addCTCorporateComment) {
      payload["notes"] = this.state.addCTCorporateComment;
    }
    const displayText = this.state.addCTCorporateName;
    this.addCT(payload, (returned_id) => {
      let personsOptionsDict = this.state.personsOptionsDict || {};
      personsOptionsDict[returned_id] = {
        key: returned_id,
        text: displayText,
        value: { id: returned_id, text: displayText },
      };
      let former_owners = this.state.former_owners || [];
      former_owners.push({ id: returned_id, text: displayText });

      this.setState({
        isCTPopupOpen: false,
        personsOptionsDict: personsOptionsDict,
        personsOptions: Object.values(personsOptionsDict),
        former_owners: former_owners,
      });
    });
  };

  addCT = (payload, callback) => {
    this.setState({ addCTError: undefined });
    fetch("/api/cerl/add", {
      body: JSON.stringify(payload),
      cache: "no-cache",
      method: "POST",
      credentials: "include",
    })
      .then((response) => {
        if (response.status !== 200) {
          this.setState({ addCTError: response.statusText });
        } else {
          response.json().then((r_data) => callback(r_data.id));
        }
      })
      .then((result) => {
        if (result) {
          // console.log({ result });
          this.setState({ isCTPopupOpen: false });
        }
      })
      .catch((e) => {
        console.log({ e });
        this.setState({ addCTError: e.message });
      });
  };

  saveAndSubmit = (event) => {
    const fieldErrorObj = { formError: false };
    ["institution", "shelfmark", "kind"].forEach((fieldname) => {
      if (!this.state[fieldname]) {
        fieldErrorObj["formError"] = true;
        fieldErrorObj[`${fieldname}_error`] = true;
      } else {
        fieldErrorObj[`${fieldname}_error`] = false;
      }
    });
    this.setState(fieldErrorObj);
    if (fieldErrorObj["formError"] === true) return;
    if (this.state.imageURLs.length < 1) {
      alert("Please upload an image first");
      return;
    }
    const obj = this.serializeToObject();
    // console.log(obj);
    saveObject(obj, this.props.search.credentials.token, (result) =>
      this.props.history.push(
        `${process.env.REACT_APP_URL_PATH}/view/${result.OK}/him_CERLPDA`
      )
    );
  };

  removeImageUrl = (removed) => {
    this.setState({
      imageURLs: this.state.imageURLs.filter((value) => value !== removed),
    });
  };

  render() {
    if (!this.props.search.credentials) {
      return (
        <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
          <CERLHeader
            handleSearchClick={(event) =>
              this.props.history.push(
                `${process.env.REACT_APP_URL_PATH}/section/him_CERLPDA/`
              )
            }
          />
          You need to be{" "}
          <a href={process.env.REACT_APP_CERLPDA_AUTH_TOKEN_URL}>logged in</a>{" "}
          to access this page
        </div>
      );
    }
    const CTAddPanes = [
      {
        menuItem: "Place",
        render: () => (
          <Tab.Pane>
            <Form error={this.state.addCTError !== undefined}>
              <Message error header="Errors" content={this.state.addCTError} />
              <Form.Input
                width={10}
                placeholder="Place Name"
                name="addCTPlaceName"
                value={this.state["addCTPlaceName"]}
                onChange={this.handleAddCTChange}
              />
              <Form.TextArea
                name="addCTPlaceNameComment"
                placeholder="Comments"
                value={this.state["addCTPlaceNameComment"]}
                onChange={this.handleAddCTChange}
              />
              <Button
                color="green"
                style={{ marginTop: "1em", marginBottom: "2em" }}
                onClick={this.addCTPlace}
              >
                Add this Place
              </Button>
              <Button
                onClick={(event) => this.setState({ isCTPopupOpen: false })}
              >
                Cancel
              </Button>
              <div>
                Please make really sure that the place is not already found in
                the CERL Thesaurus under a slightly different spelling or
                language variation.
              </div>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Former Owner",
        render: () => (
          <Tab.Pane>
            <Form error={this.state.addCTError !== undefined}>
              <Message error header="Errors" content={this.state.addCTError} />
              <Form.Input
                width={15}
                placeholder="First Name"
                name="addCTPersonFirstName"
                value={this.state["addCTPersonFirstName"]}
                onChange={this.handleAddCTChange}
              />
              <Form.Input
                width={15}
                placeholder="Last Name"
                name="addCTPersonLastName"
                value={this.state["addCTPersonLastName"]}
                onChange={this.handleAddCTChange}
              />
              <Form.Input
                width={8}
                placeholder="Prefix, e.g. 'van der' or 'de'"
                name="addCTPersonPrefixName"
                value={this.state["addCTPersonPrefixName"]}
                onChange={this.handleAddCTChange}
              />

              <Form.Select
                width={2}
                name="addCTPersonGender"
                value={this.state.addCTPersonGender}
                onChange={this.handleChange}
                label="Gender"
                placeholder="Gender"
                options={[
                  { text: "Unknown", value: "x" },
                  { text: "Female", value: "a" },
                  { text: "Male", value: "b" },
                ]}
              />

              <Form.TextArea
                name="addCTPersonComment"
                placeholder="Comments"
                value={this.state["addCTPersonComment"]}
                onChange={this.handleAddCTChange}
              />

              <Button
                color="green"
                style={{ marginTop: "1em", marginBottom: "2em" }}
                onClick={this.addCTPerson}
              >
                Add this Person
              </Button>
              <Button
                onClick={(event) => this.setState({ isCTPopupOpen: false })}
              >
                Cancel
              </Button>
            </Form>
          </Tab.Pane>
        ),
      },
      {
        menuItem: "Corporate Body",
        render: () => (
          <Tab.Pane>
            <Form error={this.state.addCTError !== undefined}>
              <Message error header="Errors" content={this.state.addCTError} />
              <Form.Input
                widht={15}
                placeholder="Name of Institution"
                name="addCTCorporateName"
                value={this.state.addCTCorporateName}
                onChange={this.handleAddCTChange}
              />
              <Form.TextArea
                name="addCTCorporateComment"
                placeholder="Comments"
                value={this.state.addCTCorporateComment}
                onChange={this.handleAddCTChange}
              />

              <Button
                color="green"
                style={{ marginTop: "1em", marginBottom: "2em" }}
                onClick={this.addCTCorporate}
              >
                Add this Institution
              </Button>
              <Button
                onClick={(event) => this.setState({ isCTPopupOpen: false })}
              >
                Cancel
              </Button>
            </Form>
          </Tab.Pane>
        ),
      },
    ];
    return (
      <div style={{ paddingLeft: "3em", paddingRight: "3em" }}>
        <CERLHeader
          handleSearchClick={(event) =>
            this.props.history.push(
              `${process.env.REACT_APP_URL_PATH}/section/him_CERLPDA/`
            )
          }
        />
        <div style={{ float: "right" }}>
          For more information, please contact:{" "}
          <span style={{ fontWeight: "bold" }}>secretariat@cerl.org</span>
        </div>
        <h1>CERL Provenance Digital Archive</h1>

        <Grid columns={16}>
          <Grid.Column width={11}>
            <Form error={this.state.formError === true}>
              <Message
                error
                header="Mandatory Fields"
                content="The fields marked in red are mandatory, and an image must always be uploaded."
              />
              <span style={{ fontWeight: "bold" }}>Source URL </span>
              <a target="_lookup" href="http://hpb.cerl.org/">
                HPB
              </a>
              &middot;
              <a target="_lookup" href="https://data.cerl.org/mei/">
                MEI
              </a>
              &middot;
              <a target="_lookup" href="https://data.cerl.org/istc/">
                ISTC
              </a>
              {_.range(this.state.sourceURLCount).map((sourceURLCountIndex) => (
                <Form.Input
                  width={16}
                  inline
                  name={`source_url_${sourceURLCountIndex}`}
                  value={this.state[`source_url_${sourceURLCountIndex}`]}
                  onChange={this.handleChange}
                  placeholder="link to HPB, MEI, ISTC or OPAC - if known"
                  icon={
                    sourceURLCountIndex === 0 ? (
                      <Icon
                        name="plus"
                        link
                        onClick={(event) =>
                          this.setState({
                            sourceURLCount: this.state.sourceURLCount + 1,
                          })
                        }
                      />
                    ) : (
                      <Icon
                        name="minus"
                        link
                        onClick={(event) => {
                          const { ...state } = this.state;
                          if (state[`source_url_${sourceURLCountIndex}`]) {
                            delete state[`source_url_${sourceURLCountIndex}`];
                          }
                          state["sourceURLCount"] =
                            this.state.sourceURLCount - 1;
                          this.setState(state);
                        }}
                      />
                    )
                  }
                />
              ))}
              <Form.TextArea
                required
                label="Caption/Description"
                onChange={this.handleChange}
                name="caption"
                value={this.state.caption}
                placeholder="A short caption or description of this item (Required)"
              />
              <hr />
              <Popup
                trigger={<Icon name="question circle" />}
                size="large"
                hoverable
              >
                Institution names are from the{" "}
                <a target="_lookup" href="https://data.cerl.org/holdinst/">
                  CERL "Holding Institutions" Database.
                </a>
                <br />
                For more information or to add a missing institution, please
                contact: <br />
                Alexander Jahnke
                <br />
                <span style={{ fontWeight: "bold", fontSize: "75%" }}>
                  jahnke@sub.uni-goettingen.de
                </span>
              </Popup>
              <Form.Dropdown
                fluid
                required
                search
                options={this.state.institutionOptions}
                error={this.state.institution_error === true}
                onChange={this.handleChange}
                onSearchChange={this.handleInstitutionSearch}
                label="Institution Name"
                placeholder="Type a name of insitution here to display list to choose from."
                name="institution"
                value={this.state.institution}
              />
              <Form.Group>
                <Form.Input
                  width={6}
                  required
                  error={this.state.shelfmark_error === true}
                  onChange={this.handleChange}
                  value={this.state.shelfmark}
                  name="shelfmark"
                  label="Shelfmark of the provenance"
                  placeholder="shelfmark in the keeping institution"
                />

                <Form.Select
                  width={5}
                  onChange={this.handleChange}
                  inline
                  required
                  error={this.state.kind_error === true}
                  label="Kind of provenance"
                  options={[
                    { text: "Unknown", value: "Unknown" },
                    { text: "Binding", value: "Binding" },
                    { text: "Bookseller’s label", value: "Bookseller’s label" },
                    { text: "Coat of arms", value: "Coat of arms" },
                    { text: "(De)accession mark", value: "(De)accession mark" },
                    { text: "Decoration", value: "Decoration" },
                    { text: "Ex-Libris", value: "Ex-Libris" },
                    {
                      text: "Manuscript annotations / marginalia",
                      value: "Manuscript annotations / marginalia",
                    },
                    { text: "Monogram", value: "Monogram" },
                    { text: "Motto", value: "Motto" },
                    { text: "Old shelfmark", value: "Old shelfmark" },
                    {
                      text: "Ownership inscription",
                      value: "Ownership inscription",
                    },
                    {
                      text: "Purchase information / prices",
                      value: "Purchase information / prices",
                    },
                    { text: "Stamp", value: "Stamp" },
                    { text: "Supralibros", value: "Supralibros" },
                  ]}
                  name="kind"
                  value={this.state.kind}
                />
              </Form.Group>
              <Form.Group>
                <Form.Select
                  width={5}
                  name="location"
                  value={this.state.location}
                  error={this.state.location_error === true}
                  onChange={this.handleChange}
                  label="Location in source"
                  options={options_location}
                />
                {this.state.location === "Page Number" && (
                  <Form.Input
                    width={3}
                    label="Page"
                    placeholder="page/folio number"
                    name="pagenumber"
                    value={this.state.pagenumber}
                    onChange={this.handleChange}
                  />
                )}
              </Form.Group>
              <h3>Bibliographic Details</h3>
              <Form.Input
                label="Title"
                placeholder="of the work"
                name="title"
                fluid
                value={this.state.title}
                onChange={this.handleChange}
              />
              <Form.Group>
                <Form.Input
                  label="Author"
                  width={8}
                  placeholder="of the work"
                  name="author"
                  fluid
                  value={this.state.author}
                  onChange={this.handleChange}
                />
                <Form.Input
                  label="Imprint"
                  width={8}
                  placeholder="of the work"
                  name="imprint"
                  fluid
                  value={this.state.imprint}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <h3>Description of the provenance</h3>
              <Form.Input
                label="Transcription"
                onChange={this.handleChange}
                placeholder="(of the inscription)"
                name="transcription"
                value={this.state.transcription}
              />
              <Form.Group>
                <Form.Select
                  width={6}
                  onChange={this.handleChange}
                  inline
                  label="Language"
                  options={[
                    { text: "English", value: "en" },
                    { text: "German", value: "de" },
                    { text: "French", value: "fr" },
                    { text: "Dutch", value: "nl" },
                    { text: "Italian", value: "it" },
                    { text: "Latin", value: "la" },
                    { text: "Greek", value: "gr" },
                    { text: "Hebrew", value: "he" },
                  ]}
                  name="language"
                  value={this.state.language}
                />
                <Form.Dropdown
                  inline
                  multiple
                  width={6}
                  options={[
                    { text: "14th", value: "14" },
                    { text: "15th", value: "15" },
                    { text: "16th", value: "16" },
                    { text: "17th", value: "17" },
                    { text: "18th", value: "18" },
                    { text: "19th", value: "19" },
                    { text: "20th", value: "20" },
                  ]}
                  onChange={this.handleChange}
                  label="Century"
                  placeholder="Choosing multiples are possible"
                  name="century"
                  value={this.state.century || []}
                />
                <Form.Input
                  inline
                  onChange={this.handleChange}
                  name="date"
                  label="Date"
                  placeholder="as exact as possible"
                  value={this.state.date}
                />
              </Form.Group>
              <Popup
                trigger={<Icon name="question circle" />}
                hoverable
                position={"bottom left"}
              >
                <div style={{ width: "60em" }}>
                  <div style={{ float: "right", width: "15em" }}>
                    Search{" "}
                    <a
                      target="_lookup"
                      href="http://iconclass.org/help/outline"
                    >
                      ICONCLASS
                    </a>{" "}
                    for other more specific notations
                  </div>
                  <span style={{ fontStyle: "italic" }}>
                    Here are some sample notations to use:
                  </span>
                  <div>
                    25G3 trees
                    <br />
                    24D stars
                    <br />
                    49L27 mark of ownership
                    <br />
                    49L8 inscription
                    <br />
                    49M8 ex-libris
                    <br />
                    49M51 book-cover, binding
                    <br />
                    44B194 coat of arms (symbol of sovereignty)
                    <br />
                    48A981 ornament ~ geometric motifs
                    <br />
                    57A65 Unselfishness
                    <br />
                    55C221 Sacrifice
                    <br />
                    92D1916 cupids: 'amores', 'amoretti', 'putti'
                    <br />
                  </div>
                </div>
              </Popup>
              <Form.TextArea
                label="ICONCLASS"
                onChange={this.handleChange}
                name="ic"
                value={this.state.ic}
                placeholder="codes without spaces (please only enter the notations one per line, no text)"
              />
              <Form.Group>
                <Form.Select
                  inline
                  onChange={this.handleChange}
                  label="Technique"
                  name="technique"
                  value={this.state.technique}
                  options={[
                    { text: "Unidentified", value: "Unidentified" },
                    { text: "Blind Stamp", value: "Blind Stamp" },
                    { text: "Drawings", value: "Drawings" },
                    { text: "Firestamp", value: "Firestamp" },
                    { text: "Gold", value: "Gold" },
                    { text: "Illumination", value: "Illumination" },
                    { text: "Ink", value: "Ink" },
                    { text: "Pencil", value: "Pencil" },
                    { text: "Printed", value: "Printed" },
                    { text: "Silver", value: "Silver" },
                    { text: "Stamp", value: "Stamp" },
                    {
                      text: "Other (Specify in Notes field)",
                      value: "other",
                    },
                  ]}
                />
                <Form.Input
                  label="Width"
                  onChange={this.handleChange}
                  inline
                  placeholder="(width in cm, eg. 2.5cm)"
                  name="width"
                  value={this.state.width}
                />
                <Form.Input
                  label="Height"
                  onChange={this.handleChange}
                  inline
                  placeholder="(height in cm, eg. 5cm)"
                  name="height"
                  value={this.state.height}
                />
              </Form.Group>
              <Popup
                trigger={<Icon name="question circle" />}
                flowing
                hoverable
              >
                <div>
                  Placenames and Former Owners are from the{" "}
                  <a target="_lookup" href="https://data.cerl.org/thesaurus/">
                    CERL Thesaurus Database.
                  </a>
                </div>
              </Popup>
              <Form.Dropdown
                fluid
                search
                multiple
                options={this.state.placeOptions}
                onChange={this.handleChange}
                onSearchChange={this.handlePlaceSearch}
                label="Place of use"
                placeholder="Geographical references to where it was used"
                name="place"
                value={this.state.place || []}
              />
              <Form.Dropdown
                fluid
                search
                multiple
                options={this.state.personsOptions}
                onChange={this.handleChange}
                onSearchChange={this.handlePersonsSearch}
                label="Former Owners"
                placeholder="Persons, Institutions or Artists"
                name="former_owners"
                value={this.state.former_owners || []}
              />
              <div style={{ display: "inline", width: 12 }}>
                <Popup
                  trigger={
                    <Button color="olive" size="tiny">
                      Add missing lookup items
                    </Button>
                  }
                  flowing
                  on="click"
                  open={this.state.isCTPopupOpen}
                  onOpen={() => {
                    this.setState({ isCTPopupOpen: true });
                  }}
                  onClose={() => {
                    this.setState({
                      isCTPopupOpen: false,
                      addCTError: undefined,
                    });
                  }}
                >
                  <Tab panes={CTAddPanes} />
                  <div style={{ marginTop: "1em" }}>
                    Placenames and Former Owners are from the{" "}
                    <a target="_lookup" href="https://data.cerl.org/thesaurus/">
                      CERL Thesaurus Database.
                    </a>{" "}
                  </div>
                </Popup>
              </div>
            </Form>
            {this.state.obj ? (
              <div style={{ display: "inline-block" }}>
                <Button
                  color="green"
                  floated="right"
                  style={{ marginTop: "1em", marginBottom: "2em" }}
                  onClick={this.saveAndSubmit}
                >
                  Save this item
                </Button>
                <Button
                  floated="right"
                  style={{ marginTop: "1em", marginBottom: "2em" }}
                  onClick={() =>
                    this.props.history.push(
                      `${process.env.REACT_APP_URL_PATH}/view/${
                        this.state.obj.ID[0]
                      }/him_CERLPDA`
                    )
                  }
                >
                  View
                </Button>
              </div>
            ) : (
              <Button
                color="green"
                floated="right"
                style={{ marginTop: "1em", marginBottom: "2em" }}
                onClick={this.saveAndSubmit}
              >
                Submit this item
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={5}>
            {this.state.obj ? (
              <div style={{ display: "inline-block" }}>
                <Button
                  color="green"
                  style={{ marginTop: "1em", marginBottom: "2em" }}
                  onClick={this.saveAndSubmit}
                >
                  Save this item
                </Button>
                <Button
                  style={{ marginTop: "1em", marginBottom: "2em" }}
                  onClick={() =>
                    this.props.history.push(
                      `${process.env.REACT_APP_URL_PATH}/view/${
                        this.state.obj.ID[0]
                      }/him_CERLPDA`
                    )
                  }
                >
                  View
                </Button>
              </div>
            ) : (
              <Button
                color="green"
                style={{ marginTop: "1em", marginBottom: "2em" }}
                onClick={this.saveAndSubmit}
              >
                Submit this item
              </Button>
            )}

            <div
              style={{
                padding: "1em",
                backgroundColor: "#eee",
                marginBottom: "1em",
              }}
            >
              <Form.Checkbox
                label="Display this item in the Can You Help? forum"
                name="canyouhelp"
                onChange={this.handleChange}
                checked={this.state.canyouhelp}
              />
            </div>

            <div style={{ marginBottom: "1em" }}>
              <Uploader
                url={`${SEARCH.PATH_BASE}/api/upload`}
                search={this.props.search}
                callbackDone={(oldfile, newfile) => {
                  console.log(`We have ${newfile} and ${this.state.imageURLs}`);
                  if (this.state.imageURLs.indexOf(newfile) < 0) {
                    console.log("Trying to add new file");
                    this.setState({
                      imageURLs: [...this.state.imageURLs, newfile],
                    });
                  }
                }}
                style={{
                  backgroundColor: "#ededed",
                  width: "100%",
                  padding: "1em",
                  cursor: "pointer",
                }}
              >
                Upload image file(s) <Icon name="upload" />
              </Uploader>
              {this.state.imageURLs.length > 0 ? (
                <div>
                  <ZoomedImage
                    key={`imageURLs_${this.state.imageURLs.length}`}
                    obj={{
                      ALLIMAGES: this.state.imageURLs,
                      ID: [this.state.imageURLs[0]],
                    }}
                    style={{
                      height: "500px",
                      width: "100%",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                    actions={[RemoveImageUrl(this.removeImageUrl)]}
                  />
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
            <Form>
              <Form.TextArea
                name="comments"
                value={this.state.comments}
                label="Notes"
                onChange={this.handleChange}
                rows={6}
                placeholder="Any general comments can be added here"
              />
            </Form>
          </Grid.Column>
        </Grid>

        <PageFooter />
      </div>
    );
  }
}

export default CERLPDA;
